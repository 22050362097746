/* tslint:disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v2.0.14
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as GenericExceptions from "./../../../thrift/protocol/common/exceptions";
import * as PHeader from "./../../../thrift/protocol/common/models";
import * as ElkEvent from "./../../../thrift/protocol/prototypes/events/messages";
import * as ElkPayment from "./../../../thrift/protocol/prototypes/events/messages";
import * as ElkTheme from "./../../../thrift/protocol/prototypes/events/messages";
export namespace TElkEventService {
    export interface IFetchEventsArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkFetchEventsRequest;
    }
    export class FetchEventsArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkFetchEventsRequest;
        constructor(args: IFetchEventsArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchEventsArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchEventsArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_1: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_1;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_2: ElkEvent.TElkFetchEventsRequest = ElkEvent.TElkFetchEventsRequest.read(input);
                            _args.request = value_2;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new FetchEventsArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read FetchEventsArgs from input");
            }
        }
    }
    export interface IGetEventArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkGetEventRequest;
    }
    export class GetEventArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkGetEventRequest;
        constructor(args: IGetEventArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetEventArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetEventArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_3: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_3;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_4: ElkEvent.TElkGetEventRequest = ElkEvent.TElkGetEventRequest.read(input);
                            _args.request = value_4;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetEventArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetEventArgs from input");
            }
        }
    }
    export interface IGetEventPreviewArgsArgs {
        header: PHeader.TUnauthHeader;
        request: ElkEvent.TElkGetEventPreviewRequest;
    }
    export class GetEventPreviewArgs {
        public header: PHeader.TUnauthHeader;
        public request: ElkEvent.TElkGetEventPreviewRequest;
        constructor(args: IGetEventPreviewArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetEventPreviewArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetEventPreviewArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_5: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_5;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_6: ElkEvent.TElkGetEventPreviewRequest = ElkEvent.TElkGetEventPreviewRequest.read(input);
                            _args.request = value_6;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetEventPreviewArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetEventPreviewArgs from input");
            }
        }
    }
    export interface IGetEventMessageHistoryArgsArgs {
        header: PHeader.TUnauthHeader;
        request: ElkEvent.TElkGetMessageHistoryRequest;
    }
    export class GetEventMessageHistoryArgs {
        public header: PHeader.TUnauthHeader;
        public request: ElkEvent.TElkGetMessageHistoryRequest;
        constructor(args: IGetEventMessageHistoryArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetEventMessageHistoryArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetEventMessageHistoryArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_7: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_7;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_8: ElkEvent.TElkGetMessageHistoryRequest = ElkEvent.TElkGetMessageHistoryRequest.read(input);
                            _args.request = value_8;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetEventMessageHistoryArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetEventMessageHistoryArgs from input");
            }
        }
    }
    export interface IGetEventImageHistoryArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkGetImageHistoryRequest;
    }
    export class GetEventImageHistoryArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkGetImageHistoryRequest;
        constructor(args: IGetEventImageHistoryArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetEventImageHistoryArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetEventImageHistoryArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_9: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_9;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_10: ElkEvent.TElkGetImageHistoryRequest = ElkEvent.TElkGetImageHistoryRequest.read(input);
                            _args.request = value_10;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetEventImageHistoryArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetEventImageHistoryArgs from input");
            }
        }
    }
    export interface IGetUserEventMessageHistoryArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkGetMessageHistoryRequest;
    }
    export class GetUserEventMessageHistoryArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkGetMessageHistoryRequest;
        constructor(args: IGetUserEventMessageHistoryArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetUserEventMessageHistoryArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetUserEventMessageHistoryArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_11: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_11;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_12: ElkEvent.TElkGetMessageHistoryRequest = ElkEvent.TElkGetMessageHistoryRequest.read(input);
                            _args.request = value_12;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetUserEventMessageHistoryArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetUserEventMessageHistoryArgs from input");
            }
        }
    }
    export interface ICreateEventArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkCreateEventRequest;
    }
    export class CreateEventArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkCreateEventRequest;
        constructor(args: ICreateEventArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("CreateEventArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): CreateEventArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_13: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_13;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_14: ElkEvent.TElkCreateEventRequest = ElkEvent.TElkCreateEventRequest.read(input);
                            _args.request = value_14;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new CreateEventArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read CreateEventArgs from input");
            }
        }
    }
    export interface ICreateDraftEventArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkCreateEventDraftRequest;
    }
    export class CreateDraftEventArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkCreateEventDraftRequest;
        constructor(args: ICreateDraftEventArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("CreateDraftEventArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): CreateDraftEventArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_15: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_15;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_16: ElkEvent.TElkCreateEventDraftRequest = ElkEvent.TElkCreateEventDraftRequest.read(input);
                            _args.request = value_16;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new CreateDraftEventArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read CreateDraftEventArgs from input");
            }
        }
    }
    export interface IGetEventShortLinkArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkGetEventShortLinkRequest;
    }
    export class GetEventShortLinkArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkGetEventShortLinkRequest;
        constructor(args: IGetEventShortLinkArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetEventShortLinkArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetEventShortLinkArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_17: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_17;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_18: ElkEvent.TElkGetEventShortLinkRequest = ElkEvent.TElkGetEventShortLinkRequest.read(input);
                            _args.request = value_18;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetEventShortLinkArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetEventShortLinkArgs from input");
            }
        }
    }
    export interface IEditEventDetailsArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkEditEventDetailsRequest;
    }
    export class EditEventDetailsArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkEditEventDetailsRequest;
        constructor(args: IEditEventDetailsArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("EditEventDetailsArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): EditEventDetailsArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_19: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_19;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_20: ElkEvent.TElkEditEventDetailsRequest = ElkEvent.TElkEditEventDetailsRequest.read(input);
                            _args.request = value_20;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new EditEventDetailsArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read EditEventDetailsArgs from input");
            }
        }
    }
    export interface IEditEventDraftArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkEditEventDraftRequest;
    }
    export class EditEventDraftArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkEditEventDraftRequest;
        constructor(args: IEditEventDraftArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("EditEventDraftArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): EditEventDraftArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_21: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_21;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_22: ElkEvent.TElkEditEventDraftRequest = ElkEvent.TElkEditEventDraftRequest.read(input);
                            _args.request = value_22;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new EditEventDraftArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read EditEventDraftArgs from input");
            }
        }
    }
    export interface IUpdateAttendanceArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkUpdateAttendanceRequest;
    }
    export class UpdateAttendanceArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkUpdateAttendanceRequest;
        constructor(args: IUpdateAttendanceArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateAttendanceArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateAttendanceArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_23: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_23;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_24: ElkEvent.TElkUpdateAttendanceRequest = ElkEvent.TElkUpdateAttendanceRequest.read(input);
                            _args.request = value_24;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new UpdateAttendanceArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpdateAttendanceArgs from input");
            }
        }
    }
    export interface IEditInviteeArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkEditInviteeRequest;
    }
    export class EditInviteeArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkEditInviteeRequest;
        constructor(args: IEditInviteeArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("EditInviteeArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): EditInviteeArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_25: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_25;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_26: ElkEvent.TElkEditInviteeRequest = ElkEvent.TElkEditInviteeRequest.read(input);
                            _args.request = value_26;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new EditInviteeArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read EditInviteeArgs from input");
            }
        }
    }
    export interface IUpdateAttendanceThroughEmailArgsArgs {
        header: PHeader.TUnauthHeader;
        request: ElkEvent.TElkUpdateAttendanceRequest;
    }
    export class UpdateAttendanceThroughEmailArgs {
        public header: PHeader.TUnauthHeader;
        public request: ElkEvent.TElkUpdateAttendanceRequest;
        constructor(args: IUpdateAttendanceThroughEmailArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateAttendanceThroughEmailArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateAttendanceThroughEmailArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_27: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_27;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_28: ElkEvent.TElkUpdateAttendanceRequest = ElkEvent.TElkUpdateAttendanceRequest.read(input);
                            _args.request = value_28;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new UpdateAttendanceThroughEmailArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpdateAttendanceThroughEmailArgs from input");
            }
        }
    }
    export interface IUpdateAttendanceByLinkArgsArgs {
        header: PHeader.TUnauthHeader;
        request: ElkEvent.TElkUpdateAttendanceRequest;
    }
    export class UpdateAttendanceByLinkArgs {
        public header: PHeader.TUnauthHeader;
        public request: ElkEvent.TElkUpdateAttendanceRequest;
        constructor(args: IUpdateAttendanceByLinkArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateAttendanceByLinkArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateAttendanceByLinkArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_29: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_29;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_30: ElkEvent.TElkUpdateAttendanceRequest = ElkEvent.TElkUpdateAttendanceRequest.read(input);
                            _args.request = value_30;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new UpdateAttendanceByLinkArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpdateAttendanceByLinkArgs from input");
            }
        }
    }
    export interface ICancelEventArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkCancelEventRequest;
    }
    export class CancelEventArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkCancelEventRequest;
        constructor(args: ICancelEventArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("CancelEventArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): CancelEventArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_31: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_31;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_32: ElkEvent.TElkCancelEventRequest = ElkEvent.TElkCancelEventRequest.read(input);
                            _args.request = value_32;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new CancelEventArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read CancelEventArgs from input");
            }
        }
    }
    export interface IDeleteEventArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkDeleteEventRequest;
    }
    export class DeleteEventArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkDeleteEventRequest;
        constructor(args: IDeleteEventArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("DeleteEventArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): DeleteEventArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_33: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_33;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_34: ElkEvent.TElkDeleteEventRequest = ElkEvent.TElkDeleteEventRequest.read(input);
                            _args.request = value_34;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new DeleteEventArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read DeleteEventArgs from input");
            }
        }
    }
    export interface IGetElkUserArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkGetUserRequest;
    }
    export class GetElkUserArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkGetUserRequest;
        constructor(args: IGetElkUserArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetElkUserArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetElkUserArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_35: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_35;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_36: ElkEvent.TElkGetUserRequest = ElkEvent.TElkGetUserRequest.read(input);
                            _args.request = value_36;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetElkUserArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetElkUserArgs from input");
            }
        }
    }
    export interface ICreateElkUserArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkCreateUserRequest;
    }
    export class CreateElkUserArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkCreateUserRequest;
        constructor(args: ICreateElkUserArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("CreateElkUserArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): CreateElkUserArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_37: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_37;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_38: ElkEvent.TElkCreateUserRequest = ElkEvent.TElkCreateUserRequest.read(input);
                            _args.request = value_38;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new CreateElkUserArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read CreateElkUserArgs from input");
            }
        }
    }
    export interface IUpdateElkUserArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkUpdateUserRequest;
    }
    export class UpdateElkUserArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkUpdateUserRequest;
        constructor(args: IUpdateElkUserArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateElkUserArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateElkUserArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_39: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_39;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_40: ElkEvent.TElkUpdateUserRequest = ElkEvent.TElkUpdateUserRequest.read(input);
                            _args.request = value_40;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new UpdateElkUserArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpdateElkUserArgs from input");
            }
        }
    }
    export interface IGetHasCompletedElkProfileArgsArgs {
        header: PHeader.TUnauthHeader;
        request: ElkEvent.TElkGetHasCompletedProfileRequest;
    }
    export class GetHasCompletedElkProfileArgs {
        public header: PHeader.TUnauthHeader;
        public request: ElkEvent.TElkGetHasCompletedProfileRequest;
        constructor(args: IGetHasCompletedElkProfileArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetHasCompletedElkProfileArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetHasCompletedElkProfileArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_41: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_41;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_42: ElkEvent.TElkGetHasCompletedProfileRequest = ElkEvent.TElkGetHasCompletedProfileRequest.read(input);
                            _args.request = value_42;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetHasCompletedElkProfileArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetHasCompletedElkProfileArgs from input");
            }
        }
    }
    export interface IGetHasElkProfileArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkGetHasProfileRequest;
    }
    export class GetHasElkProfileArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkGetHasProfileRequest;
        constructor(args: IGetHasElkProfileArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetHasElkProfileArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetHasElkProfileArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_43: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_43;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_44: ElkEvent.TElkGetHasProfileRequest = ElkEvent.TElkGetHasProfileRequest.read(input);
                            _args.request = value_44;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetHasElkProfileArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetHasElkProfileArgs from input");
            }
        }
    }
    export interface ISendEventCommentArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkSendCommentRequest;
    }
    export class SendEventCommentArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkSendCommentRequest;
        constructor(args: ISendEventCommentArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendEventCommentArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendEventCommentArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_45: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_45;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_46: ElkEvent.TElkSendCommentRequest = ElkEvent.TElkSendCommentRequest.read(input);
                            _args.request = value_46;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new SendEventCommentArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SendEventCommentArgs from input");
            }
        }
    }
    export interface ISendTemplateEmailArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkSendTemplateEmailRequest;
    }
    export class SendTemplateEmailArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkSendTemplateEmailRequest;
        constructor(args: ISendTemplateEmailArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendTemplateEmailArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendTemplateEmailArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_47: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_47;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_48: ElkEvent.TElkSendTemplateEmailRequest = ElkEvent.TElkSendTemplateEmailRequest.read(input);
                            _args.request = value_48;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new SendTemplateEmailArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SendTemplateEmailArgs from input");
            }
        }
    }
    export interface IQueueInvitesArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkQueueInviteRequest;
    }
    export class QueueInvitesArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkQueueInviteRequest;
        constructor(args: IQueueInvitesArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("QueueInvitesArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): QueueInvitesArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_49: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_49;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_50: ElkEvent.TElkQueueInviteRequest = ElkEvent.TElkQueueInviteRequest.read(input);
                            _args.request = value_50;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new QueueInvitesArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read QueueInvitesArgs from input");
            }
        }
    }
    export interface IDeletePendingInvitesArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkDeletePendingInviteRequest;
    }
    export class DeletePendingInvitesArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkDeletePendingInviteRequest;
        constructor(args: IDeletePendingInvitesArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("DeletePendingInvitesArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): DeletePendingInvitesArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_51: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_51;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_52: ElkEvent.TElkDeletePendingInviteRequest = ElkEvent.TElkDeletePendingInviteRequest.read(input);
                            _args.request = value_52;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new DeletePendingInvitesArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read DeletePendingInvitesArgs from input");
            }
        }
    }
    export interface ISendInvitesArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkSendInviteRequest;
    }
    export class SendInvitesArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkSendInviteRequest;
        constructor(args: ISendInvitesArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendInvitesArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendInvitesArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_53: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_53;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_54: ElkEvent.TElkSendInviteRequest = ElkEvent.TElkSendInviteRequest.read(input);
                            _args.request = value_54;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new SendInvitesArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SendInvitesArgs from input");
            }
        }
    }
    export interface IGetAlbumDetailsArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkGetAlbumDetailsRequest;
    }
    export class GetAlbumDetailsArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkGetAlbumDetailsRequest;
        constructor(args: IGetAlbumDetailsArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetAlbumDetailsArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetAlbumDetailsArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_55: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_55;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_56: ElkEvent.TElkGetAlbumDetailsRequest = ElkEvent.TElkGetAlbumDetailsRequest.read(input);
                            _args.request = value_56;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetAlbumDetailsArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetAlbumDetailsArgs from input");
            }
        }
    }
    export interface IGetPreviewAlbumDetailsArgsArgs {
        header: PHeader.TUnauthHeader;
        request: ElkEvent.TElkGetAlbumDetailsRequest;
    }
    export class GetPreviewAlbumDetailsArgs {
        public header: PHeader.TUnauthHeader;
        public request: ElkEvent.TElkGetAlbumDetailsRequest;
        constructor(args: IGetPreviewAlbumDetailsArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetPreviewAlbumDetailsArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetPreviewAlbumDetailsArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_57: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_57;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_58: ElkEvent.TElkGetAlbumDetailsRequest = ElkEvent.TElkGetAlbumDetailsRequest.read(input);
                            _args.request = value_58;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetPreviewAlbumDetailsArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetPreviewAlbumDetailsArgs from input");
            }
        }
    }
    export interface IGetAlbumForElkArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkFetchAlbumRequest;
    }
    export class GetAlbumForElkArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkFetchAlbumRequest;
        constructor(args: IGetAlbumForElkArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetAlbumForElkArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetAlbumForElkArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_59: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_59;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_60: ElkEvent.TElkFetchAlbumRequest = ElkEvent.TElkFetchAlbumRequest.read(input);
                            _args.request = value_60;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetAlbumForElkArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetAlbumForElkArgs from input");
            }
        }
    }
    export interface IFetchContactsArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkFetchContactsRequest;
    }
    export class FetchContactsArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkFetchContactsRequest;
        constructor(args: IFetchContactsArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchContactsArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchContactsArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_61: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_61;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_62: ElkEvent.TElkFetchContactsRequest = ElkEvent.TElkFetchContactsRequest.read(input);
                            _args.request = value_62;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new FetchContactsArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read FetchContactsArgs from input");
            }
        }
    }
    export interface IFetchDraftArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkFetchDraftRequest;
    }
    export class FetchDraftArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkFetchDraftRequest;
        constructor(args: IFetchDraftArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchDraftArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchDraftArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_63: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_63;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_64: ElkEvent.TElkFetchDraftRequest = ElkEvent.TElkFetchDraftRequest.read(input);
                            _args.request = value_64;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new FetchDraftArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read FetchDraftArgs from input");
            }
        }
    }
    export interface IFetchMutualMembersArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkFetchMutualMembersRequest;
    }
    export class FetchMutualMembersArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkFetchMutualMembersRequest;
        constructor(args: IFetchMutualMembersArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchMutualMembersArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchMutualMembersArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_65: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_65;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_66: ElkEvent.TElkFetchMutualMembersRequest = ElkEvent.TElkFetchMutualMembersRequest.read(input);
                            _args.request = value_66;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new FetchMutualMembersArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read FetchMutualMembersArgs from input");
            }
        }
    }
    export interface IFetchDraftsArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkFetchDraftsRequest;
    }
    export class FetchDraftsArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkFetchDraftsRequest;
        constructor(args: IFetchDraftsArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchDraftsArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchDraftsArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_67: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_67;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_68: ElkEvent.TElkFetchDraftsRequest = ElkEvent.TElkFetchDraftsRequest.read(input);
                            _args.request = value_68;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new FetchDraftsArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read FetchDraftsArgs from input");
            }
        }
    }
    export interface IDeleteDraftArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkEvent.TElkDeleteDraftRequest;
    }
    export class DeleteDraftArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkEvent.TElkDeleteDraftRequest;
        constructor(args: IDeleteDraftArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("DeleteDraftArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): DeleteDraftArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_69: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_69;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_70: ElkEvent.TElkDeleteDraftRequest = ElkEvent.TElkDeleteDraftRequest.read(input);
                            _args.request = value_70;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new DeleteDraftArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read DeleteDraftArgs from input");
            }
        }
    }
    export interface IFetchEventsResultArgs {
        success?: ElkEvent.TElkFetchEventsResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class FetchEventsResult {
        public success?: ElkEvent.TElkFetchEventsResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IFetchEventsResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchEventsResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchEventsResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_71: ElkEvent.TElkFetchEventsResponse = ElkEvent.TElkFetchEventsResponse.read(input);
                            _args.success = value_71;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_72: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_72;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_73: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_73;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new FetchEventsResult(_args);
        }
    }
    export interface IGetEventResultArgs {
        success?: ElkEvent.TElkGetEventResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetEventResult {
        public success?: ElkEvent.TElkGetEventResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetEventResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetEventResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetEventResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_74: ElkEvent.TElkGetEventResponse = ElkEvent.TElkGetEventResponse.read(input);
                            _args.success = value_74;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_75: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_75;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_76: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_76;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetEventResult(_args);
        }
    }
    export interface IGetEventPreviewResultArgs {
        success?: ElkEvent.TElkGetEventPreviewResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetEventPreviewResult {
        public success?: ElkEvent.TElkGetEventPreviewResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetEventPreviewResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetEventPreviewResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetEventPreviewResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_77: ElkEvent.TElkGetEventPreviewResponse = ElkEvent.TElkGetEventPreviewResponse.read(input);
                            _args.success = value_77;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_78: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_78;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_79: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_79;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetEventPreviewResult(_args);
        }
    }
    export interface IGetEventMessageHistoryResultArgs {
        success?: ElkEvent.TElkGetMessageHistoryResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetEventMessageHistoryResult {
        public success?: ElkEvent.TElkGetMessageHistoryResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetEventMessageHistoryResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetEventMessageHistoryResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetEventMessageHistoryResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_80: ElkEvent.TElkGetMessageHistoryResponse = ElkEvent.TElkGetMessageHistoryResponse.read(input);
                            _args.success = value_80;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_81: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_81;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_82: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_82;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetEventMessageHistoryResult(_args);
        }
    }
    export interface IGetEventImageHistoryResultArgs {
        success?: ElkEvent.TElkGetImageHistoryResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetEventImageHistoryResult {
        public success?: ElkEvent.TElkGetImageHistoryResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetEventImageHistoryResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetEventImageHistoryResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetEventImageHistoryResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_83: ElkEvent.TElkGetImageHistoryResponse = ElkEvent.TElkGetImageHistoryResponse.read(input);
                            _args.success = value_83;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_84: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_84;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_85: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_85;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetEventImageHistoryResult(_args);
        }
    }
    export interface IGetUserEventMessageHistoryResultArgs {
        success?: ElkEvent.TElkGetMessageHistoryResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetUserEventMessageHistoryResult {
        public success?: ElkEvent.TElkGetMessageHistoryResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetUserEventMessageHistoryResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetUserEventMessageHistoryResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetUserEventMessageHistoryResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_86: ElkEvent.TElkGetMessageHistoryResponse = ElkEvent.TElkGetMessageHistoryResponse.read(input);
                            _args.success = value_86;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_87: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_87;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_88: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_88;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetUserEventMessageHistoryResult(_args);
        }
    }
    export interface ICreateEventResultArgs {
        success?: ElkEvent.TElkCreateEventResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class CreateEventResult {
        public success?: ElkEvent.TElkCreateEventResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ICreateEventResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("CreateEventResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): CreateEventResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_89: ElkEvent.TElkCreateEventResponse = ElkEvent.TElkCreateEventResponse.read(input);
                            _args.success = value_89;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_90: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_90;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_91: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_91;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new CreateEventResult(_args);
        }
    }
    export interface ICreateDraftEventResultArgs {
        success?: ElkEvent.TElkCreateEventDraftResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class CreateDraftEventResult {
        public success?: ElkEvent.TElkCreateEventDraftResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ICreateDraftEventResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("CreateDraftEventResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): CreateDraftEventResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_92: ElkEvent.TElkCreateEventDraftResponse = ElkEvent.TElkCreateEventDraftResponse.read(input);
                            _args.success = value_92;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_93: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_93;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_94: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_94;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new CreateDraftEventResult(_args);
        }
    }
    export interface IGetEventShortLinkResultArgs {
        success?: ElkEvent.TElkGetEventShortLinkResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetEventShortLinkResult {
        public success?: ElkEvent.TElkGetEventShortLinkResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetEventShortLinkResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetEventShortLinkResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetEventShortLinkResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_95: ElkEvent.TElkGetEventShortLinkResponse = ElkEvent.TElkGetEventShortLinkResponse.read(input);
                            _args.success = value_95;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_96: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_96;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_97: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_97;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetEventShortLinkResult(_args);
        }
    }
    export interface IEditEventDetailsResultArgs {
        success?: ElkEvent.TElkEditEventDetailsResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class EditEventDetailsResult {
        public success?: ElkEvent.TElkEditEventDetailsResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IEditEventDetailsResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("EditEventDetailsResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): EditEventDetailsResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_98: ElkEvent.TElkEditEventDetailsResponse = ElkEvent.TElkEditEventDetailsResponse.read(input);
                            _args.success = value_98;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_99: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_99;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_100: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_100;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new EditEventDetailsResult(_args);
        }
    }
    export interface IEditEventDraftResultArgs {
        success?: ElkEvent.TElkEditEventDraftResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class EditEventDraftResult {
        public success?: ElkEvent.TElkEditEventDraftResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IEditEventDraftResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("EditEventDraftResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): EditEventDraftResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_101: ElkEvent.TElkEditEventDraftResponse = ElkEvent.TElkEditEventDraftResponse.read(input);
                            _args.success = value_101;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_102: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_102;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_103: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_103;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new EditEventDraftResult(_args);
        }
    }
    export interface IUpdateAttendanceResultArgs {
        success?: ElkEvent.TElkUpdateAttendanceResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class UpdateAttendanceResult {
        public success?: ElkEvent.TElkUpdateAttendanceResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IUpdateAttendanceResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateAttendanceResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateAttendanceResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_104: ElkEvent.TElkUpdateAttendanceResponse = ElkEvent.TElkUpdateAttendanceResponse.read(input);
                            _args.success = value_104;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_105: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_105;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_106: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_106;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new UpdateAttendanceResult(_args);
        }
    }
    export interface IEditInviteeResultArgs {
        success?: ElkEvent.TElkEditInviteeResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class EditInviteeResult {
        public success?: ElkEvent.TElkEditInviteeResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IEditInviteeResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("EditInviteeResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): EditInviteeResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_107: ElkEvent.TElkEditInviteeResponse = ElkEvent.TElkEditInviteeResponse.read(input);
                            _args.success = value_107;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_108: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_108;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_109: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_109;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new EditInviteeResult(_args);
        }
    }
    export interface IUpdateAttendanceThroughEmailResultArgs {
        success?: ElkEvent.TElkUpdateAttendanceResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class UpdateAttendanceThroughEmailResult {
        public success?: ElkEvent.TElkUpdateAttendanceResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IUpdateAttendanceThroughEmailResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateAttendanceThroughEmailResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateAttendanceThroughEmailResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_110: ElkEvent.TElkUpdateAttendanceResponse = ElkEvent.TElkUpdateAttendanceResponse.read(input);
                            _args.success = value_110;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_111: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_111;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_112: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_112;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new UpdateAttendanceThroughEmailResult(_args);
        }
    }
    export interface IUpdateAttendanceByLinkResultArgs {
        success?: ElkEvent.TElkUpdateAttendanceResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class UpdateAttendanceByLinkResult {
        public success?: ElkEvent.TElkUpdateAttendanceResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IUpdateAttendanceByLinkResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateAttendanceByLinkResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateAttendanceByLinkResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_113: ElkEvent.TElkUpdateAttendanceResponse = ElkEvent.TElkUpdateAttendanceResponse.read(input);
                            _args.success = value_113;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_114: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_114;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_115: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_115;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new UpdateAttendanceByLinkResult(_args);
        }
    }
    export interface ICancelEventResultArgs {
        success?: ElkEvent.TElkCancelEventResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class CancelEventResult {
        public success?: ElkEvent.TElkCancelEventResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ICancelEventResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("CancelEventResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): CancelEventResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_116: ElkEvent.TElkCancelEventResponse = ElkEvent.TElkCancelEventResponse.read(input);
                            _args.success = value_116;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_117: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_117;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_118: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_118;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new CancelEventResult(_args);
        }
    }
    export interface IDeleteEventResultArgs {
        success?: ElkEvent.TElkDeleteEventResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class DeleteEventResult {
        public success?: ElkEvent.TElkDeleteEventResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IDeleteEventResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("DeleteEventResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): DeleteEventResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_119: ElkEvent.TElkDeleteEventResponse = ElkEvent.TElkDeleteEventResponse.read(input);
                            _args.success = value_119;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_120: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_120;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_121: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_121;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new DeleteEventResult(_args);
        }
    }
    export interface IGetElkUserResultArgs {
        success?: ElkEvent.TElkGetUserResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetElkUserResult {
        public success?: ElkEvent.TElkGetUserResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetElkUserResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetElkUserResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetElkUserResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_122: ElkEvent.TElkGetUserResponse = ElkEvent.TElkGetUserResponse.read(input);
                            _args.success = value_122;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_123: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_123;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_124: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_124;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetElkUserResult(_args);
        }
    }
    export interface ICreateElkUserResultArgs {
        success?: ElkEvent.TElkCreateUserResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class CreateElkUserResult {
        public success?: ElkEvent.TElkCreateUserResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ICreateElkUserResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("CreateElkUserResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): CreateElkUserResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_125: ElkEvent.TElkCreateUserResponse = ElkEvent.TElkCreateUserResponse.read(input);
                            _args.success = value_125;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_126: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_126;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_127: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_127;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new CreateElkUserResult(_args);
        }
    }
    export interface IUpdateElkUserResultArgs {
        success?: ElkEvent.TElkUpdateUserResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class UpdateElkUserResult {
        public success?: ElkEvent.TElkUpdateUserResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IUpdateElkUserResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateElkUserResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateElkUserResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_128: ElkEvent.TElkUpdateUserResponse = ElkEvent.TElkUpdateUserResponse.read(input);
                            _args.success = value_128;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_129: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_129;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_130: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_130;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new UpdateElkUserResult(_args);
        }
    }
    export interface IGetHasCompletedElkProfileResultArgs {
        success?: ElkEvent.TElkGetHasCompletedProfileResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetHasCompletedElkProfileResult {
        public success?: ElkEvent.TElkGetHasCompletedProfileResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetHasCompletedElkProfileResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetHasCompletedElkProfileResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetHasCompletedElkProfileResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_131: ElkEvent.TElkGetHasCompletedProfileResponse = ElkEvent.TElkGetHasCompletedProfileResponse.read(input);
                            _args.success = value_131;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_132: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_132;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_133: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_133;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetHasCompletedElkProfileResult(_args);
        }
    }
    export interface IGetHasElkProfileResultArgs {
        success?: ElkEvent.TElkGetHasProfileResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetHasElkProfileResult {
        public success?: ElkEvent.TElkGetHasProfileResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetHasElkProfileResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetHasElkProfileResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetHasElkProfileResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_134: ElkEvent.TElkGetHasProfileResponse = ElkEvent.TElkGetHasProfileResponse.read(input);
                            _args.success = value_134;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_135: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_135;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_136: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_136;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetHasElkProfileResult(_args);
        }
    }
    export interface ISendEventCommentResultArgs {
        success?: ElkEvent.TElkSendCommentResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class SendEventCommentResult {
        public success?: ElkEvent.TElkSendCommentResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ISendEventCommentResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendEventCommentResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendEventCommentResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_137: ElkEvent.TElkSendCommentResponse = ElkEvent.TElkSendCommentResponse.read(input);
                            _args.success = value_137;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_138: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_138;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_139: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_139;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new SendEventCommentResult(_args);
        }
    }
    export interface ISendTemplateEmailResultArgs {
        success?: ElkEvent.TElkSendTemplateEmailResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class SendTemplateEmailResult {
        public success?: ElkEvent.TElkSendTemplateEmailResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ISendTemplateEmailResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendTemplateEmailResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendTemplateEmailResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_140: ElkEvent.TElkSendTemplateEmailResponse = ElkEvent.TElkSendTemplateEmailResponse.read(input);
                            _args.success = value_140;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_141: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_141;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_142: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_142;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new SendTemplateEmailResult(_args);
        }
    }
    export interface IQueueInvitesResultArgs {
        success?: ElkEvent.TElkQueueInviteResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class QueueInvitesResult {
        public success?: ElkEvent.TElkQueueInviteResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IQueueInvitesResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("QueueInvitesResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): QueueInvitesResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_143: ElkEvent.TElkQueueInviteResponse = ElkEvent.TElkQueueInviteResponse.read(input);
                            _args.success = value_143;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_144: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_144;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_145: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_145;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new QueueInvitesResult(_args);
        }
    }
    export interface IDeletePendingInvitesResultArgs {
        success?: ElkEvent.TElkDeletePendingInviteResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class DeletePendingInvitesResult {
        public success?: ElkEvent.TElkDeletePendingInviteResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IDeletePendingInvitesResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("DeletePendingInvitesResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): DeletePendingInvitesResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_146: ElkEvent.TElkDeletePendingInviteResponse = ElkEvent.TElkDeletePendingInviteResponse.read(input);
                            _args.success = value_146;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_147: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_147;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_148: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_148;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new DeletePendingInvitesResult(_args);
        }
    }
    export interface ISendInvitesResultArgs {
        success?: ElkEvent.TElkSendInviteResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class SendInvitesResult {
        public success?: ElkEvent.TElkSendInviteResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ISendInvitesResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendInvitesResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendInvitesResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_149: ElkEvent.TElkSendInviteResponse = ElkEvent.TElkSendInviteResponse.read(input);
                            _args.success = value_149;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_150: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_150;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_151: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_151;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new SendInvitesResult(_args);
        }
    }
    export interface IGetAlbumDetailsResultArgs {
        success?: ElkEvent.TElkGetAlbumDetailsResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetAlbumDetailsResult {
        public success?: ElkEvent.TElkGetAlbumDetailsResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetAlbumDetailsResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetAlbumDetailsResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetAlbumDetailsResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_152: ElkEvent.TElkGetAlbumDetailsResponse = ElkEvent.TElkGetAlbumDetailsResponse.read(input);
                            _args.success = value_152;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_153: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_153;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_154: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_154;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetAlbumDetailsResult(_args);
        }
    }
    export interface IGetPreviewAlbumDetailsResultArgs {
        success?: ElkEvent.TElkGetAlbumDetailsResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetPreviewAlbumDetailsResult {
        public success?: ElkEvent.TElkGetAlbumDetailsResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetPreviewAlbumDetailsResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetPreviewAlbumDetailsResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetPreviewAlbumDetailsResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_155: ElkEvent.TElkGetAlbumDetailsResponse = ElkEvent.TElkGetAlbumDetailsResponse.read(input);
                            _args.success = value_155;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_156: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_156;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_157: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_157;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetPreviewAlbumDetailsResult(_args);
        }
    }
    export interface IGetAlbumForElkResultArgs {
        success?: ElkEvent.TElkFetchAlbumResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetAlbumForElkResult {
        public success?: ElkEvent.TElkFetchAlbumResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetAlbumForElkResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetAlbumForElkResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetAlbumForElkResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_158: ElkEvent.TElkFetchAlbumResponse = ElkEvent.TElkFetchAlbumResponse.read(input);
                            _args.success = value_158;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_159: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_159;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_160: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_160;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetAlbumForElkResult(_args);
        }
    }
    export interface IFetchContactsResultArgs {
        success?: ElkEvent.TElkFetchContactsResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class FetchContactsResult {
        public success?: ElkEvent.TElkFetchContactsResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IFetchContactsResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchContactsResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchContactsResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_161: ElkEvent.TElkFetchContactsResponse = ElkEvent.TElkFetchContactsResponse.read(input);
                            _args.success = value_161;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_162: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_162;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_163: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_163;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new FetchContactsResult(_args);
        }
    }
    export interface IFetchDraftResultArgs {
        success?: ElkEvent.TElkFetchDraftResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class FetchDraftResult {
        public success?: ElkEvent.TElkFetchDraftResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IFetchDraftResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchDraftResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchDraftResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_164: ElkEvent.TElkFetchDraftResponse = ElkEvent.TElkFetchDraftResponse.read(input);
                            _args.success = value_164;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_165: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_165;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_166: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_166;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new FetchDraftResult(_args);
        }
    }
    export interface IFetchMutualMembersResultArgs {
        success?: ElkEvent.TElkFetchMutualMembersResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class FetchMutualMembersResult {
        public success?: ElkEvent.TElkFetchMutualMembersResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IFetchMutualMembersResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchMutualMembersResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchMutualMembersResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_167: ElkEvent.TElkFetchMutualMembersResponse = ElkEvent.TElkFetchMutualMembersResponse.read(input);
                            _args.success = value_167;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_168: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_168;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_169: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_169;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new FetchMutualMembersResult(_args);
        }
    }
    export interface IFetchDraftsResultArgs {
        success?: ElkEvent.TElkFetchDraftsResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class FetchDraftsResult {
        public success?: ElkEvent.TElkFetchDraftsResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IFetchDraftsResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchDraftsResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchDraftsResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_170: ElkEvent.TElkFetchDraftsResponse = ElkEvent.TElkFetchDraftsResponse.read(input);
                            _args.success = value_170;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_171: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_171;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_172: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_172;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new FetchDraftsResult(_args);
        }
    }
    export interface IDeleteDraftResultArgs {
        success?: ElkEvent.TElkDeleteDraftResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class DeleteDraftResult {
        public success?: ElkEvent.TElkDeleteDraftResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IDeleteDraftResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("DeleteDraftResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): DeleteDraftResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_173: ElkEvent.TElkDeleteDraftResponse = ElkEvent.TElkDeleteDraftResponse.read(input);
                            _args.success = value_173;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_174: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_174;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_175: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_175;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new DeleteDraftResult(_args);
        }
    }
    export class Client {
        public _seqid: number;
        public _reqs: {
            [name: number]: (err: Error | object | undefined, val?: any) => void;
        };
        public output: thrift.TTransport;
        public protocol: new (trans: thrift.TTransport) => thrift.TProtocol;
        constructor(output: thrift.TTransport, protocol: new (trans: thrift.TTransport) => thrift.TProtocol) {
            this._seqid = 0;
            this._reqs = {};
            this.output = output;
            this.protocol = protocol;
        }
        public incrementSeqId(): number {
            return this._seqid += 1;
        }
        public fetchEvents(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchEventsRequest): Promise<ElkEvent.TElkFetchEventsResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkFetchEventsResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_fetchEvents(header, request, requestId);
            });
        }
        public getEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetEventRequest): Promise<ElkEvent.TElkGetEventResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkGetEventResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getEvent(header, request, requestId);
            });
        }
        public getEventPreview(header: PHeader.TUnauthHeader, request: ElkEvent.TElkGetEventPreviewRequest): Promise<ElkEvent.TElkGetEventPreviewResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkGetEventPreviewResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getEventPreview(header, request, requestId);
            });
        }
        public getEventMessageHistory(header: PHeader.TUnauthHeader, request: ElkEvent.TElkGetMessageHistoryRequest): Promise<ElkEvent.TElkGetMessageHistoryResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkGetMessageHistoryResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getEventMessageHistory(header, request, requestId);
            });
        }
        public getEventImageHistory(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetImageHistoryRequest): Promise<ElkEvent.TElkGetImageHistoryResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkGetImageHistoryResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getEventImageHistory(header, request, requestId);
            });
        }
        public getUserEventMessageHistory(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetMessageHistoryRequest): Promise<ElkEvent.TElkGetMessageHistoryResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkGetMessageHistoryResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getUserEventMessageHistory(header, request, requestId);
            });
        }
        public createEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkCreateEventRequest): Promise<ElkEvent.TElkCreateEventResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkCreateEventResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_createEvent(header, request, requestId);
            });
        }
        public createDraftEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkCreateEventDraftRequest): Promise<ElkEvent.TElkCreateEventDraftResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkCreateEventDraftResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_createDraftEvent(header, request, requestId);
            });
        }
        public getEventShortLink(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetEventShortLinkRequest): Promise<ElkEvent.TElkGetEventShortLinkResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkGetEventShortLinkResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getEventShortLink(header, request, requestId);
            });
        }
        public editEventDetails(header: PHeader.TAuthHeader, request: ElkEvent.TElkEditEventDetailsRequest): Promise<ElkEvent.TElkEditEventDetailsResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkEditEventDetailsResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_editEventDetails(header, request, requestId);
            });
        }
        public editEventDraft(header: PHeader.TAuthHeader, request: ElkEvent.TElkEditEventDraftRequest): Promise<ElkEvent.TElkEditEventDraftResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkEditEventDraftResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_editEventDraft(header, request, requestId);
            });
        }
        public updateAttendance(header: PHeader.TAuthHeader, request: ElkEvent.TElkUpdateAttendanceRequest): Promise<ElkEvent.TElkUpdateAttendanceResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkUpdateAttendanceResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_updateAttendance(header, request, requestId);
            });
        }
        public editInvitee(header: PHeader.TAuthHeader, request: ElkEvent.TElkEditInviteeRequest): Promise<ElkEvent.TElkEditInviteeResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkEditInviteeResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_editInvitee(header, request, requestId);
            });
        }
        public updateAttendanceThroughEmail(header: PHeader.TUnauthHeader, request: ElkEvent.TElkUpdateAttendanceRequest): Promise<ElkEvent.TElkUpdateAttendanceResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkUpdateAttendanceResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_updateAttendanceThroughEmail(header, request, requestId);
            });
        }
        public updateAttendanceByLink(header: PHeader.TUnauthHeader, request: ElkEvent.TElkUpdateAttendanceRequest): Promise<ElkEvent.TElkUpdateAttendanceResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkUpdateAttendanceResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_updateAttendanceByLink(header, request, requestId);
            });
        }
        public cancelEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkCancelEventRequest): Promise<ElkEvent.TElkCancelEventResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkCancelEventResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_cancelEvent(header, request, requestId);
            });
        }
        public deleteEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkDeleteEventRequest): Promise<ElkEvent.TElkDeleteEventResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkDeleteEventResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_deleteEvent(header, request, requestId);
            });
        }
        public getElkUser(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetUserRequest): Promise<ElkEvent.TElkGetUserResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkGetUserResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getElkUser(header, request, requestId);
            });
        }
        public createElkUser(header: PHeader.TAuthHeader, request: ElkEvent.TElkCreateUserRequest): Promise<ElkEvent.TElkCreateUserResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkCreateUserResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_createElkUser(header, request, requestId);
            });
        }
        public updateElkUser(header: PHeader.TAuthHeader, request: ElkEvent.TElkUpdateUserRequest): Promise<ElkEvent.TElkUpdateUserResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkUpdateUserResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_updateElkUser(header, request, requestId);
            });
        }
        public getHasCompletedElkProfile(header: PHeader.TUnauthHeader, request: ElkEvent.TElkGetHasCompletedProfileRequest): Promise<ElkEvent.TElkGetHasCompletedProfileResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkGetHasCompletedProfileResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getHasCompletedElkProfile(header, request, requestId);
            });
        }
        public getHasElkProfile(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetHasProfileRequest): Promise<ElkEvent.TElkGetHasProfileResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkGetHasProfileResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getHasElkProfile(header, request, requestId);
            });
        }
        public sendEventComment(header: PHeader.TAuthHeader, request: ElkEvent.TElkSendCommentRequest): Promise<ElkEvent.TElkSendCommentResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkSendCommentResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_sendEventComment(header, request, requestId);
            });
        }
        public sendTemplateEmail(header: PHeader.TAuthHeader, request: ElkEvent.TElkSendTemplateEmailRequest): Promise<ElkEvent.TElkSendTemplateEmailResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkSendTemplateEmailResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_sendTemplateEmail(header, request, requestId);
            });
        }
        public queueInvites(header: PHeader.TAuthHeader, request: ElkEvent.TElkQueueInviteRequest): Promise<ElkEvent.TElkQueueInviteResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkQueueInviteResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_queueInvites(header, request, requestId);
            });
        }
        public deletePendingInvites(header: PHeader.TAuthHeader, request: ElkEvent.TElkDeletePendingInviteRequest): Promise<ElkEvent.TElkDeletePendingInviteResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkDeletePendingInviteResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_deletePendingInvites(header, request, requestId);
            });
        }
        public sendInvites(header: PHeader.TAuthHeader, request: ElkEvent.TElkSendInviteRequest): Promise<ElkEvent.TElkSendInviteResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkSendInviteResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_sendInvites(header, request, requestId);
            });
        }
        public getAlbumDetails(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetAlbumDetailsRequest): Promise<ElkEvent.TElkGetAlbumDetailsResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkGetAlbumDetailsResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getAlbumDetails(header, request, requestId);
            });
        }
        public getPreviewAlbumDetails(header: PHeader.TUnauthHeader, request: ElkEvent.TElkGetAlbumDetailsRequest): Promise<ElkEvent.TElkGetAlbumDetailsResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkGetAlbumDetailsResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getPreviewAlbumDetails(header, request, requestId);
            });
        }
        public getAlbumForElk(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchAlbumRequest): Promise<ElkEvent.TElkFetchAlbumResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkFetchAlbumResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getAlbumForElk(header, request, requestId);
            });
        }
        public fetchContacts(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchContactsRequest): Promise<ElkEvent.TElkFetchContactsResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkFetchContactsResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_fetchContacts(header, request, requestId);
            });
        }
        public fetchDraft(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchDraftRequest): Promise<ElkEvent.TElkFetchDraftResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkFetchDraftResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_fetchDraft(header, request, requestId);
            });
        }
        public fetchMutualMembers(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchMutualMembersRequest): Promise<ElkEvent.TElkFetchMutualMembersResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkFetchMutualMembersResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_fetchMutualMembers(header, request, requestId);
            });
        }
        public fetchDrafts(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchDraftsRequest): Promise<ElkEvent.TElkFetchDraftsResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkFetchDraftsResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_fetchDrafts(header, request, requestId);
            });
        }
        public deleteDraft(header: PHeader.TAuthHeader, request: ElkEvent.TElkDeleteDraftRequest): Promise<ElkEvent.TElkDeleteDraftResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkEvent.TElkDeleteDraftResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_deleteDraft(header, request, requestId);
            });
        }
        public send_fetchEvents(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchEventsRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("fetchEvents", thrift.Thrift.MessageType.CALL, requestId);
            const args: FetchEventsArgs = new FetchEventsArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetEventRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getEvent", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetEventArgs = new GetEventArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getEventPreview(header: PHeader.TUnauthHeader, request: ElkEvent.TElkGetEventPreviewRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getEventPreview", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetEventPreviewArgs = new GetEventPreviewArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getEventMessageHistory(header: PHeader.TUnauthHeader, request: ElkEvent.TElkGetMessageHistoryRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getEventMessageHistory", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetEventMessageHistoryArgs = new GetEventMessageHistoryArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getEventImageHistory(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetImageHistoryRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getEventImageHistory", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetEventImageHistoryArgs = new GetEventImageHistoryArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getUserEventMessageHistory(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetMessageHistoryRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getUserEventMessageHistory", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetUserEventMessageHistoryArgs = new GetUserEventMessageHistoryArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_createEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkCreateEventRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("createEvent", thrift.Thrift.MessageType.CALL, requestId);
            const args: CreateEventArgs = new CreateEventArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_createDraftEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkCreateEventDraftRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("createDraftEvent", thrift.Thrift.MessageType.CALL, requestId);
            const args: CreateDraftEventArgs = new CreateDraftEventArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getEventShortLink(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetEventShortLinkRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getEventShortLink", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetEventShortLinkArgs = new GetEventShortLinkArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_editEventDetails(header: PHeader.TAuthHeader, request: ElkEvent.TElkEditEventDetailsRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("editEventDetails", thrift.Thrift.MessageType.CALL, requestId);
            const args: EditEventDetailsArgs = new EditEventDetailsArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_editEventDraft(header: PHeader.TAuthHeader, request: ElkEvent.TElkEditEventDraftRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("editEventDraft", thrift.Thrift.MessageType.CALL, requestId);
            const args: EditEventDraftArgs = new EditEventDraftArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_updateAttendance(header: PHeader.TAuthHeader, request: ElkEvent.TElkUpdateAttendanceRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("updateAttendance", thrift.Thrift.MessageType.CALL, requestId);
            const args: UpdateAttendanceArgs = new UpdateAttendanceArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_editInvitee(header: PHeader.TAuthHeader, request: ElkEvent.TElkEditInviteeRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("editInvitee", thrift.Thrift.MessageType.CALL, requestId);
            const args: EditInviteeArgs = new EditInviteeArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_updateAttendanceThroughEmail(header: PHeader.TUnauthHeader, request: ElkEvent.TElkUpdateAttendanceRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("updateAttendanceThroughEmail", thrift.Thrift.MessageType.CALL, requestId);
            const args: UpdateAttendanceThroughEmailArgs = new UpdateAttendanceThroughEmailArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_updateAttendanceByLink(header: PHeader.TUnauthHeader, request: ElkEvent.TElkUpdateAttendanceRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("updateAttendanceByLink", thrift.Thrift.MessageType.CALL, requestId);
            const args: UpdateAttendanceByLinkArgs = new UpdateAttendanceByLinkArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_cancelEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkCancelEventRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("cancelEvent", thrift.Thrift.MessageType.CALL, requestId);
            const args: CancelEventArgs = new CancelEventArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_deleteEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkDeleteEventRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("deleteEvent", thrift.Thrift.MessageType.CALL, requestId);
            const args: DeleteEventArgs = new DeleteEventArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getElkUser(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetUserRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getElkUser", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetElkUserArgs = new GetElkUserArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_createElkUser(header: PHeader.TAuthHeader, request: ElkEvent.TElkCreateUserRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("createElkUser", thrift.Thrift.MessageType.CALL, requestId);
            const args: CreateElkUserArgs = new CreateElkUserArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_updateElkUser(header: PHeader.TAuthHeader, request: ElkEvent.TElkUpdateUserRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("updateElkUser", thrift.Thrift.MessageType.CALL, requestId);
            const args: UpdateElkUserArgs = new UpdateElkUserArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getHasCompletedElkProfile(header: PHeader.TUnauthHeader, request: ElkEvent.TElkGetHasCompletedProfileRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getHasCompletedElkProfile", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetHasCompletedElkProfileArgs = new GetHasCompletedElkProfileArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getHasElkProfile(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetHasProfileRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getHasElkProfile", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetHasElkProfileArgs = new GetHasElkProfileArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_sendEventComment(header: PHeader.TAuthHeader, request: ElkEvent.TElkSendCommentRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("sendEventComment", thrift.Thrift.MessageType.CALL, requestId);
            const args: SendEventCommentArgs = new SendEventCommentArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_sendTemplateEmail(header: PHeader.TAuthHeader, request: ElkEvent.TElkSendTemplateEmailRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("sendTemplateEmail", thrift.Thrift.MessageType.CALL, requestId);
            const args: SendTemplateEmailArgs = new SendTemplateEmailArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_queueInvites(header: PHeader.TAuthHeader, request: ElkEvent.TElkQueueInviteRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("queueInvites", thrift.Thrift.MessageType.CALL, requestId);
            const args: QueueInvitesArgs = new QueueInvitesArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_deletePendingInvites(header: PHeader.TAuthHeader, request: ElkEvent.TElkDeletePendingInviteRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("deletePendingInvites", thrift.Thrift.MessageType.CALL, requestId);
            const args: DeletePendingInvitesArgs = new DeletePendingInvitesArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_sendInvites(header: PHeader.TAuthHeader, request: ElkEvent.TElkSendInviteRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("sendInvites", thrift.Thrift.MessageType.CALL, requestId);
            const args: SendInvitesArgs = new SendInvitesArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getAlbumDetails(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetAlbumDetailsRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getAlbumDetails", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetAlbumDetailsArgs = new GetAlbumDetailsArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getPreviewAlbumDetails(header: PHeader.TUnauthHeader, request: ElkEvent.TElkGetAlbumDetailsRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getPreviewAlbumDetails", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetPreviewAlbumDetailsArgs = new GetPreviewAlbumDetailsArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getAlbumForElk(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchAlbumRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getAlbumForElk", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetAlbumForElkArgs = new GetAlbumForElkArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_fetchContacts(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchContactsRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("fetchContacts", thrift.Thrift.MessageType.CALL, requestId);
            const args: FetchContactsArgs = new FetchContactsArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_fetchDraft(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchDraftRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("fetchDraft", thrift.Thrift.MessageType.CALL, requestId);
            const args: FetchDraftArgs = new FetchDraftArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_fetchMutualMembers(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchMutualMembersRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("fetchMutualMembers", thrift.Thrift.MessageType.CALL, requestId);
            const args: FetchMutualMembersArgs = new FetchMutualMembersArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_fetchDrafts(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchDraftsRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("fetchDrafts", thrift.Thrift.MessageType.CALL, requestId);
            const args: FetchDraftsArgs = new FetchDraftsArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_deleteDraft(header: PHeader.TAuthHeader, request: ElkEvent.TElkDeleteDraftRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("deleteDraft", thrift.Thrift.MessageType.CALL, requestId);
            const args: DeleteDraftArgs = new DeleteDraftArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public recv_fetchEvents(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: FetchEventsResult = FetchEventsResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "fetchEvents failed: unknown result"));
                    }
                }
            }
        }
        public recv_getEvent(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetEventResult = GetEventResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getEvent failed: unknown result"));
                    }
                }
            }
        }
        public recv_getEventPreview(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetEventPreviewResult = GetEventPreviewResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getEventPreview failed: unknown result"));
                    }
                }
            }
        }
        public recv_getEventMessageHistory(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetEventMessageHistoryResult = GetEventMessageHistoryResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getEventMessageHistory failed: unknown result"));
                    }
                }
            }
        }
        public recv_getEventImageHistory(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetEventImageHistoryResult = GetEventImageHistoryResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getEventImageHistory failed: unknown result"));
                    }
                }
            }
        }
        public recv_getUserEventMessageHistory(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetUserEventMessageHistoryResult = GetUserEventMessageHistoryResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getUserEventMessageHistory failed: unknown result"));
                    }
                }
            }
        }
        public recv_createEvent(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: CreateEventResult = CreateEventResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "createEvent failed: unknown result"));
                    }
                }
            }
        }
        public recv_createDraftEvent(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: CreateDraftEventResult = CreateDraftEventResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "createDraftEvent failed: unknown result"));
                    }
                }
            }
        }
        public recv_getEventShortLink(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetEventShortLinkResult = GetEventShortLinkResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getEventShortLink failed: unknown result"));
                    }
                }
            }
        }
        public recv_editEventDetails(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: EditEventDetailsResult = EditEventDetailsResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "editEventDetails failed: unknown result"));
                    }
                }
            }
        }
        public recv_editEventDraft(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: EditEventDraftResult = EditEventDraftResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "editEventDraft failed: unknown result"));
                    }
                }
            }
        }
        public recv_updateAttendance(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: UpdateAttendanceResult = UpdateAttendanceResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "updateAttendance failed: unknown result"));
                    }
                }
            }
        }
        public recv_editInvitee(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: EditInviteeResult = EditInviteeResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "editInvitee failed: unknown result"));
                    }
                }
            }
        }
        public recv_updateAttendanceThroughEmail(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: UpdateAttendanceThroughEmailResult = UpdateAttendanceThroughEmailResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "updateAttendanceThroughEmail failed: unknown result"));
                    }
                }
            }
        }
        public recv_updateAttendanceByLink(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: UpdateAttendanceByLinkResult = UpdateAttendanceByLinkResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "updateAttendanceByLink failed: unknown result"));
                    }
                }
            }
        }
        public recv_cancelEvent(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: CancelEventResult = CancelEventResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "cancelEvent failed: unknown result"));
                    }
                }
            }
        }
        public recv_deleteEvent(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: DeleteEventResult = DeleteEventResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "deleteEvent failed: unknown result"));
                    }
                }
            }
        }
        public recv_getElkUser(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetElkUserResult = GetElkUserResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getElkUser failed: unknown result"));
                    }
                }
            }
        }
        public recv_createElkUser(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: CreateElkUserResult = CreateElkUserResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "createElkUser failed: unknown result"));
                    }
                }
            }
        }
        public recv_updateElkUser(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: UpdateElkUserResult = UpdateElkUserResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "updateElkUser failed: unknown result"));
                    }
                }
            }
        }
        public recv_getHasCompletedElkProfile(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetHasCompletedElkProfileResult = GetHasCompletedElkProfileResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getHasCompletedElkProfile failed: unknown result"));
                    }
                }
            }
        }
        public recv_getHasElkProfile(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetHasElkProfileResult = GetHasElkProfileResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getHasElkProfile failed: unknown result"));
                    }
                }
            }
        }
        public recv_sendEventComment(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: SendEventCommentResult = SendEventCommentResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "sendEventComment failed: unknown result"));
                    }
                }
            }
        }
        public recv_sendTemplateEmail(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: SendTemplateEmailResult = SendTemplateEmailResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "sendTemplateEmail failed: unknown result"));
                    }
                }
            }
        }
        public recv_queueInvites(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: QueueInvitesResult = QueueInvitesResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "queueInvites failed: unknown result"));
                    }
                }
            }
        }
        public recv_deletePendingInvites(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: DeletePendingInvitesResult = DeletePendingInvitesResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "deletePendingInvites failed: unknown result"));
                    }
                }
            }
        }
        public recv_sendInvites(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: SendInvitesResult = SendInvitesResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "sendInvites failed: unknown result"));
                    }
                }
            }
        }
        public recv_getAlbumDetails(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetAlbumDetailsResult = GetAlbumDetailsResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getAlbumDetails failed: unknown result"));
                    }
                }
            }
        }
        public recv_getPreviewAlbumDetails(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetPreviewAlbumDetailsResult = GetPreviewAlbumDetailsResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getPreviewAlbumDetails failed: unknown result"));
                    }
                }
            }
        }
        public recv_getAlbumForElk(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetAlbumForElkResult = GetAlbumForElkResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getAlbumForElk failed: unknown result"));
                    }
                }
            }
        }
        public recv_fetchContacts(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: FetchContactsResult = FetchContactsResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "fetchContacts failed: unknown result"));
                    }
                }
            }
        }
        public recv_fetchDraft(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: FetchDraftResult = FetchDraftResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "fetchDraft failed: unknown result"));
                    }
                }
            }
        }
        public recv_fetchMutualMembers(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: FetchMutualMembersResult = FetchMutualMembersResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "fetchMutualMembers failed: unknown result"));
                    }
                }
            }
        }
        public recv_fetchDrafts(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: FetchDraftsResult = FetchDraftsResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "fetchDrafts failed: unknown result"));
                    }
                }
            }
        }
        public recv_deleteDraft(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: DeleteDraftResult = DeleteDraftResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "deleteDraft failed: unknown result"));
                    }
                }
            }
        }
    }
    export interface IHandler {
        fetchEvents(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchEventsRequest): ElkEvent.TElkFetchEventsResponse | Promise<ElkEvent.TElkFetchEventsResponse>;
        getEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetEventRequest): ElkEvent.TElkGetEventResponse | Promise<ElkEvent.TElkGetEventResponse>;
        getEventPreview(header: PHeader.TUnauthHeader, request: ElkEvent.TElkGetEventPreviewRequest): ElkEvent.TElkGetEventPreviewResponse | Promise<ElkEvent.TElkGetEventPreviewResponse>;
        getEventMessageHistory(header: PHeader.TUnauthHeader, request: ElkEvent.TElkGetMessageHistoryRequest): ElkEvent.TElkGetMessageHistoryResponse | Promise<ElkEvent.TElkGetMessageHistoryResponse>;
        getEventImageHistory(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetImageHistoryRequest): ElkEvent.TElkGetImageHistoryResponse | Promise<ElkEvent.TElkGetImageHistoryResponse>;
        getUserEventMessageHistory(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetMessageHistoryRequest): ElkEvent.TElkGetMessageHistoryResponse | Promise<ElkEvent.TElkGetMessageHistoryResponse>;
        createEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkCreateEventRequest): ElkEvent.TElkCreateEventResponse | Promise<ElkEvent.TElkCreateEventResponse>;
        createDraftEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkCreateEventDraftRequest): ElkEvent.TElkCreateEventDraftResponse | Promise<ElkEvent.TElkCreateEventDraftResponse>;
        getEventShortLink(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetEventShortLinkRequest): ElkEvent.TElkGetEventShortLinkResponse | Promise<ElkEvent.TElkGetEventShortLinkResponse>;
        editEventDetails(header: PHeader.TAuthHeader, request: ElkEvent.TElkEditEventDetailsRequest): ElkEvent.TElkEditEventDetailsResponse | Promise<ElkEvent.TElkEditEventDetailsResponse>;
        editEventDraft(header: PHeader.TAuthHeader, request: ElkEvent.TElkEditEventDraftRequest): ElkEvent.TElkEditEventDraftResponse | Promise<ElkEvent.TElkEditEventDraftResponse>;
        updateAttendance(header: PHeader.TAuthHeader, request: ElkEvent.TElkUpdateAttendanceRequest): ElkEvent.TElkUpdateAttendanceResponse | Promise<ElkEvent.TElkUpdateAttendanceResponse>;
        editInvitee(header: PHeader.TAuthHeader, request: ElkEvent.TElkEditInviteeRequest): ElkEvent.TElkEditInviteeResponse | Promise<ElkEvent.TElkEditInviteeResponse>;
        updateAttendanceThroughEmail(header: PHeader.TUnauthHeader, request: ElkEvent.TElkUpdateAttendanceRequest): ElkEvent.TElkUpdateAttendanceResponse | Promise<ElkEvent.TElkUpdateAttendanceResponse>;
        updateAttendanceByLink(header: PHeader.TUnauthHeader, request: ElkEvent.TElkUpdateAttendanceRequest): ElkEvent.TElkUpdateAttendanceResponse | Promise<ElkEvent.TElkUpdateAttendanceResponse>;
        cancelEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkCancelEventRequest): ElkEvent.TElkCancelEventResponse | Promise<ElkEvent.TElkCancelEventResponse>;
        deleteEvent(header: PHeader.TAuthHeader, request: ElkEvent.TElkDeleteEventRequest): ElkEvent.TElkDeleteEventResponse | Promise<ElkEvent.TElkDeleteEventResponse>;
        getElkUser(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetUserRequest): ElkEvent.TElkGetUserResponse | Promise<ElkEvent.TElkGetUserResponse>;
        createElkUser(header: PHeader.TAuthHeader, request: ElkEvent.TElkCreateUserRequest): ElkEvent.TElkCreateUserResponse | Promise<ElkEvent.TElkCreateUserResponse>;
        updateElkUser(header: PHeader.TAuthHeader, request: ElkEvent.TElkUpdateUserRequest): ElkEvent.TElkUpdateUserResponse | Promise<ElkEvent.TElkUpdateUserResponse>;
        getHasCompletedElkProfile(header: PHeader.TUnauthHeader, request: ElkEvent.TElkGetHasCompletedProfileRequest): ElkEvent.TElkGetHasCompletedProfileResponse | Promise<ElkEvent.TElkGetHasCompletedProfileResponse>;
        getHasElkProfile(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetHasProfileRequest): ElkEvent.TElkGetHasProfileResponse | Promise<ElkEvent.TElkGetHasProfileResponse>;
        sendEventComment(header: PHeader.TAuthHeader, request: ElkEvent.TElkSendCommentRequest): ElkEvent.TElkSendCommentResponse | Promise<ElkEvent.TElkSendCommentResponse>;
        sendTemplateEmail(header: PHeader.TAuthHeader, request: ElkEvent.TElkSendTemplateEmailRequest): ElkEvent.TElkSendTemplateEmailResponse | Promise<ElkEvent.TElkSendTemplateEmailResponse>;
        queueInvites(header: PHeader.TAuthHeader, request: ElkEvent.TElkQueueInviteRequest): ElkEvent.TElkQueueInviteResponse | Promise<ElkEvent.TElkQueueInviteResponse>;
        deletePendingInvites(header: PHeader.TAuthHeader, request: ElkEvent.TElkDeletePendingInviteRequest): ElkEvent.TElkDeletePendingInviteResponse | Promise<ElkEvent.TElkDeletePendingInviteResponse>;
        sendInvites(header: PHeader.TAuthHeader, request: ElkEvent.TElkSendInviteRequest): ElkEvent.TElkSendInviteResponse | Promise<ElkEvent.TElkSendInviteResponse>;
        getAlbumDetails(header: PHeader.TAuthHeader, request: ElkEvent.TElkGetAlbumDetailsRequest): ElkEvent.TElkGetAlbumDetailsResponse | Promise<ElkEvent.TElkGetAlbumDetailsResponse>;
        getPreviewAlbumDetails(header: PHeader.TUnauthHeader, request: ElkEvent.TElkGetAlbumDetailsRequest): ElkEvent.TElkGetAlbumDetailsResponse | Promise<ElkEvent.TElkGetAlbumDetailsResponse>;
        getAlbumForElk(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchAlbumRequest): ElkEvent.TElkFetchAlbumResponse | Promise<ElkEvent.TElkFetchAlbumResponse>;
        fetchContacts(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchContactsRequest): ElkEvent.TElkFetchContactsResponse | Promise<ElkEvent.TElkFetchContactsResponse>;
        fetchDraft(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchDraftRequest): ElkEvent.TElkFetchDraftResponse | Promise<ElkEvent.TElkFetchDraftResponse>;
        fetchMutualMembers(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchMutualMembersRequest): ElkEvent.TElkFetchMutualMembersResponse | Promise<ElkEvent.TElkFetchMutualMembersResponse>;
        fetchDrafts(header: PHeader.TAuthHeader, request: ElkEvent.TElkFetchDraftsRequest): ElkEvent.TElkFetchDraftsResponse | Promise<ElkEvent.TElkFetchDraftsResponse>;
        deleteDraft(header: PHeader.TAuthHeader, request: ElkEvent.TElkDeleteDraftRequest): ElkEvent.TElkDeleteDraftResponse | Promise<ElkEvent.TElkDeleteDraftResponse>;
    }
    export class Processor {
        public _handler: IHandler;
        constructor(handler: IHandler) {
            this._handler = handler;
        }
        public process(input: thrift.TProtocol, output: thrift.TProtocol): void {
            const metadata: thrift.TMessage = input.readMessageBegin();
            const fname: string = metadata.fname;
            const requestId: number = metadata.rseqid;
            const methodName: string = "process_" + fname;
            switch (methodName) {
                case "process_fetchEvents": {
                    this.process_fetchEvents(requestId, input, output);
                    return;
                }
                case "process_getEvent": {
                    this.process_getEvent(requestId, input, output);
                    return;
                }
                case "process_getEventPreview": {
                    this.process_getEventPreview(requestId, input, output);
                    return;
                }
                case "process_getEventMessageHistory": {
                    this.process_getEventMessageHistory(requestId, input, output);
                    return;
                }
                case "process_getEventImageHistory": {
                    this.process_getEventImageHistory(requestId, input, output);
                    return;
                }
                case "process_getUserEventMessageHistory": {
                    this.process_getUserEventMessageHistory(requestId, input, output);
                    return;
                }
                case "process_createEvent": {
                    this.process_createEvent(requestId, input, output);
                    return;
                }
                case "process_createDraftEvent": {
                    this.process_createDraftEvent(requestId, input, output);
                    return;
                }
                case "process_getEventShortLink": {
                    this.process_getEventShortLink(requestId, input, output);
                    return;
                }
                case "process_editEventDetails": {
                    this.process_editEventDetails(requestId, input, output);
                    return;
                }
                case "process_editEventDraft": {
                    this.process_editEventDraft(requestId, input, output);
                    return;
                }
                case "process_updateAttendance": {
                    this.process_updateAttendance(requestId, input, output);
                    return;
                }
                case "process_editInvitee": {
                    this.process_editInvitee(requestId, input, output);
                    return;
                }
                case "process_updateAttendanceThroughEmail": {
                    this.process_updateAttendanceThroughEmail(requestId, input, output);
                    return;
                }
                case "process_updateAttendanceByLink": {
                    this.process_updateAttendanceByLink(requestId, input, output);
                    return;
                }
                case "process_cancelEvent": {
                    this.process_cancelEvent(requestId, input, output);
                    return;
                }
                case "process_deleteEvent": {
                    this.process_deleteEvent(requestId, input, output);
                    return;
                }
                case "process_getElkUser": {
                    this.process_getElkUser(requestId, input, output);
                    return;
                }
                case "process_createElkUser": {
                    this.process_createElkUser(requestId, input, output);
                    return;
                }
                case "process_updateElkUser": {
                    this.process_updateElkUser(requestId, input, output);
                    return;
                }
                case "process_getHasCompletedElkProfile": {
                    this.process_getHasCompletedElkProfile(requestId, input, output);
                    return;
                }
                case "process_getHasElkProfile": {
                    this.process_getHasElkProfile(requestId, input, output);
                    return;
                }
                case "process_sendEventComment": {
                    this.process_sendEventComment(requestId, input, output);
                    return;
                }
                case "process_sendTemplateEmail": {
                    this.process_sendTemplateEmail(requestId, input, output);
                    return;
                }
                case "process_queueInvites": {
                    this.process_queueInvites(requestId, input, output);
                    return;
                }
                case "process_deletePendingInvites": {
                    this.process_deletePendingInvites(requestId, input, output);
                    return;
                }
                case "process_sendInvites": {
                    this.process_sendInvites(requestId, input, output);
                    return;
                }
                case "process_getAlbumDetails": {
                    this.process_getAlbumDetails(requestId, input, output);
                    return;
                }
                case "process_getPreviewAlbumDetails": {
                    this.process_getPreviewAlbumDetails(requestId, input, output);
                    return;
                }
                case "process_getAlbumForElk": {
                    this.process_getAlbumForElk(requestId, input, output);
                    return;
                }
                case "process_fetchContacts": {
                    this.process_fetchContacts(requestId, input, output);
                    return;
                }
                case "process_fetchDraft": {
                    this.process_fetchDraft(requestId, input, output);
                    return;
                }
                case "process_fetchMutualMembers": {
                    this.process_fetchMutualMembers(requestId, input, output);
                    return;
                }
                case "process_fetchDrafts": {
                    this.process_fetchDrafts(requestId, input, output);
                    return;
                }
                case "process_deleteDraft": {
                    this.process_deleteDraft(requestId, input, output);
                    return;
                }
                default: {
                    input.skip(thrift.Thrift.Type.STRUCT);
                    input.readMessageEnd();
                    const errMessage = "Unknown function " + fname;
                    const err = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN_METHOD, errMessage);
                    output.writeMessageBegin(fname, thrift.Thrift.MessageType.EXCEPTION, requestId);
                    err.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            }
        }
        public process_fetchEvents(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkFetchEventsResponse>((resolve, reject): void => {
                try {
                    const args: FetchEventsArgs = FetchEventsArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.fetchEvents(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkFetchEventsResponse): void => {
                const result: FetchEventsResult = new FetchEventsResult({ success: data });
                output.writeMessageBegin("fetchEvents", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: FetchEventsResult = new FetchEventsResult({ authFailedError: err });
                    output.writeMessageBegin("fetchEvents", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: FetchEventsResult = new FetchEventsResult({ invalidRequestError: err });
                    output.writeMessageBegin("fetchEvents", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("fetchEvents", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getEvent(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkGetEventResponse>((resolve, reject): void => {
                try {
                    const args: GetEventArgs = GetEventArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getEvent(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkGetEventResponse): void => {
                const result: GetEventResult = new GetEventResult({ success: data });
                output.writeMessageBegin("getEvent", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetEventResult = new GetEventResult({ authFailedError: err });
                    output.writeMessageBegin("getEvent", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetEventResult = new GetEventResult({ invalidRequestError: err });
                    output.writeMessageBegin("getEvent", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getEvent", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getEventPreview(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkGetEventPreviewResponse>((resolve, reject): void => {
                try {
                    const args: GetEventPreviewArgs = GetEventPreviewArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getEventPreview(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkGetEventPreviewResponse): void => {
                const result: GetEventPreviewResult = new GetEventPreviewResult({ success: data });
                output.writeMessageBegin("getEventPreview", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetEventPreviewResult = new GetEventPreviewResult({ authFailedError: err });
                    output.writeMessageBegin("getEventPreview", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetEventPreviewResult = new GetEventPreviewResult({ invalidRequestError: err });
                    output.writeMessageBegin("getEventPreview", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getEventPreview", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getEventMessageHistory(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkGetMessageHistoryResponse>((resolve, reject): void => {
                try {
                    const args: GetEventMessageHistoryArgs = GetEventMessageHistoryArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getEventMessageHistory(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkGetMessageHistoryResponse): void => {
                const result: GetEventMessageHistoryResult = new GetEventMessageHistoryResult({ success: data });
                output.writeMessageBegin("getEventMessageHistory", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetEventMessageHistoryResult = new GetEventMessageHistoryResult({ authFailedError: err });
                    output.writeMessageBegin("getEventMessageHistory", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetEventMessageHistoryResult = new GetEventMessageHistoryResult({ invalidRequestError: err });
                    output.writeMessageBegin("getEventMessageHistory", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getEventMessageHistory", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getEventImageHistory(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkGetImageHistoryResponse>((resolve, reject): void => {
                try {
                    const args: GetEventImageHistoryArgs = GetEventImageHistoryArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getEventImageHistory(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkGetImageHistoryResponse): void => {
                const result: GetEventImageHistoryResult = new GetEventImageHistoryResult({ success: data });
                output.writeMessageBegin("getEventImageHistory", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetEventImageHistoryResult = new GetEventImageHistoryResult({ authFailedError: err });
                    output.writeMessageBegin("getEventImageHistory", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetEventImageHistoryResult = new GetEventImageHistoryResult({ invalidRequestError: err });
                    output.writeMessageBegin("getEventImageHistory", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getEventImageHistory", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getUserEventMessageHistory(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkGetMessageHistoryResponse>((resolve, reject): void => {
                try {
                    const args: GetUserEventMessageHistoryArgs = GetUserEventMessageHistoryArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getUserEventMessageHistory(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkGetMessageHistoryResponse): void => {
                const result: GetUserEventMessageHistoryResult = new GetUserEventMessageHistoryResult({ success: data });
                output.writeMessageBegin("getUserEventMessageHistory", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetUserEventMessageHistoryResult = new GetUserEventMessageHistoryResult({ authFailedError: err });
                    output.writeMessageBegin("getUserEventMessageHistory", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetUserEventMessageHistoryResult = new GetUserEventMessageHistoryResult({ invalidRequestError: err });
                    output.writeMessageBegin("getUserEventMessageHistory", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getUserEventMessageHistory", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_createEvent(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkCreateEventResponse>((resolve, reject): void => {
                try {
                    const args: CreateEventArgs = CreateEventArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.createEvent(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkCreateEventResponse): void => {
                const result: CreateEventResult = new CreateEventResult({ success: data });
                output.writeMessageBegin("createEvent", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: CreateEventResult = new CreateEventResult({ authFailedError: err });
                    output.writeMessageBegin("createEvent", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: CreateEventResult = new CreateEventResult({ invalidRequestError: err });
                    output.writeMessageBegin("createEvent", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("createEvent", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_createDraftEvent(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkCreateEventDraftResponse>((resolve, reject): void => {
                try {
                    const args: CreateDraftEventArgs = CreateDraftEventArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.createDraftEvent(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkCreateEventDraftResponse): void => {
                const result: CreateDraftEventResult = new CreateDraftEventResult({ success: data });
                output.writeMessageBegin("createDraftEvent", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: CreateDraftEventResult = new CreateDraftEventResult({ authFailedError: err });
                    output.writeMessageBegin("createDraftEvent", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: CreateDraftEventResult = new CreateDraftEventResult({ invalidRequestError: err });
                    output.writeMessageBegin("createDraftEvent", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("createDraftEvent", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getEventShortLink(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkGetEventShortLinkResponse>((resolve, reject): void => {
                try {
                    const args: GetEventShortLinkArgs = GetEventShortLinkArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getEventShortLink(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkGetEventShortLinkResponse): void => {
                const result: GetEventShortLinkResult = new GetEventShortLinkResult({ success: data });
                output.writeMessageBegin("getEventShortLink", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetEventShortLinkResult = new GetEventShortLinkResult({ authFailedError: err });
                    output.writeMessageBegin("getEventShortLink", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetEventShortLinkResult = new GetEventShortLinkResult({ invalidRequestError: err });
                    output.writeMessageBegin("getEventShortLink", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getEventShortLink", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_editEventDetails(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkEditEventDetailsResponse>((resolve, reject): void => {
                try {
                    const args: EditEventDetailsArgs = EditEventDetailsArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.editEventDetails(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkEditEventDetailsResponse): void => {
                const result: EditEventDetailsResult = new EditEventDetailsResult({ success: data });
                output.writeMessageBegin("editEventDetails", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: EditEventDetailsResult = new EditEventDetailsResult({ authFailedError: err });
                    output.writeMessageBegin("editEventDetails", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: EditEventDetailsResult = new EditEventDetailsResult({ invalidRequestError: err });
                    output.writeMessageBegin("editEventDetails", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("editEventDetails", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_editEventDraft(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkEditEventDraftResponse>((resolve, reject): void => {
                try {
                    const args: EditEventDraftArgs = EditEventDraftArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.editEventDraft(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkEditEventDraftResponse): void => {
                const result: EditEventDraftResult = new EditEventDraftResult({ success: data });
                output.writeMessageBegin("editEventDraft", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: EditEventDraftResult = new EditEventDraftResult({ authFailedError: err });
                    output.writeMessageBegin("editEventDraft", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: EditEventDraftResult = new EditEventDraftResult({ invalidRequestError: err });
                    output.writeMessageBegin("editEventDraft", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("editEventDraft", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_updateAttendance(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkUpdateAttendanceResponse>((resolve, reject): void => {
                try {
                    const args: UpdateAttendanceArgs = UpdateAttendanceArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.updateAttendance(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkUpdateAttendanceResponse): void => {
                const result: UpdateAttendanceResult = new UpdateAttendanceResult({ success: data });
                output.writeMessageBegin("updateAttendance", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: UpdateAttendanceResult = new UpdateAttendanceResult({ authFailedError: err });
                    output.writeMessageBegin("updateAttendance", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: UpdateAttendanceResult = new UpdateAttendanceResult({ invalidRequestError: err });
                    output.writeMessageBegin("updateAttendance", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("updateAttendance", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_editInvitee(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkEditInviteeResponse>((resolve, reject): void => {
                try {
                    const args: EditInviteeArgs = EditInviteeArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.editInvitee(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkEditInviteeResponse): void => {
                const result: EditInviteeResult = new EditInviteeResult({ success: data });
                output.writeMessageBegin("editInvitee", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: EditInviteeResult = new EditInviteeResult({ authFailedError: err });
                    output.writeMessageBegin("editInvitee", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: EditInviteeResult = new EditInviteeResult({ invalidRequestError: err });
                    output.writeMessageBegin("editInvitee", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("editInvitee", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_updateAttendanceThroughEmail(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkUpdateAttendanceResponse>((resolve, reject): void => {
                try {
                    const args: UpdateAttendanceThroughEmailArgs = UpdateAttendanceThroughEmailArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.updateAttendanceThroughEmail(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkUpdateAttendanceResponse): void => {
                const result: UpdateAttendanceThroughEmailResult = new UpdateAttendanceThroughEmailResult({ success: data });
                output.writeMessageBegin("updateAttendanceThroughEmail", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: UpdateAttendanceThroughEmailResult = new UpdateAttendanceThroughEmailResult({ authFailedError: err });
                    output.writeMessageBegin("updateAttendanceThroughEmail", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: UpdateAttendanceThroughEmailResult = new UpdateAttendanceThroughEmailResult({ invalidRequestError: err });
                    output.writeMessageBegin("updateAttendanceThroughEmail", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("updateAttendanceThroughEmail", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_updateAttendanceByLink(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkUpdateAttendanceResponse>((resolve, reject): void => {
                try {
                    const args: UpdateAttendanceByLinkArgs = UpdateAttendanceByLinkArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.updateAttendanceByLink(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkUpdateAttendanceResponse): void => {
                const result: UpdateAttendanceByLinkResult = new UpdateAttendanceByLinkResult({ success: data });
                output.writeMessageBegin("updateAttendanceByLink", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: UpdateAttendanceByLinkResult = new UpdateAttendanceByLinkResult({ authFailedError: err });
                    output.writeMessageBegin("updateAttendanceByLink", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: UpdateAttendanceByLinkResult = new UpdateAttendanceByLinkResult({ invalidRequestError: err });
                    output.writeMessageBegin("updateAttendanceByLink", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("updateAttendanceByLink", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_cancelEvent(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkCancelEventResponse>((resolve, reject): void => {
                try {
                    const args: CancelEventArgs = CancelEventArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.cancelEvent(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkCancelEventResponse): void => {
                const result: CancelEventResult = new CancelEventResult({ success: data });
                output.writeMessageBegin("cancelEvent", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: CancelEventResult = new CancelEventResult({ authFailedError: err });
                    output.writeMessageBegin("cancelEvent", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: CancelEventResult = new CancelEventResult({ invalidRequestError: err });
                    output.writeMessageBegin("cancelEvent", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("cancelEvent", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_deleteEvent(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkDeleteEventResponse>((resolve, reject): void => {
                try {
                    const args: DeleteEventArgs = DeleteEventArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.deleteEvent(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkDeleteEventResponse): void => {
                const result: DeleteEventResult = new DeleteEventResult({ success: data });
                output.writeMessageBegin("deleteEvent", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: DeleteEventResult = new DeleteEventResult({ authFailedError: err });
                    output.writeMessageBegin("deleteEvent", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: DeleteEventResult = new DeleteEventResult({ invalidRequestError: err });
                    output.writeMessageBegin("deleteEvent", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("deleteEvent", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getElkUser(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkGetUserResponse>((resolve, reject): void => {
                try {
                    const args: GetElkUserArgs = GetElkUserArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getElkUser(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkGetUserResponse): void => {
                const result: GetElkUserResult = new GetElkUserResult({ success: data });
                output.writeMessageBegin("getElkUser", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetElkUserResult = new GetElkUserResult({ authFailedError: err });
                    output.writeMessageBegin("getElkUser", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetElkUserResult = new GetElkUserResult({ invalidRequestError: err });
                    output.writeMessageBegin("getElkUser", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getElkUser", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_createElkUser(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkCreateUserResponse>((resolve, reject): void => {
                try {
                    const args: CreateElkUserArgs = CreateElkUserArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.createElkUser(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkCreateUserResponse): void => {
                const result: CreateElkUserResult = new CreateElkUserResult({ success: data });
                output.writeMessageBegin("createElkUser", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: CreateElkUserResult = new CreateElkUserResult({ authFailedError: err });
                    output.writeMessageBegin("createElkUser", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: CreateElkUserResult = new CreateElkUserResult({ invalidRequestError: err });
                    output.writeMessageBegin("createElkUser", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("createElkUser", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_updateElkUser(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkUpdateUserResponse>((resolve, reject): void => {
                try {
                    const args: UpdateElkUserArgs = UpdateElkUserArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.updateElkUser(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkUpdateUserResponse): void => {
                const result: UpdateElkUserResult = new UpdateElkUserResult({ success: data });
                output.writeMessageBegin("updateElkUser", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: UpdateElkUserResult = new UpdateElkUserResult({ authFailedError: err });
                    output.writeMessageBegin("updateElkUser", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: UpdateElkUserResult = new UpdateElkUserResult({ invalidRequestError: err });
                    output.writeMessageBegin("updateElkUser", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("updateElkUser", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getHasCompletedElkProfile(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkGetHasCompletedProfileResponse>((resolve, reject): void => {
                try {
                    const args: GetHasCompletedElkProfileArgs = GetHasCompletedElkProfileArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getHasCompletedElkProfile(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkGetHasCompletedProfileResponse): void => {
                const result: GetHasCompletedElkProfileResult = new GetHasCompletedElkProfileResult({ success: data });
                output.writeMessageBegin("getHasCompletedElkProfile", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetHasCompletedElkProfileResult = new GetHasCompletedElkProfileResult({ authFailedError: err });
                    output.writeMessageBegin("getHasCompletedElkProfile", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetHasCompletedElkProfileResult = new GetHasCompletedElkProfileResult({ invalidRequestError: err });
                    output.writeMessageBegin("getHasCompletedElkProfile", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getHasCompletedElkProfile", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getHasElkProfile(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkGetHasProfileResponse>((resolve, reject): void => {
                try {
                    const args: GetHasElkProfileArgs = GetHasElkProfileArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getHasElkProfile(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkGetHasProfileResponse): void => {
                const result: GetHasElkProfileResult = new GetHasElkProfileResult({ success: data });
                output.writeMessageBegin("getHasElkProfile", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetHasElkProfileResult = new GetHasElkProfileResult({ authFailedError: err });
                    output.writeMessageBegin("getHasElkProfile", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetHasElkProfileResult = new GetHasElkProfileResult({ invalidRequestError: err });
                    output.writeMessageBegin("getHasElkProfile", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getHasElkProfile", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_sendEventComment(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkSendCommentResponse>((resolve, reject): void => {
                try {
                    const args: SendEventCommentArgs = SendEventCommentArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.sendEventComment(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkSendCommentResponse): void => {
                const result: SendEventCommentResult = new SendEventCommentResult({ success: data });
                output.writeMessageBegin("sendEventComment", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: SendEventCommentResult = new SendEventCommentResult({ authFailedError: err });
                    output.writeMessageBegin("sendEventComment", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: SendEventCommentResult = new SendEventCommentResult({ invalidRequestError: err });
                    output.writeMessageBegin("sendEventComment", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("sendEventComment", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_sendTemplateEmail(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkSendTemplateEmailResponse>((resolve, reject): void => {
                try {
                    const args: SendTemplateEmailArgs = SendTemplateEmailArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.sendTemplateEmail(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkSendTemplateEmailResponse): void => {
                const result: SendTemplateEmailResult = new SendTemplateEmailResult({ success: data });
                output.writeMessageBegin("sendTemplateEmail", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: SendTemplateEmailResult = new SendTemplateEmailResult({ authFailedError: err });
                    output.writeMessageBegin("sendTemplateEmail", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: SendTemplateEmailResult = new SendTemplateEmailResult({ invalidRequestError: err });
                    output.writeMessageBegin("sendTemplateEmail", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("sendTemplateEmail", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_queueInvites(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkQueueInviteResponse>((resolve, reject): void => {
                try {
                    const args: QueueInvitesArgs = QueueInvitesArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.queueInvites(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkQueueInviteResponse): void => {
                const result: QueueInvitesResult = new QueueInvitesResult({ success: data });
                output.writeMessageBegin("queueInvites", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: QueueInvitesResult = new QueueInvitesResult({ authFailedError: err });
                    output.writeMessageBegin("queueInvites", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: QueueInvitesResult = new QueueInvitesResult({ invalidRequestError: err });
                    output.writeMessageBegin("queueInvites", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("queueInvites", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_deletePendingInvites(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkDeletePendingInviteResponse>((resolve, reject): void => {
                try {
                    const args: DeletePendingInvitesArgs = DeletePendingInvitesArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.deletePendingInvites(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkDeletePendingInviteResponse): void => {
                const result: DeletePendingInvitesResult = new DeletePendingInvitesResult({ success: data });
                output.writeMessageBegin("deletePendingInvites", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: DeletePendingInvitesResult = new DeletePendingInvitesResult({ authFailedError: err });
                    output.writeMessageBegin("deletePendingInvites", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: DeletePendingInvitesResult = new DeletePendingInvitesResult({ invalidRequestError: err });
                    output.writeMessageBegin("deletePendingInvites", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("deletePendingInvites", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_sendInvites(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkSendInviteResponse>((resolve, reject): void => {
                try {
                    const args: SendInvitesArgs = SendInvitesArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.sendInvites(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkSendInviteResponse): void => {
                const result: SendInvitesResult = new SendInvitesResult({ success: data });
                output.writeMessageBegin("sendInvites", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: SendInvitesResult = new SendInvitesResult({ authFailedError: err });
                    output.writeMessageBegin("sendInvites", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: SendInvitesResult = new SendInvitesResult({ invalidRequestError: err });
                    output.writeMessageBegin("sendInvites", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("sendInvites", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getAlbumDetails(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkGetAlbumDetailsResponse>((resolve, reject): void => {
                try {
                    const args: GetAlbumDetailsArgs = GetAlbumDetailsArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getAlbumDetails(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkGetAlbumDetailsResponse): void => {
                const result: GetAlbumDetailsResult = new GetAlbumDetailsResult({ success: data });
                output.writeMessageBegin("getAlbumDetails", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetAlbumDetailsResult = new GetAlbumDetailsResult({ authFailedError: err });
                    output.writeMessageBegin("getAlbumDetails", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetAlbumDetailsResult = new GetAlbumDetailsResult({ invalidRequestError: err });
                    output.writeMessageBegin("getAlbumDetails", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getAlbumDetails", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getPreviewAlbumDetails(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkGetAlbumDetailsResponse>((resolve, reject): void => {
                try {
                    const args: GetPreviewAlbumDetailsArgs = GetPreviewAlbumDetailsArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getPreviewAlbumDetails(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkGetAlbumDetailsResponse): void => {
                const result: GetPreviewAlbumDetailsResult = new GetPreviewAlbumDetailsResult({ success: data });
                output.writeMessageBegin("getPreviewAlbumDetails", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetPreviewAlbumDetailsResult = new GetPreviewAlbumDetailsResult({ authFailedError: err });
                    output.writeMessageBegin("getPreviewAlbumDetails", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetPreviewAlbumDetailsResult = new GetPreviewAlbumDetailsResult({ invalidRequestError: err });
                    output.writeMessageBegin("getPreviewAlbumDetails", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getPreviewAlbumDetails", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getAlbumForElk(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkFetchAlbumResponse>((resolve, reject): void => {
                try {
                    const args: GetAlbumForElkArgs = GetAlbumForElkArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getAlbumForElk(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkFetchAlbumResponse): void => {
                const result: GetAlbumForElkResult = new GetAlbumForElkResult({ success: data });
                output.writeMessageBegin("getAlbumForElk", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetAlbumForElkResult = new GetAlbumForElkResult({ authFailedError: err });
                    output.writeMessageBegin("getAlbumForElk", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetAlbumForElkResult = new GetAlbumForElkResult({ invalidRequestError: err });
                    output.writeMessageBegin("getAlbumForElk", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getAlbumForElk", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_fetchContacts(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkFetchContactsResponse>((resolve, reject): void => {
                try {
                    const args: FetchContactsArgs = FetchContactsArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.fetchContacts(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkFetchContactsResponse): void => {
                const result: FetchContactsResult = new FetchContactsResult({ success: data });
                output.writeMessageBegin("fetchContacts", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: FetchContactsResult = new FetchContactsResult({ authFailedError: err });
                    output.writeMessageBegin("fetchContacts", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: FetchContactsResult = new FetchContactsResult({ invalidRequestError: err });
                    output.writeMessageBegin("fetchContacts", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("fetchContacts", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_fetchDraft(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkFetchDraftResponse>((resolve, reject): void => {
                try {
                    const args: FetchDraftArgs = FetchDraftArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.fetchDraft(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkFetchDraftResponse): void => {
                const result: FetchDraftResult = new FetchDraftResult({ success: data });
                output.writeMessageBegin("fetchDraft", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: FetchDraftResult = new FetchDraftResult({ authFailedError: err });
                    output.writeMessageBegin("fetchDraft", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: FetchDraftResult = new FetchDraftResult({ invalidRequestError: err });
                    output.writeMessageBegin("fetchDraft", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("fetchDraft", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_fetchMutualMembers(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkFetchMutualMembersResponse>((resolve, reject): void => {
                try {
                    const args: FetchMutualMembersArgs = FetchMutualMembersArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.fetchMutualMembers(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkFetchMutualMembersResponse): void => {
                const result: FetchMutualMembersResult = new FetchMutualMembersResult({ success: data });
                output.writeMessageBegin("fetchMutualMembers", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: FetchMutualMembersResult = new FetchMutualMembersResult({ authFailedError: err });
                    output.writeMessageBegin("fetchMutualMembers", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: FetchMutualMembersResult = new FetchMutualMembersResult({ invalidRequestError: err });
                    output.writeMessageBegin("fetchMutualMembers", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("fetchMutualMembers", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_fetchDrafts(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkFetchDraftsResponse>((resolve, reject): void => {
                try {
                    const args: FetchDraftsArgs = FetchDraftsArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.fetchDrafts(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkFetchDraftsResponse): void => {
                const result: FetchDraftsResult = new FetchDraftsResult({ success: data });
                output.writeMessageBegin("fetchDrafts", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: FetchDraftsResult = new FetchDraftsResult({ authFailedError: err });
                    output.writeMessageBegin("fetchDrafts", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: FetchDraftsResult = new FetchDraftsResult({ invalidRequestError: err });
                    output.writeMessageBegin("fetchDrafts", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("fetchDrafts", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_deleteDraft(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkEvent.TElkDeleteDraftResponse>((resolve, reject): void => {
                try {
                    const args: DeleteDraftArgs = DeleteDraftArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.deleteDraft(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkEvent.TElkDeleteDraftResponse): void => {
                const result: DeleteDraftResult = new DeleteDraftResult({ success: data });
                output.writeMessageBegin("deleteDraft", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: DeleteDraftResult = new DeleteDraftResult({ authFailedError: err });
                    output.writeMessageBegin("deleteDraft", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: DeleteDraftResult = new DeleteDraftResult({ invalidRequestError: err });
                    output.writeMessageBegin("deleteDraft", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("deleteDraft", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
    }
}
export namespace TElkPaymentService {
    export interface IInitializePaymentArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkPayment.TElkInitializePaymentRequest;
    }
    export class InitializePaymentArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkPayment.TElkInitializePaymentRequest;
        constructor(args: IInitializePaymentArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("InitializePaymentArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): InitializePaymentArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_176: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_176;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_177: ElkPayment.TElkInitializePaymentRequest = ElkPayment.TElkInitializePaymentRequest.read(input);
                            _args.request = value_177;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new InitializePaymentArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read InitializePaymentArgs from input");
            }
        }
    }
    export interface IInitializePaymentResultArgs {
        success?: ElkPayment.TElkInitializePaymentResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class InitializePaymentResult {
        public success?: ElkPayment.TElkInitializePaymentResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IInitializePaymentResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("InitializePaymentResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): InitializePaymentResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_178: ElkPayment.TElkInitializePaymentResponse = ElkPayment.TElkInitializePaymentResponse.read(input);
                            _args.success = value_178;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_179: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_179;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_180: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_180;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new InitializePaymentResult(_args);
        }
    }
    export class Client {
        public _seqid: number;
        public _reqs: {
            [name: number]: (err: Error | object | undefined, val?: any) => void;
        };
        public output: thrift.TTransport;
        public protocol: new (trans: thrift.TTransport) => thrift.TProtocol;
        constructor(output: thrift.TTransport, protocol: new (trans: thrift.TTransport) => thrift.TProtocol) {
            this._seqid = 0;
            this._reqs = {};
            this.output = output;
            this.protocol = protocol;
        }
        public incrementSeqId(): number {
            return this._seqid += 1;
        }
        public initializePayment(header: PHeader.TAuthHeader, request: ElkPayment.TElkInitializePaymentRequest): Promise<ElkPayment.TElkInitializePaymentResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkPayment.TElkInitializePaymentResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_initializePayment(header, request, requestId);
            });
        }
        public send_initializePayment(header: PHeader.TAuthHeader, request: ElkPayment.TElkInitializePaymentRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("initializePayment", thrift.Thrift.MessageType.CALL, requestId);
            const args: InitializePaymentArgs = new InitializePaymentArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public recv_initializePayment(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: InitializePaymentResult = InitializePaymentResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "initializePayment failed: unknown result"));
                    }
                }
            }
        }
    }
    export interface IHandler {
        initializePayment(header: PHeader.TAuthHeader, request: ElkPayment.TElkInitializePaymentRequest): ElkPayment.TElkInitializePaymentResponse | Promise<ElkPayment.TElkInitializePaymentResponse>;
    }
    export class Processor {
        public _handler: IHandler;
        constructor(handler: IHandler) {
            this._handler = handler;
        }
        public process(input: thrift.TProtocol, output: thrift.TProtocol): void {
            const metadata: thrift.TMessage = input.readMessageBegin();
            const fname: string = metadata.fname;
            const requestId: number = metadata.rseqid;
            const methodName: string = "process_" + fname;
            switch (methodName) {
                case "process_initializePayment": {
                    this.process_initializePayment(requestId, input, output);
                    return;
                }
                default: {
                    input.skip(thrift.Thrift.Type.STRUCT);
                    input.readMessageEnd();
                    const errMessage = "Unknown function " + fname;
                    const err = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN_METHOD, errMessage);
                    output.writeMessageBegin(fname, thrift.Thrift.MessageType.EXCEPTION, requestId);
                    err.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            }
        }
        public process_initializePayment(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkPayment.TElkInitializePaymentResponse>((resolve, reject): void => {
                try {
                    const args: InitializePaymentArgs = InitializePaymentArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.initializePayment(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkPayment.TElkInitializePaymentResponse): void => {
                const result: InitializePaymentResult = new InitializePaymentResult({ success: data });
                output.writeMessageBegin("initializePayment", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: InitializePaymentResult = new InitializePaymentResult({ authFailedError: err });
                    output.writeMessageBegin("initializePayment", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: InitializePaymentResult = new InitializePaymentResult({ invalidRequestError: err });
                    output.writeMessageBegin("initializePayment", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("initializePayment", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
    }
}
export namespace TElkThemeService {
    export interface IGetEventThemeSuggestionArgsArgs {
        header: PHeader.TUnauthHeader;
        request: ElkTheme.TElkGetThemeSuggestionRequest;
    }
    export class GetEventThemeSuggestionArgs {
        public header: PHeader.TUnauthHeader;
        public request: ElkTheme.TElkGetThemeSuggestionRequest;
        constructor(args: IGetEventThemeSuggestionArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetEventThemeSuggestionArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetEventThemeSuggestionArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_181: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_181;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_182: ElkTheme.TElkGetThemeSuggestionRequest = ElkTheme.TElkGetThemeSuggestionRequest.read(input);
                            _args.request = value_182;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetEventThemeSuggestionArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetEventThemeSuggestionArgs from input");
            }
        }
    }
    export interface IGenerateEventImageArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkTheme.TElkGenerateImageRequest;
    }
    export class GenerateEventImageArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkTheme.TElkGenerateImageRequest;
        constructor(args: IGenerateEventImageArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GenerateEventImageArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GenerateEventImageArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_183: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_183;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_184: ElkTheme.TElkGenerateImageRequest = ElkTheme.TElkGenerateImageRequest.read(input);
                            _args.request = value_184;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GenerateEventImageArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GenerateEventImageArgs from input");
            }
        }
    }
    export interface ISampleEventImageArgsArgs {
        header: PHeader.TUnauthHeader;
        request: ElkTheme.TElkGenerateSampleImageRequest;
    }
    export class SampleEventImageArgs {
        public header: PHeader.TUnauthHeader;
        public request: ElkTheme.TElkGenerateSampleImageRequest;
        constructor(args: ISampleEventImageArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SampleEventImageArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SampleEventImageArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_185: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_185;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_186: ElkTheme.TElkGenerateSampleImageRequest = ElkTheme.TElkGenerateSampleImageRequest.read(input);
                            _args.request = value_186;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new SampleEventImageArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SampleEventImageArgs from input");
            }
        }
    }
    export interface IGenerateEventFromMadLibsArgsArgs {
        header: PHeader.TUnauthHeader;
        request: ElkTheme.TElkGenerateEventFromMadLibsRequest;
    }
    export class GenerateEventFromMadLibsArgs {
        public header: PHeader.TUnauthHeader;
        public request: ElkTheme.TElkGenerateEventFromMadLibsRequest;
        constructor(args: IGenerateEventFromMadLibsArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GenerateEventFromMadLibsArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GenerateEventFromMadLibsArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_187: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_187;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_188: ElkTheme.TElkGenerateEventFromMadLibsRequest = ElkTheme.TElkGenerateEventFromMadLibsRequest.read(input);
                            _args.request = value_188;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GenerateEventFromMadLibsArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GenerateEventFromMadLibsArgs from input");
            }
        }
    }
    export interface IQuickCreateEventArgsArgs {
        header: PHeader.TAuthHeader;
        request: ElkTheme.TElkGenerateEventFromMadLibsRequest;
    }
    export class QuickCreateEventArgs {
        public header: PHeader.TAuthHeader;
        public request: ElkTheme.TElkGenerateEventFromMadLibsRequest;
        constructor(args: IQuickCreateEventArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("QuickCreateEventArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): QuickCreateEventArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_189: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_189;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_190: ElkTheme.TElkGenerateEventFromMadLibsRequest = ElkTheme.TElkGenerateEventFromMadLibsRequest.read(input);
                            _args.request = value_190;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new QuickCreateEventArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read QuickCreateEventArgs from input");
            }
        }
    }
    export interface IGetEventThemeSuggestionResultArgs {
        success?: ElkTheme.TElkGetThemeSuggestionResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetEventThemeSuggestionResult {
        public success?: ElkTheme.TElkGetThemeSuggestionResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetEventThemeSuggestionResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetEventThemeSuggestionResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetEventThemeSuggestionResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_191: ElkTheme.TElkGetThemeSuggestionResponse = ElkTheme.TElkGetThemeSuggestionResponse.read(input);
                            _args.success = value_191;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_192: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_192;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_193: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_193;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetEventThemeSuggestionResult(_args);
        }
    }
    export interface IGenerateEventImageResultArgs {
        success?: ElkTheme.TElkGenerateImageResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GenerateEventImageResult {
        public success?: ElkTheme.TElkGenerateImageResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGenerateEventImageResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GenerateEventImageResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GenerateEventImageResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_194: ElkTheme.TElkGenerateImageResponse = ElkTheme.TElkGenerateImageResponse.read(input);
                            _args.success = value_194;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_195: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_195;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_196: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_196;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GenerateEventImageResult(_args);
        }
    }
    export interface ISampleEventImageResultArgs {
        success?: ElkTheme.TElkGenerateSampleImageResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class SampleEventImageResult {
        public success?: ElkTheme.TElkGenerateSampleImageResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ISampleEventImageResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SampleEventImageResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SampleEventImageResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_197: ElkTheme.TElkGenerateSampleImageResponse = ElkTheme.TElkGenerateSampleImageResponse.read(input);
                            _args.success = value_197;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_198: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_198;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_199: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_199;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new SampleEventImageResult(_args);
        }
    }
    export interface IGenerateEventFromMadLibsResultArgs {
        success?: ElkTheme.TElkGenerateEventFromMadLibsResponse;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GenerateEventFromMadLibsResult {
        public success?: ElkTheme.TElkGenerateEventFromMadLibsResponse;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGenerateEventFromMadLibsResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GenerateEventFromMadLibsResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 1);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GenerateEventFromMadLibsResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_200: ElkTheme.TElkGenerateEventFromMadLibsResponse = ElkTheme.TElkGenerateEventFromMadLibsResponse.read(input);
                            _args.success = value_200;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_201: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_201;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GenerateEventFromMadLibsResult(_args);
        }
    }
    export interface IQuickCreateEventResultArgs {
        success?: ElkTheme.TElkGenerateEventFromMadLibsResponse;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
        authFailedException?: GenericExceptions.TAuthFailed;
    }
    export class QuickCreateEventResult {
        public success?: ElkTheme.TElkGenerateEventFromMadLibsResponse;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        public authFailedException?: GenericExceptions.TAuthFailed;
        constructor(args?: IQuickCreateEventResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
            if (args != null && args.authFailedException != null) {
                this.authFailedException = args.authFailedException;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("QuickCreateEventResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 1);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedException != null) {
                output.writeFieldBegin("authFailedException", thrift.Thrift.Type.STRUCT, 2);
                this.authFailedException.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): QuickCreateEventResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_202: ElkTheme.TElkGenerateEventFromMadLibsResponse = ElkTheme.TElkGenerateEventFromMadLibsResponse.read(input);
                            _args.success = value_202;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_203: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_203;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_204: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedException = value_204;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new QuickCreateEventResult(_args);
        }
    }
    export class Client {
        public _seqid: number;
        public _reqs: {
            [name: number]: (err: Error | object | undefined, val?: any) => void;
        };
        public output: thrift.TTransport;
        public protocol: new (trans: thrift.TTransport) => thrift.TProtocol;
        constructor(output: thrift.TTransport, protocol: new (trans: thrift.TTransport) => thrift.TProtocol) {
            this._seqid = 0;
            this._reqs = {};
            this.output = output;
            this.protocol = protocol;
        }
        public incrementSeqId(): number {
            return this._seqid += 1;
        }
        public getEventThemeSuggestion(header: PHeader.TUnauthHeader, request: ElkTheme.TElkGetThemeSuggestionRequest): Promise<ElkTheme.TElkGetThemeSuggestionResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkTheme.TElkGetThemeSuggestionResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getEventThemeSuggestion(header, request, requestId);
            });
        }
        public generateEventImage(header: PHeader.TAuthHeader, request: ElkTheme.TElkGenerateImageRequest): Promise<ElkTheme.TElkGenerateImageResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkTheme.TElkGenerateImageResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_generateEventImage(header, request, requestId);
            });
        }
        public sampleEventImage(header: PHeader.TUnauthHeader, request: ElkTheme.TElkGenerateSampleImageRequest): Promise<ElkTheme.TElkGenerateSampleImageResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkTheme.TElkGenerateSampleImageResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_sampleEventImage(header, request, requestId);
            });
        }
        public generateEventFromMadLibs(header: PHeader.TUnauthHeader, request: ElkTheme.TElkGenerateEventFromMadLibsRequest): Promise<ElkTheme.TElkGenerateEventFromMadLibsResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkTheme.TElkGenerateEventFromMadLibsResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_generateEventFromMadLibs(header, request, requestId);
            });
        }
        public quickCreateEvent(header: PHeader.TAuthHeader, request: ElkTheme.TElkGenerateEventFromMadLibsRequest): Promise<ElkTheme.TElkGenerateEventFromMadLibsResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ElkTheme.TElkGenerateEventFromMadLibsResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_quickCreateEvent(header, request, requestId);
            });
        }
        public send_getEventThemeSuggestion(header: PHeader.TUnauthHeader, request: ElkTheme.TElkGetThemeSuggestionRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getEventThemeSuggestion", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetEventThemeSuggestionArgs = new GetEventThemeSuggestionArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_generateEventImage(header: PHeader.TAuthHeader, request: ElkTheme.TElkGenerateImageRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("generateEventImage", thrift.Thrift.MessageType.CALL, requestId);
            const args: GenerateEventImageArgs = new GenerateEventImageArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_sampleEventImage(header: PHeader.TUnauthHeader, request: ElkTheme.TElkGenerateSampleImageRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("sampleEventImage", thrift.Thrift.MessageType.CALL, requestId);
            const args: SampleEventImageArgs = new SampleEventImageArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_generateEventFromMadLibs(header: PHeader.TUnauthHeader, request: ElkTheme.TElkGenerateEventFromMadLibsRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("generateEventFromMadLibs", thrift.Thrift.MessageType.CALL, requestId);
            const args: GenerateEventFromMadLibsArgs = new GenerateEventFromMadLibsArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_quickCreateEvent(header: PHeader.TAuthHeader, request: ElkTheme.TElkGenerateEventFromMadLibsRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("quickCreateEvent", thrift.Thrift.MessageType.CALL, requestId);
            const args: QuickCreateEventArgs = new QuickCreateEventArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public recv_getEventThemeSuggestion(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetEventThemeSuggestionResult = GetEventThemeSuggestionResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getEventThemeSuggestion failed: unknown result"));
                    }
                }
            }
        }
        public recv_generateEventImage(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GenerateEventImageResult = GenerateEventImageResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "generateEventImage failed: unknown result"));
                    }
                }
            }
        }
        public recv_sampleEventImage(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: SampleEventImageResult = SampleEventImageResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "sampleEventImage failed: unknown result"));
                    }
                }
            }
        }
        public recv_generateEventFromMadLibs(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GenerateEventFromMadLibsResult = GenerateEventFromMadLibsResult.read(input);
                input.readMessageEnd();
                if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "generateEventFromMadLibs failed: unknown result"));
                    }
                }
            }
        }
        public recv_quickCreateEvent(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: QuickCreateEventResult = QuickCreateEventResult.read(input);
                input.readMessageEnd();
                if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else if (result.authFailedException != null) {
                    return callback(result.authFailedException);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "quickCreateEvent failed: unknown result"));
                    }
                }
            }
        }
    }
    export interface IHandler {
        getEventThemeSuggestion(header: PHeader.TUnauthHeader, request: ElkTheme.TElkGetThemeSuggestionRequest): ElkTheme.TElkGetThemeSuggestionResponse | Promise<ElkTheme.TElkGetThemeSuggestionResponse>;
        generateEventImage(header: PHeader.TAuthHeader, request: ElkTheme.TElkGenerateImageRequest): ElkTheme.TElkGenerateImageResponse | Promise<ElkTheme.TElkGenerateImageResponse>;
        sampleEventImage(header: PHeader.TUnauthHeader, request: ElkTheme.TElkGenerateSampleImageRequest): ElkTheme.TElkGenerateSampleImageResponse | Promise<ElkTheme.TElkGenerateSampleImageResponse>;
        generateEventFromMadLibs(header: PHeader.TUnauthHeader, request: ElkTheme.TElkGenerateEventFromMadLibsRequest): ElkTheme.TElkGenerateEventFromMadLibsResponse | Promise<ElkTheme.TElkGenerateEventFromMadLibsResponse>;
        quickCreateEvent(header: PHeader.TAuthHeader, request: ElkTheme.TElkGenerateEventFromMadLibsRequest): ElkTheme.TElkGenerateEventFromMadLibsResponse | Promise<ElkTheme.TElkGenerateEventFromMadLibsResponse>;
    }
    export class Processor {
        public _handler: IHandler;
        constructor(handler: IHandler) {
            this._handler = handler;
        }
        public process(input: thrift.TProtocol, output: thrift.TProtocol): void {
            const metadata: thrift.TMessage = input.readMessageBegin();
            const fname: string = metadata.fname;
            const requestId: number = metadata.rseqid;
            const methodName: string = "process_" + fname;
            switch (methodName) {
                case "process_getEventThemeSuggestion": {
                    this.process_getEventThemeSuggestion(requestId, input, output);
                    return;
                }
                case "process_generateEventImage": {
                    this.process_generateEventImage(requestId, input, output);
                    return;
                }
                case "process_sampleEventImage": {
                    this.process_sampleEventImage(requestId, input, output);
                    return;
                }
                case "process_generateEventFromMadLibs": {
                    this.process_generateEventFromMadLibs(requestId, input, output);
                    return;
                }
                case "process_quickCreateEvent": {
                    this.process_quickCreateEvent(requestId, input, output);
                    return;
                }
                default: {
                    input.skip(thrift.Thrift.Type.STRUCT);
                    input.readMessageEnd();
                    const errMessage = "Unknown function " + fname;
                    const err = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN_METHOD, errMessage);
                    output.writeMessageBegin(fname, thrift.Thrift.MessageType.EXCEPTION, requestId);
                    err.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            }
        }
        public process_getEventThemeSuggestion(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkTheme.TElkGetThemeSuggestionResponse>((resolve, reject): void => {
                try {
                    const args: GetEventThemeSuggestionArgs = GetEventThemeSuggestionArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getEventThemeSuggestion(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkTheme.TElkGetThemeSuggestionResponse): void => {
                const result: GetEventThemeSuggestionResult = new GetEventThemeSuggestionResult({ success: data });
                output.writeMessageBegin("getEventThemeSuggestion", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetEventThemeSuggestionResult = new GetEventThemeSuggestionResult({ authFailedError: err });
                    output.writeMessageBegin("getEventThemeSuggestion", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetEventThemeSuggestionResult = new GetEventThemeSuggestionResult({ invalidRequestError: err });
                    output.writeMessageBegin("getEventThemeSuggestion", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getEventThemeSuggestion", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_generateEventImage(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkTheme.TElkGenerateImageResponse>((resolve, reject): void => {
                try {
                    const args: GenerateEventImageArgs = GenerateEventImageArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.generateEventImage(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkTheme.TElkGenerateImageResponse): void => {
                const result: GenerateEventImageResult = new GenerateEventImageResult({ success: data });
                output.writeMessageBegin("generateEventImage", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GenerateEventImageResult = new GenerateEventImageResult({ authFailedError: err });
                    output.writeMessageBegin("generateEventImage", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GenerateEventImageResult = new GenerateEventImageResult({ invalidRequestError: err });
                    output.writeMessageBegin("generateEventImage", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("generateEventImage", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_sampleEventImage(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkTheme.TElkGenerateSampleImageResponse>((resolve, reject): void => {
                try {
                    const args: SampleEventImageArgs = SampleEventImageArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.sampleEventImage(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkTheme.TElkGenerateSampleImageResponse): void => {
                const result: SampleEventImageResult = new SampleEventImageResult({ success: data });
                output.writeMessageBegin("sampleEventImage", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: SampleEventImageResult = new SampleEventImageResult({ authFailedError: err });
                    output.writeMessageBegin("sampleEventImage", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: SampleEventImageResult = new SampleEventImageResult({ invalidRequestError: err });
                    output.writeMessageBegin("sampleEventImage", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("sampleEventImage", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_generateEventFromMadLibs(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkTheme.TElkGenerateEventFromMadLibsResponse>((resolve, reject): void => {
                try {
                    const args: GenerateEventFromMadLibsArgs = GenerateEventFromMadLibsArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.generateEventFromMadLibs(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkTheme.TElkGenerateEventFromMadLibsResponse): void => {
                const result: GenerateEventFromMadLibsResult = new GenerateEventFromMadLibsResult({ success: data });
                output.writeMessageBegin("generateEventFromMadLibs", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GenerateEventFromMadLibsResult = new GenerateEventFromMadLibsResult({ invalidRequestError: err });
                    output.writeMessageBegin("generateEventFromMadLibs", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("generateEventFromMadLibs", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_quickCreateEvent(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ElkTheme.TElkGenerateEventFromMadLibsResponse>((resolve, reject): void => {
                try {
                    const args: QuickCreateEventArgs = QuickCreateEventArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.quickCreateEvent(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ElkTheme.TElkGenerateEventFromMadLibsResponse): void => {
                const result: QuickCreateEventResult = new QuickCreateEventResult({ success: data });
                output.writeMessageBegin("quickCreateEvent", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: QuickCreateEventResult = new QuickCreateEventResult({ invalidRequestError: err });
                    output.writeMessageBegin("quickCreateEvent", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: QuickCreateEventResult = new QuickCreateEventResult({ authFailedException: err });
                    output.writeMessageBegin("quickCreateEvent", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("quickCreateEvent", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
    }
}
