/* tslint:disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v2.0.14
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/

import * as thrift from "thrift";
import { Int64 } from "thrift";
export interface ITFetchCoinBalanceRequestArgs {
}
export class TFetchCoinBalanceRequest {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TFetchCoinBalanceRequest");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TFetchCoinBalanceRequest {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TFetchCoinBalanceRequest();
    }
}
export interface ITFetchCoinBalanceResponseArgs {
    balance: number | Int64;
}
export class TFetchCoinBalanceResponse {
    public balance: Int64;
    constructor(args: ITFetchCoinBalanceResponseArgs) {
        if (args != null && args.balance != null) {
            if (typeof args.balance === "number") {
                this.balance = new Int64(args.balance);
            }
            else {
                this.balance = args.balance;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[balance] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TFetchCoinBalanceResponse");
        if (this.balance != null) {
            output.writeFieldBegin("balance", thrift.Thrift.Type.I64, 1);
            output.writeI64(this.balance);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TFetchCoinBalanceResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_1: Int64 = input.readI64();
                        _args.balance = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.balance !== undefined) {
            return new TFetchCoinBalanceResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TFetchCoinBalanceResponse from input");
        }
    }
}
export interface ITVerifyCoinPurchaseRequestArgs {
    taskId: string;
    data: TAppVerifyCoinPurchaseData;
}
export class TVerifyCoinPurchaseRequest {
    public taskId: string;
    public data: TAppVerifyCoinPurchaseData;
    constructor(args: ITVerifyCoinPurchaseRequestArgs) {
        if (args != null && args.taskId != null) {
            this.taskId = args.taskId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[taskId] is unset!");
        }
        if (args != null && args.data != null) {
            this.data = args.data;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[data] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TVerifyCoinPurchaseRequest");
        if (this.taskId != null) {
            output.writeFieldBegin("taskId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.taskId);
            output.writeFieldEnd();
        }
        if (this.data != null) {
            output.writeFieldBegin("data", thrift.Thrift.Type.STRUCT, 2);
            this.data.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TVerifyCoinPurchaseRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_2: string = input.readString();
                        _args.taskId = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_3: TAppVerifyCoinPurchaseData = TAppVerifyCoinPurchaseData.read(input);
                        _args.data = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.taskId !== undefined && _args.data !== undefined) {
            return new TVerifyCoinPurchaseRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TVerifyCoinPurchaseRequest from input");
        }
    }
}
export interface ITVerifyCoinPurchaseResponseArgs {
    balance: number | Int64;
}
export class TVerifyCoinPurchaseResponse {
    public balance: Int64;
    constructor(args: ITVerifyCoinPurchaseResponseArgs) {
        if (args != null && args.balance != null) {
            if (typeof args.balance === "number") {
                this.balance = new Int64(args.balance);
            }
            else {
                this.balance = args.balance;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[balance] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TVerifyCoinPurchaseResponse");
        if (this.balance != null) {
            output.writeFieldBegin("balance", thrift.Thrift.Type.I64, 1);
            output.writeI64(this.balance);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TVerifyCoinPurchaseResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_4: Int64 = input.readI64();
                        _args.balance = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.balance !== undefined) {
            return new TVerifyCoinPurchaseResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TVerifyCoinPurchaseResponse from input");
        }
    }
}
export interface ITAppAppleCoinTransactionDataArgs {
    transactionId: number | Int64;
    productId: string;
    environment: string;
}
export class TAppAppleCoinTransactionData {
    public transactionId: Int64;
    public productId: string;
    public environment: string;
    constructor(args: ITAppAppleCoinTransactionDataArgs) {
        if (args != null && args.transactionId != null) {
            if (typeof args.transactionId === "number") {
                this.transactionId = new Int64(args.transactionId);
            }
            else {
                this.transactionId = args.transactionId;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[transactionId] is unset!");
        }
        if (args != null && args.productId != null) {
            this.productId = args.productId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[productId] is unset!");
        }
        if (args != null && args.environment != null) {
            this.environment = args.environment;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[environment] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppAppleCoinTransactionData");
        if (this.transactionId != null) {
            output.writeFieldBegin("transactionId", thrift.Thrift.Type.I64, 1);
            output.writeI64(this.transactionId);
            output.writeFieldEnd();
        }
        if (this.productId != null) {
            output.writeFieldBegin("productId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.productId);
            output.writeFieldEnd();
        }
        if (this.environment != null) {
            output.writeFieldBegin("environment", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.environment);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppAppleCoinTransactionData {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_5: Int64 = input.readI64();
                        _args.transactionId = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_6: string = input.readString();
                        _args.productId = value_6;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_7: string = input.readString();
                        _args.environment = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.transactionId !== undefined && _args.productId !== undefined && _args.environment !== undefined) {
            return new TAppAppleCoinTransactionData(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppAppleCoinTransactionData from input");
        }
    }
}
export interface ITAppAndroidCoinTransactionDataArgs {
    transactionId: string;
    packageName: string;
    productId: string;
    token: string;
}
export class TAppAndroidCoinTransactionData {
    public transactionId: string;
    public packageName: string;
    public productId: string;
    public token: string;
    constructor(args: ITAppAndroidCoinTransactionDataArgs) {
        if (args != null && args.transactionId != null) {
            this.transactionId = args.transactionId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[transactionId] is unset!");
        }
        if (args != null && args.packageName != null) {
            this.packageName = args.packageName;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[packageName] is unset!");
        }
        if (args != null && args.productId != null) {
            this.productId = args.productId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[productId] is unset!");
        }
        if (args != null && args.token != null) {
            this.token = args.token;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[token] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppAndroidCoinTransactionData");
        if (this.transactionId != null) {
            output.writeFieldBegin("transactionId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.transactionId);
            output.writeFieldEnd();
        }
        if (this.packageName != null) {
            output.writeFieldBegin("packageName", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.packageName);
            output.writeFieldEnd();
        }
        if (this.productId != null) {
            output.writeFieldBegin("productId", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.productId);
            output.writeFieldEnd();
        }
        if (this.token != null) {
            output.writeFieldBegin("token", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.token);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppAndroidCoinTransactionData {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_8: string = input.readString();
                        _args.transactionId = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_9: string = input.readString();
                        _args.packageName = value_9;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_10: string = input.readString();
                        _args.productId = value_10;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_11: string = input.readString();
                        _args.token = value_11;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.transactionId !== undefined && _args.packageName !== undefined && _args.productId !== undefined && _args.token !== undefined) {
            return new TAppAndroidCoinTransactionData(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppAndroidCoinTransactionData from input");
        }
    }
}
export interface ITAppWebCoinTransactionDataArgs {
    paymentIntentId: string;
    transactionId?: string;
    productId: string;
}
export class TAppWebCoinTransactionData {
    public paymentIntentId: string;
    public transactionId?: string;
    public productId: string;
    constructor(args: ITAppWebCoinTransactionDataArgs) {
        if (args != null && args.paymentIntentId != null) {
            this.paymentIntentId = args.paymentIntentId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[paymentIntentId] is unset!");
        }
        if (args != null && args.transactionId != null) {
            this.transactionId = args.transactionId;
        }
        if (args != null && args.productId != null) {
            this.productId = args.productId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[productId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppWebCoinTransactionData");
        if (this.paymentIntentId != null) {
            output.writeFieldBegin("paymentIntentId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.paymentIntentId);
            output.writeFieldEnd();
        }
        if (this.transactionId != null) {
            output.writeFieldBegin("transactionId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.transactionId);
            output.writeFieldEnd();
        }
        if (this.productId != null) {
            output.writeFieldBegin("productId", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.productId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppWebCoinTransactionData {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_12: string = input.readString();
                        _args.paymentIntentId = value_12;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_13: string = input.readString();
                        _args.transactionId = value_13;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_14: string = input.readString();
                        _args.productId = value_14;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.paymentIntentId !== undefined && _args.productId !== undefined) {
            return new TAppWebCoinTransactionData(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppWebCoinTransactionData from input");
        }
    }
}
export interface ITAppVerifyCoinPurchaseDataArgs {
    appleData?: TAppAppleCoinTransactionData;
    androidData?: TAppAndroidCoinTransactionData;
    webData?: TAppWebCoinTransactionData;
}
export class TAppVerifyCoinPurchaseData {
    public appleData?: TAppAppleCoinTransactionData;
    public androidData?: TAppAndroidCoinTransactionData;
    public webData?: TAppWebCoinTransactionData;
    constructor(args?: ITAppVerifyCoinPurchaseDataArgs) {
        let _fieldsSet: number = 0;
        if (args != null) {
            if (args.appleData != null) {
                _fieldsSet++;
                this.appleData = args.appleData;
            }
            if (args.androidData != null) {
                _fieldsSet++;
                this.androidData = args.androidData;
            }
            if (args.webData != null) {
                _fieldsSet++;
                this.webData = args.webData;
            }
            if (_fieldsSet > 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
            }
            else if (_fieldsSet < 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
            }
        }
    }
    public static fromAppleData(appleData: TAppAppleCoinTransactionData): TAppVerifyCoinPurchaseData {
        return new TAppVerifyCoinPurchaseData({ appleData });
    }
    public static fromAndroidData(androidData: TAppAndroidCoinTransactionData): TAppVerifyCoinPurchaseData {
        return new TAppVerifyCoinPurchaseData({ androidData });
    }
    public static fromWebData(webData: TAppWebCoinTransactionData): TAppVerifyCoinPurchaseData {
        return new TAppVerifyCoinPurchaseData({ webData });
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppVerifyCoinPurchaseData");
        if (this.appleData != null) {
            output.writeFieldBegin("appleData", thrift.Thrift.Type.STRUCT, 1);
            this.appleData.write(output);
            output.writeFieldEnd();
        }
        if (this.androidData != null) {
            output.writeFieldBegin("androidData", thrift.Thrift.Type.STRUCT, 2);
            this.androidData.write(output);
            output.writeFieldEnd();
        }
        if (this.webData != null) {
            output.writeFieldBegin("webData", thrift.Thrift.Type.STRUCT, 3);
            this.webData.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppVerifyCoinPurchaseData {
        let _fieldsSet: number = 0;
        let _returnValue: TAppVerifyCoinPurchaseData | null = null;
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        _fieldsSet++;
                        const value_15: TAppAppleCoinTransactionData = TAppAppleCoinTransactionData.read(input);
                        _returnValue = TAppVerifyCoinPurchaseData.fromAppleData(value_15);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        _fieldsSet++;
                        const value_16: TAppAndroidCoinTransactionData = TAppAndroidCoinTransactionData.read(input);
                        _returnValue = TAppVerifyCoinPurchaseData.fromAndroidData(value_16);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        _fieldsSet++;
                        const value_17: TAppWebCoinTransactionData = TAppWebCoinTransactionData.read(input);
                        _returnValue = TAppVerifyCoinPurchaseData.fromWebData(value_17);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_fieldsSet > 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
        }
        else if (_fieldsSet < 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
        }
        if (_returnValue !== null) {
            return _returnValue;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read data for TUnion");
        }
    }
}
