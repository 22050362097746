/* tslint:disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v2.0.14
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as GenericExceptions from "./../../thrift/protocol/common/exceptions";
import * as PHeader from "./../../thrift/protocol/common/models";
import * as OAuth from "./../../thrift/protocol/common/models";
import * as Coin from "./../../thrift/protocol/coin/messages";
export namespace TCoinService {
    export interface IFetchCoinBalanceArgsArgs {
        header: PHeader.TAuthHeader;
        request: Coin.TFetchCoinBalanceRequest;
    }
    export class FetchCoinBalanceArgs {
        public header: PHeader.TAuthHeader;
        public request: Coin.TFetchCoinBalanceRequest;
        constructor(args: IFetchCoinBalanceArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchCoinBalanceArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchCoinBalanceArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_1: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_1;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_2: Coin.TFetchCoinBalanceRequest = Coin.TFetchCoinBalanceRequest.read(input);
                            _args.request = value_2;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new FetchCoinBalanceArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read FetchCoinBalanceArgs from input");
            }
        }
    }
    export interface IVerifyCoinPurchaseArgsArgs {
        header: PHeader.TAuthHeader;
        request: Coin.TVerifyCoinPurchaseRequest;
    }
    export class VerifyCoinPurchaseArgs {
        public header: PHeader.TAuthHeader;
        public request: Coin.TVerifyCoinPurchaseRequest;
        constructor(args: IVerifyCoinPurchaseArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("VerifyCoinPurchaseArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): VerifyCoinPurchaseArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_3: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_3;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_4: Coin.TVerifyCoinPurchaseRequest = Coin.TVerifyCoinPurchaseRequest.read(input);
                            _args.request = value_4;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new VerifyCoinPurchaseArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read VerifyCoinPurchaseArgs from input");
            }
        }
    }
    export interface IFetchCoinBalanceResultArgs {
        success?: Coin.TFetchCoinBalanceResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class FetchCoinBalanceResult {
        public success?: Coin.TFetchCoinBalanceResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IFetchCoinBalanceResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchCoinBalanceResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchCoinBalanceResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_5: Coin.TFetchCoinBalanceResponse = Coin.TFetchCoinBalanceResponse.read(input);
                            _args.success = value_5;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_6: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_6;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_7: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_7;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new FetchCoinBalanceResult(_args);
        }
    }
    export interface IVerifyCoinPurchaseResultArgs {
        success?: Coin.TVerifyCoinPurchaseResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class VerifyCoinPurchaseResult {
        public success?: Coin.TVerifyCoinPurchaseResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IVerifyCoinPurchaseResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("VerifyCoinPurchaseResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): VerifyCoinPurchaseResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_8: Coin.TVerifyCoinPurchaseResponse = Coin.TVerifyCoinPurchaseResponse.read(input);
                            _args.success = value_8;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_9: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_9;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_10: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_10;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new VerifyCoinPurchaseResult(_args);
        }
    }
    export class Client {
        public _seqid: number;
        public _reqs: {
            [name: number]: (err: Error | object | undefined, val?: any) => void;
        };
        public output: thrift.TTransport;
        public protocol: new (trans: thrift.TTransport) => thrift.TProtocol;
        constructor(output: thrift.TTransport, protocol: new (trans: thrift.TTransport) => thrift.TProtocol) {
            this._seqid = 0;
            this._reqs = {};
            this.output = output;
            this.protocol = protocol;
        }
        public incrementSeqId(): number {
            return this._seqid += 1;
        }
        public fetchCoinBalance(header: PHeader.TAuthHeader, request: Coin.TFetchCoinBalanceRequest): Promise<Coin.TFetchCoinBalanceResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Coin.TFetchCoinBalanceResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_fetchCoinBalance(header, request, requestId);
            });
        }
        public verifyCoinPurchase(header: PHeader.TAuthHeader, request: Coin.TVerifyCoinPurchaseRequest): Promise<Coin.TVerifyCoinPurchaseResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Coin.TVerifyCoinPurchaseResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_verifyCoinPurchase(header, request, requestId);
            });
        }
        public send_fetchCoinBalance(header: PHeader.TAuthHeader, request: Coin.TFetchCoinBalanceRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("fetchCoinBalance", thrift.Thrift.MessageType.CALL, requestId);
            const args: FetchCoinBalanceArgs = new FetchCoinBalanceArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_verifyCoinPurchase(header: PHeader.TAuthHeader, request: Coin.TVerifyCoinPurchaseRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("verifyCoinPurchase", thrift.Thrift.MessageType.CALL, requestId);
            const args: VerifyCoinPurchaseArgs = new VerifyCoinPurchaseArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public recv_fetchCoinBalance(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: FetchCoinBalanceResult = FetchCoinBalanceResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "fetchCoinBalance failed: unknown result"));
                    }
                }
            }
        }
        public recv_verifyCoinPurchase(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: VerifyCoinPurchaseResult = VerifyCoinPurchaseResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "verifyCoinPurchase failed: unknown result"));
                    }
                }
            }
        }
    }
    export interface IHandler {
        fetchCoinBalance(header: PHeader.TAuthHeader, request: Coin.TFetchCoinBalanceRequest): Coin.TFetchCoinBalanceResponse | Promise<Coin.TFetchCoinBalanceResponse>;
        verifyCoinPurchase(header: PHeader.TAuthHeader, request: Coin.TVerifyCoinPurchaseRequest): Coin.TVerifyCoinPurchaseResponse | Promise<Coin.TVerifyCoinPurchaseResponse>;
    }
    export class Processor {
        public _handler: IHandler;
        constructor(handler: IHandler) {
            this._handler = handler;
        }
        public process(input: thrift.TProtocol, output: thrift.TProtocol): void {
            const metadata: thrift.TMessage = input.readMessageBegin();
            const fname: string = metadata.fname;
            const requestId: number = metadata.rseqid;
            const methodName: string = "process_" + fname;
            switch (methodName) {
                case "process_fetchCoinBalance": {
                    this.process_fetchCoinBalance(requestId, input, output);
                    return;
                }
                case "process_verifyCoinPurchase": {
                    this.process_verifyCoinPurchase(requestId, input, output);
                    return;
                }
                default: {
                    input.skip(thrift.Thrift.Type.STRUCT);
                    input.readMessageEnd();
                    const errMessage = "Unknown function " + fname;
                    const err = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN_METHOD, errMessage);
                    output.writeMessageBegin(fname, thrift.Thrift.MessageType.EXCEPTION, requestId);
                    err.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            }
        }
        public process_fetchCoinBalance(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Coin.TFetchCoinBalanceResponse>((resolve, reject): void => {
                try {
                    const args: FetchCoinBalanceArgs = FetchCoinBalanceArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.fetchCoinBalance(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Coin.TFetchCoinBalanceResponse): void => {
                const result: FetchCoinBalanceResult = new FetchCoinBalanceResult({ success: data });
                output.writeMessageBegin("fetchCoinBalance", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: FetchCoinBalanceResult = new FetchCoinBalanceResult({ authFailedError: err });
                    output.writeMessageBegin("fetchCoinBalance", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: FetchCoinBalanceResult = new FetchCoinBalanceResult({ invalidRequestError: err });
                    output.writeMessageBegin("fetchCoinBalance", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("fetchCoinBalance", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_verifyCoinPurchase(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Coin.TVerifyCoinPurchaseResponse>((resolve, reject): void => {
                try {
                    const args: VerifyCoinPurchaseArgs = VerifyCoinPurchaseArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.verifyCoinPurchase(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Coin.TVerifyCoinPurchaseResponse): void => {
                const result: VerifyCoinPurchaseResult = new VerifyCoinPurchaseResult({ success: data });
                output.writeMessageBegin("verifyCoinPurchase", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: VerifyCoinPurchaseResult = new VerifyCoinPurchaseResult({ authFailedError: err });
                    output.writeMessageBegin("verifyCoinPurchase", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: VerifyCoinPurchaseResult = new VerifyCoinPurchaseResult({ invalidRequestError: err });
                    output.writeMessageBegin("verifyCoinPurchase", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("verifyCoinPurchase", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
    }
}
