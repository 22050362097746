/* tslint:disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v2.0.14
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
export enum TExceptionType {
    UNDEFINED = 0,
    TAuthFailed = 1,
    TInvalidRequest = 2,
    TInternalServerError = 3,
    TRequestExpired = 4,
    TSuspiciousDevice = 5,
    TBlacklistedDevice = 6,
    TServerUnderMaintenance = 7,
    TInviteException = 8,
    TBackupUploadException = 9,
    TBackupUploadCompletedException = 10,
    TBackupDownloadDoesNotExistException = 11,
    TStripePaymentException = 12,
    TInvalidCredentials = 13,
    TGenericServerMessageException = 14,
    TInvalidPasscode = 15,
    TInsufficientFunds = 16
}
export enum TInvalidRequestReasonType {
    UNDEFINED = 0,
    UserOptedOut = 1
}
export enum TAuthFailedAction {
    UNDEFINED = 0,
    SHOULD_REFRESH_ACCESS_TOKEN = 1,
    CAPTCHA_CHALLENGE_REQUIRED = 2,
    FORCE_LOGOUT = 3
}
export interface ITAuthFailedArgs {
    exceptionType: TExceptionType;
    reason?: string;
    action?: TAuthFailedAction;
    shouldRefreshAccessToken?: boolean;
    captchaChallengeRequired?: boolean;
}
export class TAuthFailed {
    public exceptionType: TExceptionType = TExceptionType.TAuthFailed;
    public reason?: string;
    public action?: TAuthFailedAction;
    public shouldRefreshAccessToken?: boolean;
    public captchaChallengeRequired?: boolean;
    constructor(args: ITAuthFailedArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
        if (args != null && args.reason != null) {
            this.reason = args.reason;
        }
        if (args != null && args.action != null) {
            this.action = args.action;
        }
        if (args != null && args.shouldRefreshAccessToken != null) {
            this.shouldRefreshAccessToken = args.shouldRefreshAccessToken;
        }
        if (args != null && args.captchaChallengeRequired != null) {
            this.captchaChallengeRequired = args.captchaChallengeRequired;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAuthFailed");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        if (this.reason != null) {
            output.writeFieldBegin("reason", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.reason);
            output.writeFieldEnd();
        }
        if (this.action != null) {
            output.writeFieldBegin("action", thrift.Thrift.Type.I32, 5);
            output.writeI32(this.action);
            output.writeFieldEnd();
        }
        if (this.shouldRefreshAccessToken != null) {
            output.writeFieldBegin("shouldRefreshAccessToken", thrift.Thrift.Type.BOOL, 3);
            output.writeBool(this.shouldRefreshAccessToken);
            output.writeFieldEnd();
        }
        if (this.captchaChallengeRequired != null) {
            output.writeFieldBegin("captchaChallengeRequired", thrift.Thrift.Type.BOOL, 4);
            output.writeBool(this.captchaChallengeRequired);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAuthFailed {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_1: TExceptionType = input.readI32();
                        _args.exceptionType = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_2: string = input.readString();
                        _args.reason = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_3: TAuthFailedAction = input.readI32();
                        _args.action = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_4: boolean = input.readBool();
                        _args.shouldRefreshAccessToken = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_5: boolean = input.readBool();
                        _args.captchaChallengeRequired = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TAuthFailed(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAuthFailed from input");
        }
    }
}
export interface ITInvalidRequestArgs {
    exceptionType: TExceptionType;
    reason?: string;
    reasonType?: TInvalidRequestReasonType;
}
export class TInvalidRequest {
    public exceptionType: TExceptionType = TExceptionType.TInvalidRequest;
    public reason?: string;
    public reasonType?: TInvalidRequestReasonType;
    constructor(args: ITInvalidRequestArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
        if (args != null && args.reason != null) {
            this.reason = args.reason;
        }
        if (args != null && args.reasonType != null) {
            this.reasonType = args.reasonType;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TInvalidRequest");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        if (this.reason != null) {
            output.writeFieldBegin("reason", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.reason);
            output.writeFieldEnd();
        }
        if (this.reasonType != null) {
            output.writeFieldBegin("reasonType", thrift.Thrift.Type.I32, 3);
            output.writeI32(this.reasonType);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TInvalidRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_6: TExceptionType = input.readI32();
                        _args.exceptionType = value_6;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_7: string = input.readString();
                        _args.reason = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_8: TInvalidRequestReasonType = input.readI32();
                        _args.reasonType = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TInvalidRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TInvalidRequest from input");
        }
    }
}
export interface ITInternalServerErrorArgs {
    exceptionType: TExceptionType;
    reason?: string;
}
export class TInternalServerError {
    public exceptionType: TExceptionType = TExceptionType.TInternalServerError;
    public reason?: string;
    constructor(args: ITInternalServerErrorArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
        if (args != null && args.reason != null) {
            this.reason = args.reason;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TInternalServerError");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        if (this.reason != null) {
            output.writeFieldBegin("reason", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.reason);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TInternalServerError {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_9: TExceptionType = input.readI32();
                        _args.exceptionType = value_9;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_10: string = input.readString();
                        _args.reason = value_10;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TInternalServerError(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TInternalServerError from input");
        }
    }
}
export interface ITRequestExpiredArgs {
    exceptionType: TExceptionType;
}
export class TRequestExpired {
    public exceptionType: TExceptionType = TExceptionType.TRequestExpired;
    constructor(args: ITRequestExpiredArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TRequestExpired");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TRequestExpired {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_11: TExceptionType = input.readI32();
                        _args.exceptionType = value_11;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TRequestExpired(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TRequestExpired from input");
        }
    }
}
export interface ITSuspiciousDeviceArgs {
    exceptionType: TExceptionType;
    deviceId?: string;
}
export class TSuspiciousDevice {
    public exceptionType: TExceptionType = TExceptionType.TSuspiciousDevice;
    public deviceId?: string;
    constructor(args: ITSuspiciousDeviceArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
        if (args != null && args.deviceId != null) {
            this.deviceId = args.deviceId;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSuspiciousDevice");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        if (this.deviceId != null) {
            output.writeFieldBegin("deviceId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.deviceId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSuspiciousDevice {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_12: TExceptionType = input.readI32();
                        _args.exceptionType = value_12;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_13: string = input.readString();
                        _args.deviceId = value_13;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TSuspiciousDevice(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TSuspiciousDevice from input");
        }
    }
}
export interface ITBlacklistedDeviceArgs {
    exceptionType: TExceptionType;
    deviceId?: string;
}
export class TBlacklistedDevice {
    public exceptionType: TExceptionType = TExceptionType.TBlacklistedDevice;
    public deviceId?: string;
    constructor(args: ITBlacklistedDeviceArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
        if (args != null && args.deviceId != null) {
            this.deviceId = args.deviceId;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TBlacklistedDevice");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        if (this.deviceId != null) {
            output.writeFieldBegin("deviceId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.deviceId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TBlacklistedDevice {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_14: TExceptionType = input.readI32();
                        _args.exceptionType = value_14;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_15: string = input.readString();
                        _args.deviceId = value_15;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TBlacklistedDevice(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TBlacklistedDevice from input");
        }
    }
}
export interface ITServerUnderMaintenanceArgs {
    exceptionType: TExceptionType;
    reason?: string;
}
export class TServerUnderMaintenance {
    public exceptionType: TExceptionType = TExceptionType.TServerUnderMaintenance;
    public reason?: string;
    constructor(args: ITServerUnderMaintenanceArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
        if (args != null && args.reason != null) {
            this.reason = args.reason;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TServerUnderMaintenance");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        if (this.reason != null) {
            output.writeFieldBegin("reason", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.reason);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TServerUnderMaintenance {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_16: TExceptionType = input.readI32();
                        _args.exceptionType = value_16;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_17: string = input.readString();
                        _args.reason = value_17;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TServerUnderMaintenance(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TServerUnderMaintenance from input");
        }
    }
}
export interface ITInviteExceptionArgs {
    exceptionType: TExceptionType;
}
export class TInviteException {
    public exceptionType: TExceptionType = TExceptionType.TInviteException;
    constructor(args: ITInviteExceptionArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TInviteException");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TInviteException {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_18: TExceptionType = input.readI32();
                        _args.exceptionType = value_18;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TInviteException(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TInviteException from input");
        }
    }
}
export interface ITBackupUploadExceptionArgs {
    exceptionType: TExceptionType;
}
export class TBackupUploadException {
    public exceptionType: TExceptionType = TExceptionType.TBackupUploadException;
    constructor(args: ITBackupUploadExceptionArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TBackupUploadException");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TBackupUploadException {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_19: TExceptionType = input.readI32();
                        _args.exceptionType = value_19;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TBackupUploadException(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TBackupUploadException from input");
        }
    }
}
export interface ITBackupUploadCompletedExceptionArgs {
    exceptionType: TExceptionType;
}
export class TBackupUploadCompletedException {
    public exceptionType: TExceptionType = TExceptionType.TBackupUploadCompletedException;
    constructor(args: ITBackupUploadCompletedExceptionArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TBackupUploadCompletedException");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TBackupUploadCompletedException {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_20: TExceptionType = input.readI32();
                        _args.exceptionType = value_20;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TBackupUploadCompletedException(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TBackupUploadCompletedException from input");
        }
    }
}
export interface ITBackupDownloadDoesNotExistExceptionArgs {
    exceptionType: TExceptionType;
}
export class TBackupDownloadDoesNotExistException {
    public exceptionType: TExceptionType = TExceptionType.TBackupDownloadDoesNotExistException;
    constructor(args: ITBackupDownloadDoesNotExistExceptionArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TBackupDownloadDoesNotExistException");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TBackupDownloadDoesNotExistException {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_21: TExceptionType = input.readI32();
                        _args.exceptionType = value_21;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TBackupDownloadDoesNotExistException(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TBackupDownloadDoesNotExistException from input");
        }
    }
}
export interface ITStripePaymentExceptionArgs {
    exceptionType: TExceptionType;
    reason?: string;
}
export class TStripePaymentException {
    public exceptionType: TExceptionType = TExceptionType.TStripePaymentException;
    public reason?: string;
    constructor(args: ITStripePaymentExceptionArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
        if (args != null && args.reason != null) {
            this.reason = args.reason;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TStripePaymentException");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        if (this.reason != null) {
            output.writeFieldBegin("reason", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.reason);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TStripePaymentException {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_22: TExceptionType = input.readI32();
                        _args.exceptionType = value_22;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_23: string = input.readString();
                        _args.reason = value_23;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TStripePaymentException(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TStripePaymentException from input");
        }
    }
}
export interface ITInvalidCredentialsExceptionArgs {
    exceptionType: TExceptionType;
    reason?: string;
}
export class TInvalidCredentialsException {
    public exceptionType: TExceptionType = TExceptionType.TInvalidCredentials;
    public reason?: string;
    constructor(args: ITInvalidCredentialsExceptionArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
        if (args != null && args.reason != null) {
            this.reason = args.reason;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TInvalidCredentialsException");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        if (this.reason != null) {
            output.writeFieldBegin("reason", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.reason);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TInvalidCredentialsException {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_24: TExceptionType = input.readI32();
                        _args.exceptionType = value_24;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_25: string = input.readString();
                        _args.reason = value_25;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TInvalidCredentialsException(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TInvalidCredentialsException from input");
        }
    }
}
export interface ITGenericServerMessageExceptionArgs {
    exceptionType: TExceptionType;
    errorMessage: string;
}
export class TGenericServerMessageException {
    public exceptionType: TExceptionType = TExceptionType.TGenericServerMessageException;
    public errorMessage: string;
    constructor(args: ITGenericServerMessageExceptionArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
        if (args != null && args.errorMessage != null) {
            this.errorMessage = args.errorMessage;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[errorMessage] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TGenericServerMessageException");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        if (this.errorMessage != null) {
            output.writeFieldBegin("errorMessage", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.errorMessage);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TGenericServerMessageException {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_26: TExceptionType = input.readI32();
                        _args.exceptionType = value_26;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_27: string = input.readString();
                        _args.errorMessage = value_27;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined && _args.errorMessage !== undefined) {
            return new TGenericServerMessageException(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TGenericServerMessageException from input");
        }
    }
}
export interface ITInvalidPasscodeExceptionArgs {
    exceptionType: TExceptionType;
}
export class TInvalidPasscodeException {
    public exceptionType: TExceptionType = TExceptionType.TInvalidPasscode;
    constructor(args: ITInvalidPasscodeExceptionArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TInvalidPasscodeException");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TInvalidPasscodeException {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_28: TExceptionType = input.readI32();
                        _args.exceptionType = value_28;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TInvalidPasscodeException(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TInvalidPasscodeException from input");
        }
    }
}
export interface ITInsufficientFundsExceptionArgs {
    exceptionType: TExceptionType;
}
export class TInsufficientFundsException {
    public exceptionType: TExceptionType = TExceptionType.TInsufficientFunds;
    constructor(args: ITInsufficientFundsExceptionArgs) {
        if (args != null && args.exceptionType != null) {
            this.exceptionType = args.exceptionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[exceptionType] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TInsufficientFundsException");
        if (this.exceptionType != null) {
            output.writeFieldBegin("exceptionType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.exceptionType);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TInsufficientFundsException {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_29: TExceptionType = input.readI32();
                        _args.exceptionType = value_29;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.exceptionType !== undefined) {
            return new TInsufficientFundsException(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TInsufficientFundsException from input");
        }
    }
}
