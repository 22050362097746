/* tslint:disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v2.0.14
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as GenericExceptions from "./../common/exceptions";
import * as PHeader from "./../common/models";
import * as ConnectedAccount from "./../connectedaccount/messages";
import * as AccountDetails from "./messages";
import * as ContactProcessingStatus from "./messages";
import * as Distress from "./messages";
import * as EncryptionStorage from "./messages";
import * as GetAuthorizationUrl from "./messages";
import * as GoogleAuthTokens from "./messages";
import * as MicrosoftAuthTokens from "./messages";
import * as UpdateUserMetadata from "./messages";
import * as Quality from "./messages";
import * as OptOut from "./messages";
import * as TopContacts from "./messages";
import * as UserHealthStats from "./messages";
import * as InitialData from "./messages";
export namespace TUserService {
    export interface IUpdateUserSettingsArgsArgs {
        header: PHeader.TAuthHeader;
        request: UpdateUserMetadata.TUpdateUserSettingsRequest;
    }
    export class UpdateUserSettingsArgs {
        public header: PHeader.TAuthHeader;
        public request: UpdateUserMetadata.TUpdateUserSettingsRequest;
        constructor(args: IUpdateUserSettingsArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateUserSettingsArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateUserSettingsArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_1: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_1;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_2: UpdateUserMetadata.TUpdateUserSettingsRequest = UpdateUserMetadata.TUpdateUserSettingsRequest.read(input);
                            _args.request = value_2;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new UpdateUserSettingsArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpdateUserSettingsArgs from input");
            }
        }
    }
    export interface IFetchEmailScheduleArgsArgs {
        header: PHeader.TAuthHeader;
        request: UpdateUserMetadata.TFetchEmailScheduleRequest;
    }
    export class FetchEmailScheduleArgs {
        public header: PHeader.TAuthHeader;
        public request: UpdateUserMetadata.TFetchEmailScheduleRequest;
        constructor(args: IFetchEmailScheduleArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchEmailScheduleArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchEmailScheduleArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_3: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_3;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_4: UpdateUserMetadata.TFetchEmailScheduleRequest = UpdateUserMetadata.TFetchEmailScheduleRequest.read(input);
                            _args.request = value_4;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new FetchEmailScheduleArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read FetchEmailScheduleArgs from input");
            }
        }
    }
    export interface IUpdateCanonicalPhoneArgsArgs {
        header: PHeader.TAuthHeader;
        request: AccountDetails.TUpdateCanonicalPhoneRequest;
    }
    export class UpdateCanonicalPhoneArgs {
        public header: PHeader.TAuthHeader;
        public request: AccountDetails.TUpdateCanonicalPhoneRequest;
        constructor(args: IUpdateCanonicalPhoneArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateCanonicalPhoneArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateCanonicalPhoneArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_5: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_5;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_6: AccountDetails.TUpdateCanonicalPhoneRequest = AccountDetails.TUpdateCanonicalPhoneRequest.read(input);
                            _args.request = value_6;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new UpdateCanonicalPhoneArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpdateCanonicalPhoneArgs from input");
            }
        }
    }
    export interface ISendDistressArgsArgs {
        header: PHeader.TAuthHeader;
        request: Distress.TDistressRequest;
    }
    export class SendDistressArgs {
        public header: PHeader.TAuthHeader;
        public request: Distress.TDistressRequest;
        constructor(args: ISendDistressArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendDistressArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendDistressArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_7: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_7;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_8: Distress.TDistressRequest = Distress.TDistressRequest.read(input);
                            _args.request = value_8;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new SendDistressArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SendDistressArgs from input");
            }
        }
    }
    export interface IContactLooksGoodArgsArgs {
        header: PHeader.TAuthHeader;
        request: Quality.TContactLooksGoodRequest;
    }
    export class ContactLooksGoodArgs {
        public header: PHeader.TAuthHeader;
        public request: Quality.TContactLooksGoodRequest;
        constructor(args: IContactLooksGoodArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("ContactLooksGoodArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): ContactLooksGoodArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_9: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_9;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_10: Quality.TContactLooksGoodRequest = Quality.TContactLooksGoodRequest.read(input);
                            _args.request = value_10;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new ContactLooksGoodArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read ContactLooksGoodArgs from input");
            }
        }
    }
    export interface IGetAuthorizationUrlArgsArgs {
        header: PHeader.TAuthHeader;
        request: GetAuthorizationUrl.TGetAuthorizationUrlRequest;
    }
    export class GetAuthorizationUrlArgs {
        public header: PHeader.TAuthHeader;
        public request: GetAuthorizationUrl.TGetAuthorizationUrlRequest;
        constructor(args: IGetAuthorizationUrlArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetAuthorizationUrlArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetAuthorizationUrlArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_11: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_11;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_12: GetAuthorizationUrl.TGetAuthorizationUrlRequest = GetAuthorizationUrl.TGetAuthorizationUrlRequest.read(input);
                            _args.request = value_12;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetAuthorizationUrlArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetAuthorizationUrlArgs from input");
            }
        }
    }
    export interface IUpdateGoogleAuthTokensArgsArgs {
        header: PHeader.TAuthHeader;
        request: GoogleAuthTokens.TGoogleAuthTokensRequest;
    }
    export class UpdateGoogleAuthTokensArgs {
        public header: PHeader.TAuthHeader;
        public request: GoogleAuthTokens.TGoogleAuthTokensRequest;
        constructor(args: IUpdateGoogleAuthTokensArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateGoogleAuthTokensArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateGoogleAuthTokensArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_13: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_13;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_14: GoogleAuthTokens.TGoogleAuthTokensRequest = GoogleAuthTokens.TGoogleAuthTokensRequest.read(input);
                            _args.request = value_14;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new UpdateGoogleAuthTokensArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpdateGoogleAuthTokensArgs from input");
            }
        }
    }
    export interface IUpdateMicrosoftAuthTokensArgsArgs {
        header: PHeader.TAuthHeader;
        request: MicrosoftAuthTokens.TMicrosoftAuthTokensRequest;
    }
    export class UpdateMicrosoftAuthTokensArgs {
        public header: PHeader.TAuthHeader;
        public request: MicrosoftAuthTokens.TMicrosoftAuthTokensRequest;
        constructor(args: IUpdateMicrosoftAuthTokensArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateMicrosoftAuthTokensArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateMicrosoftAuthTokensArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_15: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_15;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_16: MicrosoftAuthTokens.TMicrosoftAuthTokensRequest = MicrosoftAuthTokens.TMicrosoftAuthTokensRequest.read(input);
                            _args.request = value_16;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new UpdateMicrosoftAuthTokensArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpdateMicrosoftAuthTokensArgs from input");
            }
        }
    }
    export interface IUpdateUserMetadataArgsArgs {
        header: PHeader.TAuthHeader;
        request: UpdateUserMetadata.TUpdateUserMetadataRequest;
    }
    export class UpdateUserMetadataArgs {
        public header: PHeader.TAuthHeader;
        public request: UpdateUserMetadata.TUpdateUserMetadataRequest;
        constructor(args: IUpdateUserMetadataArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateUserMetadataArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateUserMetadataArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_17: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_17;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_18: UpdateUserMetadata.TUpdateUserMetadataRequest = UpdateUserMetadata.TUpdateUserMetadataRequest.read(input);
                            _args.request = value_18;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new UpdateUserMetadataArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpdateUserMetadataArgs from input");
            }
        }
    }
    export interface IUpdateSilentNotificationTokenArgsArgs {
        request: UpdateUserMetadata.TUpdateSilentNotificationTokenRequest;
    }
    export class UpdateSilentNotificationTokenArgs {
        public request: UpdateUserMetadata.TUpdateSilentNotificationTokenRequest;
        constructor(args: IUpdateSilentNotificationTokenArgsArgs) {
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateSilentNotificationTokenArgs");
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 1);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateSilentNotificationTokenArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_19: UpdateUserMetadata.TUpdateSilentNotificationTokenRequest = UpdateUserMetadata.TUpdateSilentNotificationTokenRequest.read(input);
                            _args.request = value_19;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.request !== undefined) {
                return new UpdateSilentNotificationTokenArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpdateSilentNotificationTokenArgs from input");
            }
        }
    }
    export interface IOptOutArgsArgs {
        header: PHeader.TAuthHeader;
        request: OptOut.TOptOutRequest;
    }
    export class OptOutArgs {
        public header: PHeader.TAuthHeader;
        public request: OptOut.TOptOutRequest;
        constructor(args: IOptOutArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("OptOutArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): OptOutArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_20: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_20;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_21: OptOut.TOptOutRequest = OptOut.TOptOutRequest.read(input);
                            _args.request = value_21;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new OptOutArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read OptOutArgs from input");
            }
        }
    }
    export interface IUpdateTopContactArgsArgs {
        header: PHeader.TAuthHeader;
        request: TopContacts.TUpdateTopContactStatusRequest;
    }
    export class UpdateTopContactArgs {
        public header: PHeader.TAuthHeader;
        public request: TopContacts.TUpdateTopContactStatusRequest;
        constructor(args: IUpdateTopContactArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateTopContactArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateTopContactArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_22: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_22;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_23: TopContacts.TUpdateTopContactStatusRequest = TopContacts.TUpdateTopContactStatusRequest.read(input);
                            _args.request = value_23;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new UpdateTopContactArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpdateTopContactArgs from input");
            }
        }
    }
    export interface IStoreEncryptionKeyArgsArgs {
        header: PHeader.TAuthHeader;
        request: EncryptionStorage.TEncryptionStorageRequest;
    }
    export class StoreEncryptionKeyArgs {
        public header: PHeader.TAuthHeader;
        public request: EncryptionStorage.TEncryptionStorageRequest;
        constructor(args: IStoreEncryptionKeyArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("StoreEncryptionKeyArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): StoreEncryptionKeyArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_24: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_24;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_25: EncryptionStorage.TEncryptionStorageRequest = EncryptionStorage.TEncryptionStorageRequest.read(input);
                            _args.request = value_25;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new StoreEncryptionKeyArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read StoreEncryptionKeyArgs from input");
            }
        }
    }
    export interface IGetContactProcessingStatusArgsArgs {
        header: PHeader.TAuthHeader;
        request: ContactProcessingStatus.TContactProcessingStatusRequest;
    }
    export class GetContactProcessingStatusArgs {
        public header: PHeader.TAuthHeader;
        public request: ContactProcessingStatus.TContactProcessingStatusRequest;
        constructor(args: IGetContactProcessingStatusArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetContactProcessingStatusArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetContactProcessingStatusArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_26: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_26;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_27: ContactProcessingStatus.TContactProcessingStatusRequest = ContactProcessingStatus.TContactProcessingStatusRequest.read(input);
                            _args.request = value_27;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetContactProcessingStatusArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetContactProcessingStatusArgs from input");
            }
        }
    }
    export interface ISaveUserHealthStatsArgsArgs {
        header: PHeader.TAuthHeader;
        request: UserHealthStats.TSaveUserHealthStatsRequest;
    }
    export class SaveUserHealthStatsArgs {
        public header: PHeader.TAuthHeader;
        public request: UserHealthStats.TSaveUserHealthStatsRequest;
        constructor(args: ISaveUserHealthStatsArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SaveUserHealthStatsArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SaveUserHealthStatsArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_28: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_28;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_29: UserHealthStats.TSaveUserHealthStatsRequest = UserHealthStats.TSaveUserHealthStatsRequest.read(input);
                            _args.request = value_29;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new SaveUserHealthStatsArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SaveUserHealthStatsArgs from input");
            }
        }
    }
    export interface IGetInitialDataArgsArgs {
        header: PHeader.TAuthHeader;
        request: InitialData.TGetInitialDataRequest;
    }
    export class GetInitialDataArgs {
        public header: PHeader.TAuthHeader;
        public request: InitialData.TGetInitialDataRequest;
        constructor(args: IGetInitialDataArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetInitialDataArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetInitialDataArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_30: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_30;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_31: InitialData.TGetInitialDataRequest = InitialData.TGetInitialDataRequest.read(input);
                            _args.request = value_31;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new GetInitialDataArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetInitialDataArgs from input");
            }
        }
    }
    export interface IUpdateUserSettingsResultArgs {
        success?: UpdateUserMetadata.TUpdateUserSettingsResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class UpdateUserSettingsResult {
        public success?: UpdateUserMetadata.TUpdateUserSettingsResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IUpdateUserSettingsResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateUserSettingsResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateUserSettingsResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_32: UpdateUserMetadata.TUpdateUserSettingsResponse = UpdateUserMetadata.TUpdateUserSettingsResponse.read(input);
                            _args.success = value_32;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_33: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_33;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_34: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_34;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new UpdateUserSettingsResult(_args);
        }
    }
    export interface IFetchEmailScheduleResultArgs {
        success?: UpdateUserMetadata.TFetchEmailScheduleResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class FetchEmailScheduleResult {
        public success?: UpdateUserMetadata.TFetchEmailScheduleResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IFetchEmailScheduleResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("FetchEmailScheduleResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): FetchEmailScheduleResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_35: UpdateUserMetadata.TFetchEmailScheduleResponse = UpdateUserMetadata.TFetchEmailScheduleResponse.read(input);
                            _args.success = value_35;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_36: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_36;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_37: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_37;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new FetchEmailScheduleResult(_args);
        }
    }
    export interface IUpdateCanonicalPhoneResultArgs {
        success?: AccountDetails.TUpdateCanonicalPhoneResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class UpdateCanonicalPhoneResult {
        public success?: AccountDetails.TUpdateCanonicalPhoneResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IUpdateCanonicalPhoneResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateCanonicalPhoneResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateCanonicalPhoneResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_38: AccountDetails.TUpdateCanonicalPhoneResponse = AccountDetails.TUpdateCanonicalPhoneResponse.read(input);
                            _args.success = value_38;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_39: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_39;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_40: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_40;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new UpdateCanonicalPhoneResult(_args);
        }
    }
    export interface ISendDistressResultArgs {
        success?: Distress.TDistressResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class SendDistressResult {
        public success?: Distress.TDistressResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ISendDistressResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendDistressResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendDistressResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_41: Distress.TDistressResponse = Distress.TDistressResponse.read(input);
                            _args.success = value_41;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_42: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_42;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_43: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_43;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new SendDistressResult(_args);
        }
    }
    export interface IContactLooksGoodResultArgs {
        success?: Quality.TContactLooksGoodResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class ContactLooksGoodResult {
        public success?: Quality.TContactLooksGoodResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IContactLooksGoodResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("ContactLooksGoodResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): ContactLooksGoodResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_44: Quality.TContactLooksGoodResponse = Quality.TContactLooksGoodResponse.read(input);
                            _args.success = value_44;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_45: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_45;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_46: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_46;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new ContactLooksGoodResult(_args);
        }
    }
    export interface IGetAuthorizationUrlResultArgs {
        success?: GetAuthorizationUrl.TGetAuthorizationUrlResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetAuthorizationUrlResult {
        public success?: GetAuthorizationUrl.TGetAuthorizationUrlResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetAuthorizationUrlResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetAuthorizationUrlResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetAuthorizationUrlResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_47: GetAuthorizationUrl.TGetAuthorizationUrlResponse = GetAuthorizationUrl.TGetAuthorizationUrlResponse.read(input);
                            _args.success = value_47;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_48: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_48;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_49: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_49;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetAuthorizationUrlResult(_args);
        }
    }
    export interface IUpdateGoogleAuthTokensResultArgs {
        success?: GoogleAuthTokens.TGoogleAuthTokensResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class UpdateGoogleAuthTokensResult {
        public success?: GoogleAuthTokens.TGoogleAuthTokensResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IUpdateGoogleAuthTokensResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateGoogleAuthTokensResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateGoogleAuthTokensResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_50: GoogleAuthTokens.TGoogleAuthTokensResponse = GoogleAuthTokens.TGoogleAuthTokensResponse.read(input);
                            _args.success = value_50;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_51: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_51;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_52: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_52;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new UpdateGoogleAuthTokensResult(_args);
        }
    }
    export interface IUpdateMicrosoftAuthTokensResultArgs {
        success?: MicrosoftAuthTokens.TMicrosoftAuthTokensResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class UpdateMicrosoftAuthTokensResult {
        public success?: MicrosoftAuthTokens.TMicrosoftAuthTokensResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IUpdateMicrosoftAuthTokensResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateMicrosoftAuthTokensResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateMicrosoftAuthTokensResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_53: MicrosoftAuthTokens.TMicrosoftAuthTokensResponse = MicrosoftAuthTokens.TMicrosoftAuthTokensResponse.read(input);
                            _args.success = value_53;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_54: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_54;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_55: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_55;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new UpdateMicrosoftAuthTokensResult(_args);
        }
    }
    export interface IUpdateUserMetadataResultArgs {
        success?: UpdateUserMetadata.TUpdateUserMetadataResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class UpdateUserMetadataResult {
        public success?: UpdateUserMetadata.TUpdateUserMetadataResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IUpdateUserMetadataResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateUserMetadataResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateUserMetadataResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_56: UpdateUserMetadata.TUpdateUserMetadataResponse = UpdateUserMetadata.TUpdateUserMetadataResponse.read(input);
                            _args.success = value_56;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_57: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_57;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_58: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_58;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new UpdateUserMetadataResult(_args);
        }
    }
    export interface IUpdateSilentNotificationTokenResultArgs {
        success?: UpdateUserMetadata.TUpdateSilentNotificationTokenResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class UpdateSilentNotificationTokenResult {
        public success?: UpdateUserMetadata.TUpdateSilentNotificationTokenResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IUpdateSilentNotificationTokenResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateSilentNotificationTokenResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateSilentNotificationTokenResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_59: UpdateUserMetadata.TUpdateSilentNotificationTokenResponse = UpdateUserMetadata.TUpdateSilentNotificationTokenResponse.read(input);
                            _args.success = value_59;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_60: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_60;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_61: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_61;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new UpdateSilentNotificationTokenResult(_args);
        }
    }
    export interface IOptOutResultArgs {
        success?: OptOut.TOptOutResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class OptOutResult {
        public success?: OptOut.TOptOutResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IOptOutResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("OptOutResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): OptOutResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_62: OptOut.TOptOutResponse = OptOut.TOptOutResponse.read(input);
                            _args.success = value_62;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_63: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_63;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_64: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_64;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new OptOutResult(_args);
        }
    }
    export interface IUpdateTopContactResultArgs {
        success?: TopContacts.TUpdateTopContactStatusResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class UpdateTopContactResult {
        public success?: TopContacts.TUpdateTopContactStatusResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IUpdateTopContactResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateTopContactResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateTopContactResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_65: TopContacts.TUpdateTopContactStatusResponse = TopContacts.TUpdateTopContactStatusResponse.read(input);
                            _args.success = value_65;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_66: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_66;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_67: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_67;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new UpdateTopContactResult(_args);
        }
    }
    export interface IStoreEncryptionKeyResultArgs {
        success?: EncryptionStorage.TEncryptionStorageResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class StoreEncryptionKeyResult {
        public success?: EncryptionStorage.TEncryptionStorageResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IStoreEncryptionKeyResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("StoreEncryptionKeyResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): StoreEncryptionKeyResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_68: EncryptionStorage.TEncryptionStorageResponse = EncryptionStorage.TEncryptionStorageResponse.read(input);
                            _args.success = value_68;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_69: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_69;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_70: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_70;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new StoreEncryptionKeyResult(_args);
        }
    }
    export interface IGetContactProcessingStatusResultArgs {
        success?: ContactProcessingStatus.TContactProcessingStatusResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetContactProcessingStatusResult {
        public success?: ContactProcessingStatus.TContactProcessingStatusResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetContactProcessingStatusResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetContactProcessingStatusResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetContactProcessingStatusResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_71: ContactProcessingStatus.TContactProcessingStatusResponse = ContactProcessingStatus.TContactProcessingStatusResponse.read(input);
                            _args.success = value_71;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_72: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_72;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_73: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_73;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetContactProcessingStatusResult(_args);
        }
    }
    export interface ISaveUserHealthStatsResultArgs {
        success?: UserHealthStats.TSaveUserHealthStatsResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class SaveUserHealthStatsResult {
        public success?: UserHealthStats.TSaveUserHealthStatsResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ISaveUserHealthStatsResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SaveUserHealthStatsResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SaveUserHealthStatsResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_74: UserHealthStats.TSaveUserHealthStatsResponse = UserHealthStats.TSaveUserHealthStatsResponse.read(input);
                            _args.success = value_74;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_75: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_75;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_76: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_76;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new SaveUserHealthStatsResult(_args);
        }
    }
    export interface IGetInitialDataResultArgs {
        success?: InitialData.TGetInitialDataResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetInitialDataResult {
        public success?: InitialData.TGetInitialDataResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetInitialDataResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetInitialDataResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetInitialDataResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_77: InitialData.TGetInitialDataResponse = InitialData.TGetInitialDataResponse.read(input);
                            _args.success = value_77;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_78: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_78;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_79: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_79;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetInitialDataResult(_args);
        }
    }
    export class Client {
        public _seqid: number;
        public _reqs: {
            [name: number]: (err: Error | object | undefined, val?: any) => void;
        };
        public output: thrift.TTransport;
        public protocol: new (trans: thrift.TTransport) => thrift.TProtocol;
        constructor(output: thrift.TTransport, protocol: new (trans: thrift.TTransport) => thrift.TProtocol) {
            this._seqid = 0;
            this._reqs = {};
            this.output = output;
            this.protocol = protocol;
        }
        public incrementSeqId(): number {
            return this._seqid += 1;
        }
        public updateUserSettings(header: PHeader.TAuthHeader, request: UpdateUserMetadata.TUpdateUserSettingsRequest): Promise<UpdateUserMetadata.TUpdateUserSettingsResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<UpdateUserMetadata.TUpdateUserSettingsResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_updateUserSettings(header, request, requestId);
            });
        }
        public fetchEmailSchedule(header: PHeader.TAuthHeader, request: UpdateUserMetadata.TFetchEmailScheduleRequest): Promise<UpdateUserMetadata.TFetchEmailScheduleResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<UpdateUserMetadata.TFetchEmailScheduleResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_fetchEmailSchedule(header, request, requestId);
            });
        }
        public updateCanonicalPhone(header: PHeader.TAuthHeader, request: AccountDetails.TUpdateCanonicalPhoneRequest): Promise<AccountDetails.TUpdateCanonicalPhoneResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<AccountDetails.TUpdateCanonicalPhoneResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_updateCanonicalPhone(header, request, requestId);
            });
        }
        public sendDistress(header: PHeader.TAuthHeader, request: Distress.TDistressRequest): Promise<Distress.TDistressResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Distress.TDistressResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_sendDistress(header, request, requestId);
            });
        }
        public contactLooksGood(header: PHeader.TAuthHeader, request: Quality.TContactLooksGoodRequest): Promise<Quality.TContactLooksGoodResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Quality.TContactLooksGoodResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_contactLooksGood(header, request, requestId);
            });
        }
        public getAuthorizationUrl(header: PHeader.TAuthHeader, request: GetAuthorizationUrl.TGetAuthorizationUrlRequest): Promise<GetAuthorizationUrl.TGetAuthorizationUrlResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<GetAuthorizationUrl.TGetAuthorizationUrlResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getAuthorizationUrl(header, request, requestId);
            });
        }
        public updateGoogleAuthTokens(header: PHeader.TAuthHeader, request: GoogleAuthTokens.TGoogleAuthTokensRequest): Promise<GoogleAuthTokens.TGoogleAuthTokensResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<GoogleAuthTokens.TGoogleAuthTokensResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_updateGoogleAuthTokens(header, request, requestId);
            });
        }
        public updateMicrosoftAuthTokens(header: PHeader.TAuthHeader, request: MicrosoftAuthTokens.TMicrosoftAuthTokensRequest): Promise<MicrosoftAuthTokens.TMicrosoftAuthTokensResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<MicrosoftAuthTokens.TMicrosoftAuthTokensResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_updateMicrosoftAuthTokens(header, request, requestId);
            });
        }
        public updateUserMetadata(header: PHeader.TAuthHeader, request: UpdateUserMetadata.TUpdateUserMetadataRequest): Promise<UpdateUserMetadata.TUpdateUserMetadataResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<UpdateUserMetadata.TUpdateUserMetadataResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_updateUserMetadata(header, request, requestId);
            });
        }
        public updateSilentNotificationToken(request: UpdateUserMetadata.TUpdateSilentNotificationTokenRequest): Promise<UpdateUserMetadata.TUpdateSilentNotificationTokenResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<UpdateUserMetadata.TUpdateSilentNotificationTokenResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_updateSilentNotificationToken(request, requestId);
            });
        }
        public optOut(header: PHeader.TAuthHeader, request: OptOut.TOptOutRequest): Promise<OptOut.TOptOutResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<OptOut.TOptOutResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_optOut(header, request, requestId);
            });
        }
        public updateTopContact(header: PHeader.TAuthHeader, request: TopContacts.TUpdateTopContactStatusRequest): Promise<TopContacts.TUpdateTopContactStatusResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<TopContacts.TUpdateTopContactStatusResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_updateTopContact(header, request, requestId);
            });
        }
        public storeEncryptionKey(header: PHeader.TAuthHeader, request: EncryptionStorage.TEncryptionStorageRequest): Promise<EncryptionStorage.TEncryptionStorageResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<EncryptionStorage.TEncryptionStorageResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_storeEncryptionKey(header, request, requestId);
            });
        }
        public getContactProcessingStatus(header: PHeader.TAuthHeader, request: ContactProcessingStatus.TContactProcessingStatusRequest): Promise<ContactProcessingStatus.TContactProcessingStatusResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<ContactProcessingStatus.TContactProcessingStatusResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getContactProcessingStatus(header, request, requestId);
            });
        }
        public saveUserHealthStats(header: PHeader.TAuthHeader, request: UserHealthStats.TSaveUserHealthStatsRequest): Promise<UserHealthStats.TSaveUserHealthStatsResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<UserHealthStats.TSaveUserHealthStatsResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_saveUserHealthStats(header, request, requestId);
            });
        }
        public getInitialData(header: PHeader.TAuthHeader, request: InitialData.TGetInitialDataRequest): Promise<InitialData.TGetInitialDataResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<InitialData.TGetInitialDataResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getInitialData(header, request, requestId);
            });
        }
        public send_updateUserSettings(header: PHeader.TAuthHeader, request: UpdateUserMetadata.TUpdateUserSettingsRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("updateUserSettings", thrift.Thrift.MessageType.CALL, requestId);
            const args: UpdateUserSettingsArgs = new UpdateUserSettingsArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_fetchEmailSchedule(header: PHeader.TAuthHeader, request: UpdateUserMetadata.TFetchEmailScheduleRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("fetchEmailSchedule", thrift.Thrift.MessageType.CALL, requestId);
            const args: FetchEmailScheduleArgs = new FetchEmailScheduleArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_updateCanonicalPhone(header: PHeader.TAuthHeader, request: AccountDetails.TUpdateCanonicalPhoneRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("updateCanonicalPhone", thrift.Thrift.MessageType.CALL, requestId);
            const args: UpdateCanonicalPhoneArgs = new UpdateCanonicalPhoneArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_sendDistress(header: PHeader.TAuthHeader, request: Distress.TDistressRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("sendDistress", thrift.Thrift.MessageType.CALL, requestId);
            const args: SendDistressArgs = new SendDistressArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_contactLooksGood(header: PHeader.TAuthHeader, request: Quality.TContactLooksGoodRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("contactLooksGood", thrift.Thrift.MessageType.CALL, requestId);
            const args: ContactLooksGoodArgs = new ContactLooksGoodArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getAuthorizationUrl(header: PHeader.TAuthHeader, request: GetAuthorizationUrl.TGetAuthorizationUrlRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getAuthorizationUrl", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetAuthorizationUrlArgs = new GetAuthorizationUrlArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_updateGoogleAuthTokens(header: PHeader.TAuthHeader, request: GoogleAuthTokens.TGoogleAuthTokensRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("updateGoogleAuthTokens", thrift.Thrift.MessageType.CALL, requestId);
            const args: UpdateGoogleAuthTokensArgs = new UpdateGoogleAuthTokensArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_updateMicrosoftAuthTokens(header: PHeader.TAuthHeader, request: MicrosoftAuthTokens.TMicrosoftAuthTokensRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("updateMicrosoftAuthTokens", thrift.Thrift.MessageType.CALL, requestId);
            const args: UpdateMicrosoftAuthTokensArgs = new UpdateMicrosoftAuthTokensArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_updateUserMetadata(header: PHeader.TAuthHeader, request: UpdateUserMetadata.TUpdateUserMetadataRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("updateUserMetadata", thrift.Thrift.MessageType.CALL, requestId);
            const args: UpdateUserMetadataArgs = new UpdateUserMetadataArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_updateSilentNotificationToken(request: UpdateUserMetadata.TUpdateSilentNotificationTokenRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("updateSilentNotificationToken", thrift.Thrift.MessageType.CALL, requestId);
            const args: UpdateSilentNotificationTokenArgs = new UpdateSilentNotificationTokenArgs({ request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_optOut(header: PHeader.TAuthHeader, request: OptOut.TOptOutRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("optOut", thrift.Thrift.MessageType.CALL, requestId);
            const args: OptOutArgs = new OptOutArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_updateTopContact(header: PHeader.TAuthHeader, request: TopContacts.TUpdateTopContactStatusRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("updateTopContact", thrift.Thrift.MessageType.CALL, requestId);
            const args: UpdateTopContactArgs = new UpdateTopContactArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_storeEncryptionKey(header: PHeader.TAuthHeader, request: EncryptionStorage.TEncryptionStorageRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("storeEncryptionKey", thrift.Thrift.MessageType.CALL, requestId);
            const args: StoreEncryptionKeyArgs = new StoreEncryptionKeyArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getContactProcessingStatus(header: PHeader.TAuthHeader, request: ContactProcessingStatus.TContactProcessingStatusRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getContactProcessingStatus", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetContactProcessingStatusArgs = new GetContactProcessingStatusArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_saveUserHealthStats(header: PHeader.TAuthHeader, request: UserHealthStats.TSaveUserHealthStatsRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("saveUserHealthStats", thrift.Thrift.MessageType.CALL, requestId);
            const args: SaveUserHealthStatsArgs = new SaveUserHealthStatsArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getInitialData(header: PHeader.TAuthHeader, request: InitialData.TGetInitialDataRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getInitialData", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetInitialDataArgs = new GetInitialDataArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public recv_updateUserSettings(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: UpdateUserSettingsResult = UpdateUserSettingsResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "updateUserSettings failed: unknown result"));
                    }
                }
            }
        }
        public recv_fetchEmailSchedule(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: FetchEmailScheduleResult = FetchEmailScheduleResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "fetchEmailSchedule failed: unknown result"));
                    }
                }
            }
        }
        public recv_updateCanonicalPhone(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: UpdateCanonicalPhoneResult = UpdateCanonicalPhoneResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "updateCanonicalPhone failed: unknown result"));
                    }
                }
            }
        }
        public recv_sendDistress(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: SendDistressResult = SendDistressResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "sendDistress failed: unknown result"));
                    }
                }
            }
        }
        public recv_contactLooksGood(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: ContactLooksGoodResult = ContactLooksGoodResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "contactLooksGood failed: unknown result"));
                    }
                }
            }
        }
        public recv_getAuthorizationUrl(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetAuthorizationUrlResult = GetAuthorizationUrlResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getAuthorizationUrl failed: unknown result"));
                    }
                }
            }
        }
        public recv_updateGoogleAuthTokens(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: UpdateGoogleAuthTokensResult = UpdateGoogleAuthTokensResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "updateGoogleAuthTokens failed: unknown result"));
                    }
                }
            }
        }
        public recv_updateMicrosoftAuthTokens(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: UpdateMicrosoftAuthTokensResult = UpdateMicrosoftAuthTokensResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "updateMicrosoftAuthTokens failed: unknown result"));
                    }
                }
            }
        }
        public recv_updateUserMetadata(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: UpdateUserMetadataResult = UpdateUserMetadataResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "updateUserMetadata failed: unknown result"));
                    }
                }
            }
        }
        public recv_updateSilentNotificationToken(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: UpdateSilentNotificationTokenResult = UpdateSilentNotificationTokenResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "updateSilentNotificationToken failed: unknown result"));
                    }
                }
            }
        }
        public recv_optOut(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: OptOutResult = OptOutResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "optOut failed: unknown result"));
                    }
                }
            }
        }
        public recv_updateTopContact(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: UpdateTopContactResult = UpdateTopContactResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "updateTopContact failed: unknown result"));
                    }
                }
            }
        }
        public recv_storeEncryptionKey(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: StoreEncryptionKeyResult = StoreEncryptionKeyResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "storeEncryptionKey failed: unknown result"));
                    }
                }
            }
        }
        public recv_getContactProcessingStatus(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetContactProcessingStatusResult = GetContactProcessingStatusResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getContactProcessingStatus failed: unknown result"));
                    }
                }
            }
        }
        public recv_saveUserHealthStats(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: SaveUserHealthStatsResult = SaveUserHealthStatsResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "saveUserHealthStats failed: unknown result"));
                    }
                }
            }
        }
        public recv_getInitialData(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetInitialDataResult = GetInitialDataResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getInitialData failed: unknown result"));
                    }
                }
            }
        }
    }
    export interface IHandler {
        updateUserSettings(header: PHeader.TAuthHeader, request: UpdateUserMetadata.TUpdateUserSettingsRequest): UpdateUserMetadata.TUpdateUserSettingsResponse | Promise<UpdateUserMetadata.TUpdateUserSettingsResponse>;
        fetchEmailSchedule(header: PHeader.TAuthHeader, request: UpdateUserMetadata.TFetchEmailScheduleRequest): UpdateUserMetadata.TFetchEmailScheduleResponse | Promise<UpdateUserMetadata.TFetchEmailScheduleResponse>;
        updateCanonicalPhone(header: PHeader.TAuthHeader, request: AccountDetails.TUpdateCanonicalPhoneRequest): AccountDetails.TUpdateCanonicalPhoneResponse | Promise<AccountDetails.TUpdateCanonicalPhoneResponse>;
        sendDistress(header: PHeader.TAuthHeader, request: Distress.TDistressRequest): Distress.TDistressResponse | Promise<Distress.TDistressResponse>;
        contactLooksGood(header: PHeader.TAuthHeader, request: Quality.TContactLooksGoodRequest): Quality.TContactLooksGoodResponse | Promise<Quality.TContactLooksGoodResponse>;
        getAuthorizationUrl(header: PHeader.TAuthHeader, request: GetAuthorizationUrl.TGetAuthorizationUrlRequest): GetAuthorizationUrl.TGetAuthorizationUrlResponse | Promise<GetAuthorizationUrl.TGetAuthorizationUrlResponse>;
        updateGoogleAuthTokens(header: PHeader.TAuthHeader, request: GoogleAuthTokens.TGoogleAuthTokensRequest): GoogleAuthTokens.TGoogleAuthTokensResponse | Promise<GoogleAuthTokens.TGoogleAuthTokensResponse>;
        updateMicrosoftAuthTokens(header: PHeader.TAuthHeader, request: MicrosoftAuthTokens.TMicrosoftAuthTokensRequest): MicrosoftAuthTokens.TMicrosoftAuthTokensResponse | Promise<MicrosoftAuthTokens.TMicrosoftAuthTokensResponse>;
        updateUserMetadata(header: PHeader.TAuthHeader, request: UpdateUserMetadata.TUpdateUserMetadataRequest): UpdateUserMetadata.TUpdateUserMetadataResponse | Promise<UpdateUserMetadata.TUpdateUserMetadataResponse>;
        updateSilentNotificationToken(request: UpdateUserMetadata.TUpdateSilentNotificationTokenRequest): UpdateUserMetadata.TUpdateSilentNotificationTokenResponse | Promise<UpdateUserMetadata.TUpdateSilentNotificationTokenResponse>;
        optOut(header: PHeader.TAuthHeader, request: OptOut.TOptOutRequest): OptOut.TOptOutResponse | Promise<OptOut.TOptOutResponse>;
        updateTopContact(header: PHeader.TAuthHeader, request: TopContacts.TUpdateTopContactStatusRequest): TopContacts.TUpdateTopContactStatusResponse | Promise<TopContacts.TUpdateTopContactStatusResponse>;
        storeEncryptionKey(header: PHeader.TAuthHeader, request: EncryptionStorage.TEncryptionStorageRequest): EncryptionStorage.TEncryptionStorageResponse | Promise<EncryptionStorage.TEncryptionStorageResponse>;
        getContactProcessingStatus(header: PHeader.TAuthHeader, request: ContactProcessingStatus.TContactProcessingStatusRequest): ContactProcessingStatus.TContactProcessingStatusResponse | Promise<ContactProcessingStatus.TContactProcessingStatusResponse>;
        saveUserHealthStats(header: PHeader.TAuthHeader, request: UserHealthStats.TSaveUserHealthStatsRequest): UserHealthStats.TSaveUserHealthStatsResponse | Promise<UserHealthStats.TSaveUserHealthStatsResponse>;
        getInitialData(header: PHeader.TAuthHeader, request: InitialData.TGetInitialDataRequest): InitialData.TGetInitialDataResponse | Promise<InitialData.TGetInitialDataResponse>;
    }
    export class Processor {
        public _handler: IHandler;
        constructor(handler: IHandler) {
            this._handler = handler;
        }
        public process(input: thrift.TProtocol, output: thrift.TProtocol): void {
            const metadata: thrift.TMessage = input.readMessageBegin();
            const fname: string = metadata.fname;
            const requestId: number = metadata.rseqid;
            const methodName: string = "process_" + fname;
            switch (methodName) {
                case "process_updateUserSettings": {
                    this.process_updateUserSettings(requestId, input, output);
                    return;
                }
                case "process_fetchEmailSchedule": {
                    this.process_fetchEmailSchedule(requestId, input, output);
                    return;
                }
                case "process_updateCanonicalPhone": {
                    this.process_updateCanonicalPhone(requestId, input, output);
                    return;
                }
                case "process_sendDistress": {
                    this.process_sendDistress(requestId, input, output);
                    return;
                }
                case "process_contactLooksGood": {
                    this.process_contactLooksGood(requestId, input, output);
                    return;
                }
                case "process_getAuthorizationUrl": {
                    this.process_getAuthorizationUrl(requestId, input, output);
                    return;
                }
                case "process_updateGoogleAuthTokens": {
                    this.process_updateGoogleAuthTokens(requestId, input, output);
                    return;
                }
                case "process_updateMicrosoftAuthTokens": {
                    this.process_updateMicrosoftAuthTokens(requestId, input, output);
                    return;
                }
                case "process_updateUserMetadata": {
                    this.process_updateUserMetadata(requestId, input, output);
                    return;
                }
                case "process_updateSilentNotificationToken": {
                    this.process_updateSilentNotificationToken(requestId, input, output);
                    return;
                }
                case "process_optOut": {
                    this.process_optOut(requestId, input, output);
                    return;
                }
                case "process_updateTopContact": {
                    this.process_updateTopContact(requestId, input, output);
                    return;
                }
                case "process_storeEncryptionKey": {
                    this.process_storeEncryptionKey(requestId, input, output);
                    return;
                }
                case "process_getContactProcessingStatus": {
                    this.process_getContactProcessingStatus(requestId, input, output);
                    return;
                }
                case "process_saveUserHealthStats": {
                    this.process_saveUserHealthStats(requestId, input, output);
                    return;
                }
                case "process_getInitialData": {
                    this.process_getInitialData(requestId, input, output);
                    return;
                }
                default: {
                    input.skip(thrift.Thrift.Type.STRUCT);
                    input.readMessageEnd();
                    const errMessage = "Unknown function " + fname;
                    const err = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN_METHOD, errMessage);
                    output.writeMessageBegin(fname, thrift.Thrift.MessageType.EXCEPTION, requestId);
                    err.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            }
        }
        public process_updateUserSettings(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<UpdateUserMetadata.TUpdateUserSettingsResponse>((resolve, reject): void => {
                try {
                    const args: UpdateUserSettingsArgs = UpdateUserSettingsArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.updateUserSettings(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: UpdateUserMetadata.TUpdateUserSettingsResponse): void => {
                const result: UpdateUserSettingsResult = new UpdateUserSettingsResult({ success: data });
                output.writeMessageBegin("updateUserSettings", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: UpdateUserSettingsResult = new UpdateUserSettingsResult({ authFailedError: err });
                    output.writeMessageBegin("updateUserSettings", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: UpdateUserSettingsResult = new UpdateUserSettingsResult({ invalidRequestError: err });
                    output.writeMessageBegin("updateUserSettings", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("updateUserSettings", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_fetchEmailSchedule(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<UpdateUserMetadata.TFetchEmailScheduleResponse>((resolve, reject): void => {
                try {
                    const args: FetchEmailScheduleArgs = FetchEmailScheduleArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.fetchEmailSchedule(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: UpdateUserMetadata.TFetchEmailScheduleResponse): void => {
                const result: FetchEmailScheduleResult = new FetchEmailScheduleResult({ success: data });
                output.writeMessageBegin("fetchEmailSchedule", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: FetchEmailScheduleResult = new FetchEmailScheduleResult({ authFailedError: err });
                    output.writeMessageBegin("fetchEmailSchedule", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: FetchEmailScheduleResult = new FetchEmailScheduleResult({ invalidRequestError: err });
                    output.writeMessageBegin("fetchEmailSchedule", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("fetchEmailSchedule", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_updateCanonicalPhone(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<AccountDetails.TUpdateCanonicalPhoneResponse>((resolve, reject): void => {
                try {
                    const args: UpdateCanonicalPhoneArgs = UpdateCanonicalPhoneArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.updateCanonicalPhone(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: AccountDetails.TUpdateCanonicalPhoneResponse): void => {
                const result: UpdateCanonicalPhoneResult = new UpdateCanonicalPhoneResult({ success: data });
                output.writeMessageBegin("updateCanonicalPhone", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: UpdateCanonicalPhoneResult = new UpdateCanonicalPhoneResult({ authFailedError: err });
                    output.writeMessageBegin("updateCanonicalPhone", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: UpdateCanonicalPhoneResult = new UpdateCanonicalPhoneResult({ invalidRequestError: err });
                    output.writeMessageBegin("updateCanonicalPhone", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("updateCanonicalPhone", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_sendDistress(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Distress.TDistressResponse>((resolve, reject): void => {
                try {
                    const args: SendDistressArgs = SendDistressArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.sendDistress(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Distress.TDistressResponse): void => {
                const result: SendDistressResult = new SendDistressResult({ success: data });
                output.writeMessageBegin("sendDistress", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: SendDistressResult = new SendDistressResult({ authFailedError: err });
                    output.writeMessageBegin("sendDistress", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: SendDistressResult = new SendDistressResult({ invalidRequestError: err });
                    output.writeMessageBegin("sendDistress", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("sendDistress", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_contactLooksGood(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Quality.TContactLooksGoodResponse>((resolve, reject): void => {
                try {
                    const args: ContactLooksGoodArgs = ContactLooksGoodArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.contactLooksGood(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Quality.TContactLooksGoodResponse): void => {
                const result: ContactLooksGoodResult = new ContactLooksGoodResult({ success: data });
                output.writeMessageBegin("contactLooksGood", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: ContactLooksGoodResult = new ContactLooksGoodResult({ authFailedError: err });
                    output.writeMessageBegin("contactLooksGood", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: ContactLooksGoodResult = new ContactLooksGoodResult({ invalidRequestError: err });
                    output.writeMessageBegin("contactLooksGood", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("contactLooksGood", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getAuthorizationUrl(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<GetAuthorizationUrl.TGetAuthorizationUrlResponse>((resolve, reject): void => {
                try {
                    const args: GetAuthorizationUrlArgs = GetAuthorizationUrlArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getAuthorizationUrl(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: GetAuthorizationUrl.TGetAuthorizationUrlResponse): void => {
                const result: GetAuthorizationUrlResult = new GetAuthorizationUrlResult({ success: data });
                output.writeMessageBegin("getAuthorizationUrl", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetAuthorizationUrlResult = new GetAuthorizationUrlResult({ authFailedError: err });
                    output.writeMessageBegin("getAuthorizationUrl", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetAuthorizationUrlResult = new GetAuthorizationUrlResult({ invalidRequestError: err });
                    output.writeMessageBegin("getAuthorizationUrl", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getAuthorizationUrl", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_updateGoogleAuthTokens(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<GoogleAuthTokens.TGoogleAuthTokensResponse>((resolve, reject): void => {
                try {
                    const args: UpdateGoogleAuthTokensArgs = UpdateGoogleAuthTokensArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.updateGoogleAuthTokens(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: GoogleAuthTokens.TGoogleAuthTokensResponse): void => {
                const result: UpdateGoogleAuthTokensResult = new UpdateGoogleAuthTokensResult({ success: data });
                output.writeMessageBegin("updateGoogleAuthTokens", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: UpdateGoogleAuthTokensResult = new UpdateGoogleAuthTokensResult({ authFailedError: err });
                    output.writeMessageBegin("updateGoogleAuthTokens", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: UpdateGoogleAuthTokensResult = new UpdateGoogleAuthTokensResult({ invalidRequestError: err });
                    output.writeMessageBegin("updateGoogleAuthTokens", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("updateGoogleAuthTokens", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_updateMicrosoftAuthTokens(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<MicrosoftAuthTokens.TMicrosoftAuthTokensResponse>((resolve, reject): void => {
                try {
                    const args: UpdateMicrosoftAuthTokensArgs = UpdateMicrosoftAuthTokensArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.updateMicrosoftAuthTokens(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: MicrosoftAuthTokens.TMicrosoftAuthTokensResponse): void => {
                const result: UpdateMicrosoftAuthTokensResult = new UpdateMicrosoftAuthTokensResult({ success: data });
                output.writeMessageBegin("updateMicrosoftAuthTokens", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: UpdateMicrosoftAuthTokensResult = new UpdateMicrosoftAuthTokensResult({ authFailedError: err });
                    output.writeMessageBegin("updateMicrosoftAuthTokens", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: UpdateMicrosoftAuthTokensResult = new UpdateMicrosoftAuthTokensResult({ invalidRequestError: err });
                    output.writeMessageBegin("updateMicrosoftAuthTokens", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("updateMicrosoftAuthTokens", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_updateUserMetadata(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<UpdateUserMetadata.TUpdateUserMetadataResponse>((resolve, reject): void => {
                try {
                    const args: UpdateUserMetadataArgs = UpdateUserMetadataArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.updateUserMetadata(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: UpdateUserMetadata.TUpdateUserMetadataResponse): void => {
                const result: UpdateUserMetadataResult = new UpdateUserMetadataResult({ success: data });
                output.writeMessageBegin("updateUserMetadata", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: UpdateUserMetadataResult = new UpdateUserMetadataResult({ authFailedError: err });
                    output.writeMessageBegin("updateUserMetadata", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: UpdateUserMetadataResult = new UpdateUserMetadataResult({ invalidRequestError: err });
                    output.writeMessageBegin("updateUserMetadata", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("updateUserMetadata", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_updateSilentNotificationToken(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<UpdateUserMetadata.TUpdateSilentNotificationTokenResponse>((resolve, reject): void => {
                try {
                    const args: UpdateSilentNotificationTokenArgs = UpdateSilentNotificationTokenArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.updateSilentNotificationToken(args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: UpdateUserMetadata.TUpdateSilentNotificationTokenResponse): void => {
                const result: UpdateSilentNotificationTokenResult = new UpdateSilentNotificationTokenResult({ success: data });
                output.writeMessageBegin("updateSilentNotificationToken", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: UpdateSilentNotificationTokenResult = new UpdateSilentNotificationTokenResult({ authFailedError: err });
                    output.writeMessageBegin("updateSilentNotificationToken", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: UpdateSilentNotificationTokenResult = new UpdateSilentNotificationTokenResult({ invalidRequestError: err });
                    output.writeMessageBegin("updateSilentNotificationToken", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("updateSilentNotificationToken", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_optOut(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<OptOut.TOptOutResponse>((resolve, reject): void => {
                try {
                    const args: OptOutArgs = OptOutArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.optOut(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: OptOut.TOptOutResponse): void => {
                const result: OptOutResult = new OptOutResult({ success: data });
                output.writeMessageBegin("optOut", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: OptOutResult = new OptOutResult({ authFailedError: err });
                    output.writeMessageBegin("optOut", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: OptOutResult = new OptOutResult({ invalidRequestError: err });
                    output.writeMessageBegin("optOut", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("optOut", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_updateTopContact(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<TopContacts.TUpdateTopContactStatusResponse>((resolve, reject): void => {
                try {
                    const args: UpdateTopContactArgs = UpdateTopContactArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.updateTopContact(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: TopContacts.TUpdateTopContactStatusResponse): void => {
                const result: UpdateTopContactResult = new UpdateTopContactResult({ success: data });
                output.writeMessageBegin("updateTopContact", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: UpdateTopContactResult = new UpdateTopContactResult({ authFailedError: err });
                    output.writeMessageBegin("updateTopContact", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: UpdateTopContactResult = new UpdateTopContactResult({ invalidRequestError: err });
                    output.writeMessageBegin("updateTopContact", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("updateTopContact", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_storeEncryptionKey(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<EncryptionStorage.TEncryptionStorageResponse>((resolve, reject): void => {
                try {
                    const args: StoreEncryptionKeyArgs = StoreEncryptionKeyArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.storeEncryptionKey(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: EncryptionStorage.TEncryptionStorageResponse): void => {
                const result: StoreEncryptionKeyResult = new StoreEncryptionKeyResult({ success: data });
                output.writeMessageBegin("storeEncryptionKey", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: StoreEncryptionKeyResult = new StoreEncryptionKeyResult({ authFailedError: err });
                    output.writeMessageBegin("storeEncryptionKey", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: StoreEncryptionKeyResult = new StoreEncryptionKeyResult({ invalidRequestError: err });
                    output.writeMessageBegin("storeEncryptionKey", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("storeEncryptionKey", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getContactProcessingStatus(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<ContactProcessingStatus.TContactProcessingStatusResponse>((resolve, reject): void => {
                try {
                    const args: GetContactProcessingStatusArgs = GetContactProcessingStatusArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getContactProcessingStatus(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: ContactProcessingStatus.TContactProcessingStatusResponse): void => {
                const result: GetContactProcessingStatusResult = new GetContactProcessingStatusResult({ success: data });
                output.writeMessageBegin("getContactProcessingStatus", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetContactProcessingStatusResult = new GetContactProcessingStatusResult({ authFailedError: err });
                    output.writeMessageBegin("getContactProcessingStatus", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetContactProcessingStatusResult = new GetContactProcessingStatusResult({ invalidRequestError: err });
                    output.writeMessageBegin("getContactProcessingStatus", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getContactProcessingStatus", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_saveUserHealthStats(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<UserHealthStats.TSaveUserHealthStatsResponse>((resolve, reject): void => {
                try {
                    const args: SaveUserHealthStatsArgs = SaveUserHealthStatsArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.saveUserHealthStats(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: UserHealthStats.TSaveUserHealthStatsResponse): void => {
                const result: SaveUserHealthStatsResult = new SaveUserHealthStatsResult({ success: data });
                output.writeMessageBegin("saveUserHealthStats", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: SaveUserHealthStatsResult = new SaveUserHealthStatsResult({ authFailedError: err });
                    output.writeMessageBegin("saveUserHealthStats", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: SaveUserHealthStatsResult = new SaveUserHealthStatsResult({ invalidRequestError: err });
                    output.writeMessageBegin("saveUserHealthStats", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("saveUserHealthStats", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getInitialData(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<InitialData.TGetInitialDataResponse>((resolve, reject): void => {
                try {
                    const args: GetInitialDataArgs = GetInitialDataArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getInitialData(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: InitialData.TGetInitialDataResponse): void => {
                const result: GetInitialDataResult = new GetInitialDataResult({ success: data });
                output.writeMessageBegin("getInitialData", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: GetInitialDataResult = new GetInitialDataResult({ authFailedError: err });
                    output.writeMessageBegin("getInitialData", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetInitialDataResult = new GetInitialDataResult({ invalidRequestError: err });
                    output.writeMessageBegin("getInitialData", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getInitialData", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
    }
}
