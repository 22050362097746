/* tslint:disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v2.0.14
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as VerifyAppVersion from "./.";
export enum TAppPlatform {
    UNDEFINED = 0,
    IOS = 1,
    ANDROID = 2,
    WEB = 3
}
export interface ITAppVersionArgs {
    majorVersion: string;
    minorVersion: string;
    patchVersion: string;
    buildVersion: string;
}
export class TAppVersion {
    public majorVersion: string;
    public minorVersion: string;
    public patchVersion: string;
    public buildVersion: string;
    constructor(args: ITAppVersionArgs) {
        if (args != null && args.majorVersion != null) {
            this.majorVersion = args.majorVersion;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[majorVersion] is unset!");
        }
        if (args != null && args.minorVersion != null) {
            this.minorVersion = args.minorVersion;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[minorVersion] is unset!");
        }
        if (args != null && args.patchVersion != null) {
            this.patchVersion = args.patchVersion;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[patchVersion] is unset!");
        }
        if (args != null && args.buildVersion != null) {
            this.buildVersion = args.buildVersion;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[buildVersion] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppVersion");
        if (this.majorVersion != null) {
            output.writeFieldBegin("majorVersion", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.majorVersion);
            output.writeFieldEnd();
        }
        if (this.minorVersion != null) {
            output.writeFieldBegin("minorVersion", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.minorVersion);
            output.writeFieldEnd();
        }
        if (this.patchVersion != null) {
            output.writeFieldBegin("patchVersion", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.patchVersion);
            output.writeFieldEnd();
        }
        if (this.buildVersion != null) {
            output.writeFieldBegin("buildVersion", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.buildVersion);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppVersion {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_1: string = input.readString();
                        _args.majorVersion = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_2: string = input.readString();
                        _args.minorVersion = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_3: string = input.readString();
                        _args.patchVersion = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_4: string = input.readString();
                        _args.buildVersion = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.majorVersion !== undefined && _args.minorVersion !== undefined && _args.patchVersion !== undefined && _args.buildVersion !== undefined) {
            return new TAppVersion(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppVersion from input");
        }
    }
}
export interface ITVerifyAppVersionRequestArgs {
    appPlatform: TAppPlatform;
    currentAppVersion: TAppVersion;
}
export class TVerifyAppVersionRequest {
    public appPlatform: TAppPlatform;
    public currentAppVersion: TAppVersion;
    constructor(args: ITVerifyAppVersionRequestArgs) {
        if (args != null && args.appPlatform != null) {
            this.appPlatform = args.appPlatform;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[appPlatform] is unset!");
        }
        if (args != null && args.currentAppVersion != null) {
            this.currentAppVersion = args.currentAppVersion;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[currentAppVersion] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TVerifyAppVersionRequest");
        if (this.appPlatform != null) {
            output.writeFieldBegin("appPlatform", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.appPlatform);
            output.writeFieldEnd();
        }
        if (this.currentAppVersion != null) {
            output.writeFieldBegin("currentAppVersion", thrift.Thrift.Type.STRUCT, 2);
            this.currentAppVersion.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TVerifyAppVersionRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_5: TAppPlatform = input.readI32();
                        _args.appPlatform = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_6: TAppVersion = TAppVersion.read(input);
                        _args.currentAppVersion = value_6;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.appPlatform !== undefined && _args.currentAppVersion !== undefined) {
            return new TVerifyAppVersionRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TVerifyAppVersionRequest from input");
        }
    }
}
export interface ITVerifyAppVersionResponseArgs {
    latestVersion: TAppVersion;
    minimumVersion: TAppVersion;
    currentAppVersionBlacklisted?: boolean;
}
export class TVerifyAppVersionResponse {
    public latestVersion: TAppVersion;
    public minimumVersion: TAppVersion;
    public currentAppVersionBlacklisted?: boolean;
    constructor(args: ITVerifyAppVersionResponseArgs) {
        if (args != null && args.latestVersion != null) {
            this.latestVersion = args.latestVersion;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[latestVersion] is unset!");
        }
        if (args != null && args.minimumVersion != null) {
            this.minimumVersion = args.minimumVersion;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[minimumVersion] is unset!");
        }
        if (args != null && args.currentAppVersionBlacklisted != null) {
            this.currentAppVersionBlacklisted = args.currentAppVersionBlacklisted;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TVerifyAppVersionResponse");
        if (this.latestVersion != null) {
            output.writeFieldBegin("latestVersion", thrift.Thrift.Type.STRUCT, 1);
            this.latestVersion.write(output);
            output.writeFieldEnd();
        }
        if (this.minimumVersion != null) {
            output.writeFieldBegin("minimumVersion", thrift.Thrift.Type.STRUCT, 2);
            this.minimumVersion.write(output);
            output.writeFieldEnd();
        }
        if (this.currentAppVersionBlacklisted != null) {
            output.writeFieldBegin("currentAppVersionBlacklisted", thrift.Thrift.Type.BOOL, 3);
            output.writeBool(this.currentAppVersionBlacklisted);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TVerifyAppVersionResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_7: TAppVersion = TAppVersion.read(input);
                        _args.latestVersion = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_8: TAppVersion = TAppVersion.read(input);
                        _args.minimumVersion = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_9: boolean = input.readBool();
                        _args.currentAppVersionBlacklisted = value_9;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.latestVersion !== undefined && _args.minimumVersion !== undefined) {
            return new TVerifyAppVersionResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TVerifyAppVersionResponse from input");
        }
    }
}
export namespace TAppVersionService {
    export interface IVerifyAppVersionArgsArgs {
        request: VerifyAppVersion.TVerifyAppVersionRequest;
    }
    export class VerifyAppVersionArgs {
        public request: VerifyAppVersion.TVerifyAppVersionRequest;
        constructor(args: IVerifyAppVersionArgsArgs) {
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("VerifyAppVersionArgs");
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 1);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): VerifyAppVersionArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_10: VerifyAppVersion.TVerifyAppVersionRequest = VerifyAppVersion.TVerifyAppVersionRequest.read(input);
                            _args.request = value_10;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.request !== undefined) {
                return new VerifyAppVersionArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read VerifyAppVersionArgs from input");
            }
        }
    }
    export interface IVerifyAppVersionResultArgs {
        success?: VerifyAppVersion.TVerifyAppVersionResponse;
    }
    export class VerifyAppVersionResult {
        public success?: VerifyAppVersion.TVerifyAppVersionResponse;
        constructor(args?: IVerifyAppVersionResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("VerifyAppVersionResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): VerifyAppVersionResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_11: VerifyAppVersion.TVerifyAppVersionResponse = VerifyAppVersion.TVerifyAppVersionResponse.read(input);
                            _args.success = value_11;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new VerifyAppVersionResult(_args);
        }
    }
    export class Client {
        public _seqid: number;
        public _reqs: {
            [name: number]: (err: Error | object | undefined, val?: any) => void;
        };
        public output: thrift.TTransport;
        public protocol: new (trans: thrift.TTransport) => thrift.TProtocol;
        constructor(output: thrift.TTransport, protocol: new (trans: thrift.TTransport) => thrift.TProtocol) {
            this._seqid = 0;
            this._reqs = {};
            this.output = output;
            this.protocol = protocol;
        }
        public incrementSeqId(): number {
            return this._seqid += 1;
        }
        public verifyAppVersion(request: VerifyAppVersion.TVerifyAppVersionRequest): Promise<VerifyAppVersion.TVerifyAppVersionResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<VerifyAppVersion.TVerifyAppVersionResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_verifyAppVersion(request, requestId);
            });
        }
        public send_verifyAppVersion(request: VerifyAppVersion.TVerifyAppVersionRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("verifyAppVersion", thrift.Thrift.MessageType.CALL, requestId);
            const args: VerifyAppVersionArgs = new VerifyAppVersionArgs({ request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public recv_verifyAppVersion(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: VerifyAppVersionResult = VerifyAppVersionResult.read(input);
                input.readMessageEnd();
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "verifyAppVersion failed: unknown result"));
                }
            }
        }
    }
    export interface IHandler {
        verifyAppVersion(request: VerifyAppVersion.TVerifyAppVersionRequest): VerifyAppVersion.TVerifyAppVersionResponse | Promise<VerifyAppVersion.TVerifyAppVersionResponse>;
    }
    export class Processor {
        public _handler: IHandler;
        constructor(handler: IHandler) {
            this._handler = handler;
        }
        public process(input: thrift.TProtocol, output: thrift.TProtocol): void {
            const metadata: thrift.TMessage = input.readMessageBegin();
            const fname: string = metadata.fname;
            const requestId: number = metadata.rseqid;
            const methodName: string = "process_" + fname;
            switch (methodName) {
                case "process_verifyAppVersion": {
                    this.process_verifyAppVersion(requestId, input, output);
                    return;
                }
                default: {
                    input.skip(thrift.Thrift.Type.STRUCT);
                    input.readMessageEnd();
                    const errMessage = "Unknown function " + fname;
                    const err = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN_METHOD, errMessage);
                    output.writeMessageBegin(fname, thrift.Thrift.MessageType.EXCEPTION, requestId);
                    err.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            }
        }
        public process_verifyAppVersion(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<VerifyAppVersion.TVerifyAppVersionResponse>((resolve, reject): void => {
                try {
                    const args: VerifyAppVersionArgs = VerifyAppVersionArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.verifyAppVersion(args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: VerifyAppVersion.TVerifyAppVersionResponse): void => {
                const result: VerifyAppVersionResult = new VerifyAppVersionResult({ success: data });
                output.writeMessageBegin("verifyAppVersion", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("verifyAppVersion", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            });
        }
    }
}
