/* tslint:disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v2.0.14
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/

import * as thrift from "thrift";
import { Int64 } from "thrift";
import * as PContact from "./../../../common/models";
import * as ContactLite from "./../../../contactlite/messages";
import * as ElkEvent from "./.";
export enum TAppElkAttendeeRole {
    UNDEFINED = 0,
    ORGANIZER = 1,
    ATTENDEE = 2
}
export enum TAppElkAttendeeStatus {
    INVITED = 0,
    YES = 1,
    SEEN = 2,
    HIDDEN = 3,
    NO = 4
}
export enum TAppElkInviteStatus {
    PENDING = 0,
    TRIGGERED = 1,
    FAILED = 2,
    SUCCESS = 3,
    OPENED = 4,
    CLICKED = 5
}
export enum TAppElkTextPermissionStatus {
    UNDEFINED = 0,
    REJECTED,
    GRANTED,
    UNSUBSCRIBED
}
export enum TAppElkBackgroundAnimation {
    FIREWORKS,
    CHAMPAGNE_BUBBLES,
    COSMIC,
    BLURRED_LINES,
    BLURRY_BUBBLES,
    OIL_ON_WATER,
    LIGHT_SOURCE
}
export enum TAppElkPhotoUploadMode {
    UNDEFINED = 0,
    AUTOMATIC = 1,
    MANUAL = 2
}
export enum TAppElkEventQuestionType {
    TEXT = 1,
    YES_NO = 2,
    SONG = 3
}
export enum TAppElkNotificationPreference {
    PHONE = 1,
    EMAIL = 2,
    BOTH = 3
}
export enum TElkAlbumJoinStatus {
    JOINED_NO_PHOENIX_ACCOUNT = 1,
    JOINED_WITH_PHOENIX_ACCOUNT = 2,
    NOT_JOINED = 3
}
export interface ITAppElkPhoenixEventArgs {
    id: string;
    theme: TAppElkEventTheme;
}
export class TAppElkPhoenixEvent {
    public id: string;
    public theme: TAppElkEventTheme;
    constructor(args: ITAppElkPhoenixEventArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
        if (args != null && args.theme != null) {
            this.theme = args.theme;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[theme] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkPhoenixEvent");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        if (this.theme != null) {
            output.writeFieldBegin("theme", thrift.Thrift.Type.STRUCT, 2);
            this.theme.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkPhoenixEvent {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_1: string = input.readString();
                        _args.id = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_2: TAppElkEventTheme = TAppElkEventTheme.read(input);
                        _args.theme = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined && _args.theme !== undefined) {
            return new TAppElkPhoenixEvent(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkPhoenixEvent from input");
        }
    }
}
export interface ITAppElkDraftEventArgs {
    id: string;
    organizerUuid?: string;
    details?: TAppElkDraftEventDetails;
    theme?: TAppElkDraftEventTheme;
    metadata?: TAppElkDraftEventMetadata;
    attendees?: Array<TAppElkDraftInvitee>;
    url?: string;
    questions?: Array<TAppElkDraftEventQuestion>;
    lastSavedTimestamp?: number | Int64;
}
export class TAppElkDraftEvent {
    public id: string;
    public organizerUuid?: string;
    public details?: TAppElkDraftEventDetails;
    public theme?: TAppElkDraftEventTheme;
    public metadata?: TAppElkDraftEventMetadata;
    public attendees?: Array<TAppElkDraftInvitee>;
    public url?: string;
    public questions?: Array<TAppElkDraftEventQuestion>;
    public lastSavedTimestamp?: Int64;
    constructor(args: ITAppElkDraftEventArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
        if (args != null && args.organizerUuid != null) {
            this.organizerUuid = args.organizerUuid;
        }
        if (args != null && args.details != null) {
            this.details = args.details;
        }
        if (args != null && args.theme != null) {
            this.theme = args.theme;
        }
        if (args != null && args.metadata != null) {
            this.metadata = args.metadata;
        }
        if (args != null && args.attendees != null) {
            this.attendees = args.attendees;
        }
        if (args != null && args.url != null) {
            this.url = args.url;
        }
        if (args != null && args.questions != null) {
            this.questions = args.questions;
        }
        if (args != null && args.lastSavedTimestamp != null) {
            if (typeof args.lastSavedTimestamp === "number") {
                this.lastSavedTimestamp = new Int64(args.lastSavedTimestamp);
            }
            else {
                this.lastSavedTimestamp = args.lastSavedTimestamp;
            }
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkDraftEvent");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        if (this.organizerUuid != null) {
            output.writeFieldBegin("organizerUuid", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.organizerUuid);
            output.writeFieldEnd();
        }
        if (this.details != null) {
            output.writeFieldBegin("details", thrift.Thrift.Type.STRUCT, 3);
            this.details.write(output);
            output.writeFieldEnd();
        }
        if (this.theme != null) {
            output.writeFieldBegin("theme", thrift.Thrift.Type.STRUCT, 4);
            this.theme.write(output);
            output.writeFieldEnd();
        }
        if (this.metadata != null) {
            output.writeFieldBegin("metadata", thrift.Thrift.Type.STRUCT, 5);
            this.metadata.write(output);
            output.writeFieldEnd();
        }
        if (this.attendees != null) {
            output.writeFieldBegin("attendees", thrift.Thrift.Type.LIST, 6);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.attendees.length);
            this.attendees.forEach((value_3: TAppElkDraftInvitee): void => {
                value_3.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.url != null) {
            output.writeFieldBegin("url", thrift.Thrift.Type.STRING, 7);
            output.writeString(this.url);
            output.writeFieldEnd();
        }
        if (this.questions != null) {
            output.writeFieldBegin("questions", thrift.Thrift.Type.LIST, 8);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.questions.length);
            this.questions.forEach((value_4: TAppElkDraftEventQuestion): void => {
                value_4.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.lastSavedTimestamp != null) {
            output.writeFieldBegin("lastSavedTimestamp", thrift.Thrift.Type.I64, 9);
            output.writeI64(this.lastSavedTimestamp);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkDraftEvent {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_5: string = input.readString();
                        _args.id = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_6: string = input.readString();
                        _args.organizerUuid = value_6;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_7: TAppElkDraftEventDetails = TAppElkDraftEventDetails.read(input);
                        _args.details = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_8: TAppElkDraftEventTheme = TAppElkDraftEventTheme.read(input);
                        _args.theme = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_9: TAppElkDraftEventMetadata = TAppElkDraftEventMetadata.read(input);
                        _args.metadata = value_9;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_10: Array<TAppElkDraftInvitee> = new Array<TAppElkDraftInvitee>();
                        const metadata_1: thrift.TList = input.readListBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const value_11: TAppElkDraftInvitee = TAppElkDraftInvitee.read(input);
                            value_10.push(value_11);
                        }
                        input.readListEnd();
                        _args.attendees = value_10;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_12: string = input.readString();
                        _args.url = value_12;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_13: Array<TAppElkDraftEventQuestion> = new Array<TAppElkDraftEventQuestion>();
                        const metadata_2: thrift.TList = input.readListBegin();
                        const size_2: number = metadata_2.size;
                        for (let i_2: number = 0; i_2 < size_2; i_2++) {
                            const value_14: TAppElkDraftEventQuestion = TAppElkDraftEventQuestion.read(input);
                            value_13.push(value_14);
                        }
                        input.readListEnd();
                        _args.questions = value_13;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_15: Int64 = input.readI64();
                        _args.lastSavedTimestamp = value_15;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined) {
            return new TAppElkDraftEvent(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkDraftEvent from input");
        }
    }
}
export interface ITAppElkEventArgs {
    id: string;
    organizerUuid: string;
    details: TAppElkEventDetails;
    theme: TAppElkEventTheme;
    metadata: TAppElkEventMetadata;
    attendees: Array<TAppElkInvitee>;
    url?: string;
    questions?: Array<TAppElkEventQuestion>;
}
export class TAppElkEvent {
    public id: string;
    public organizerUuid: string;
    public details: TAppElkEventDetails;
    public theme: TAppElkEventTheme;
    public metadata: TAppElkEventMetadata;
    public attendees: Array<TAppElkInvitee>;
    public url?: string;
    public questions?: Array<TAppElkEventQuestion>;
    constructor(args: ITAppElkEventArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
        if (args != null && args.organizerUuid != null) {
            this.organizerUuid = args.organizerUuid;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[organizerUuid] is unset!");
        }
        if (args != null && args.details != null) {
            this.details = args.details;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[details] is unset!");
        }
        if (args != null && args.theme != null) {
            this.theme = args.theme;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[theme] is unset!");
        }
        if (args != null && args.metadata != null) {
            this.metadata = args.metadata;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[metadata] is unset!");
        }
        if (args != null && args.attendees != null) {
            this.attendees = args.attendees;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[attendees] is unset!");
        }
        if (args != null && args.url != null) {
            this.url = args.url;
        }
        if (args != null && args.questions != null) {
            this.questions = args.questions;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkEvent");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        if (this.organizerUuid != null) {
            output.writeFieldBegin("organizerUuid", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.organizerUuid);
            output.writeFieldEnd();
        }
        if (this.details != null) {
            output.writeFieldBegin("details", thrift.Thrift.Type.STRUCT, 3);
            this.details.write(output);
            output.writeFieldEnd();
        }
        if (this.theme != null) {
            output.writeFieldBegin("theme", thrift.Thrift.Type.STRUCT, 4);
            this.theme.write(output);
            output.writeFieldEnd();
        }
        if (this.metadata != null) {
            output.writeFieldBegin("metadata", thrift.Thrift.Type.STRUCT, 5);
            this.metadata.write(output);
            output.writeFieldEnd();
        }
        if (this.attendees != null) {
            output.writeFieldBegin("attendees", thrift.Thrift.Type.LIST, 6);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.attendees.length);
            this.attendees.forEach((value_16: TAppElkInvitee): void => {
                value_16.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.url != null) {
            output.writeFieldBegin("url", thrift.Thrift.Type.STRING, 7);
            output.writeString(this.url);
            output.writeFieldEnd();
        }
        if (this.questions != null) {
            output.writeFieldBegin("questions", thrift.Thrift.Type.LIST, 8);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.questions.length);
            this.questions.forEach((value_17: TAppElkEventQuestion): void => {
                value_17.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkEvent {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_18: string = input.readString();
                        _args.id = value_18;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_19: string = input.readString();
                        _args.organizerUuid = value_19;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_20: TAppElkEventDetails = TAppElkEventDetails.read(input);
                        _args.details = value_20;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_21: TAppElkEventTheme = TAppElkEventTheme.read(input);
                        _args.theme = value_21;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_22: TAppElkEventMetadata = TAppElkEventMetadata.read(input);
                        _args.metadata = value_22;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_23: Array<TAppElkInvitee> = new Array<TAppElkInvitee>();
                        const metadata_3: thrift.TList = input.readListBegin();
                        const size_3: number = metadata_3.size;
                        for (let i_3: number = 0; i_3 < size_3; i_3++) {
                            const value_24: TAppElkInvitee = TAppElkInvitee.read(input);
                            value_23.push(value_24);
                        }
                        input.readListEnd();
                        _args.attendees = value_23;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_25: string = input.readString();
                        _args.url = value_25;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_26: Array<TAppElkEventQuestion> = new Array<TAppElkEventQuestion>();
                        const metadata_4: thrift.TList = input.readListBegin();
                        const size_4: number = metadata_4.size;
                        for (let i_4: number = 0; i_4 < size_4; i_4++) {
                            const value_27: TAppElkEventQuestion = TAppElkEventQuestion.read(input);
                            value_26.push(value_27);
                        }
                        input.readListEnd();
                        _args.questions = value_26;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined && _args.organizerUuid !== undefined && _args.details !== undefined && _args.theme !== undefined && _args.metadata !== undefined && _args.attendees !== undefined) {
            return new TAppElkEvent(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkEvent from input");
        }
    }
}
export interface ITAppElkEventDetailsArgs {
    title: string;
    subtitle?: string;
    eventDescription?: string;
    startTime: number | Int64;
    timezone: string;
    endTime?: number | Int64;
    location: TAppElkLocation;
    hostedBy?: string;
    cancelledTimestamp?: number | Int64;
    playlistId?: string;
}
export class TAppElkEventDetails {
    public title: string;
    public subtitle?: string;
    public eventDescription?: string;
    public startTime: Int64;
    public timezone: string;
    public endTime?: Int64;
    public location: TAppElkLocation;
    public hostedBy?: string;
    public cancelledTimestamp?: Int64;
    public playlistId?: string;
    constructor(args: ITAppElkEventDetailsArgs) {
        if (args != null && args.title != null) {
            this.title = args.title;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[title] is unset!");
        }
        if (args != null && args.subtitle != null) {
            this.subtitle = args.subtitle;
        }
        if (args != null && args.eventDescription != null) {
            this.eventDescription = args.eventDescription;
        }
        if (args != null && args.startTime != null) {
            if (typeof args.startTime === "number") {
                this.startTime = new Int64(args.startTime);
            }
            else {
                this.startTime = args.startTime;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[startTime] is unset!");
        }
        if (args != null && args.timezone != null) {
            this.timezone = args.timezone;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[timezone] is unset!");
        }
        if (args != null && args.endTime != null) {
            if (typeof args.endTime === "number") {
                this.endTime = new Int64(args.endTime);
            }
            else {
                this.endTime = args.endTime;
            }
        }
        if (args != null && args.location != null) {
            this.location = args.location;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[location] is unset!");
        }
        if (args != null && args.hostedBy != null) {
            this.hostedBy = args.hostedBy;
        }
        if (args != null && args.cancelledTimestamp != null) {
            if (typeof args.cancelledTimestamp === "number") {
                this.cancelledTimestamp = new Int64(args.cancelledTimestamp);
            }
            else {
                this.cancelledTimestamp = args.cancelledTimestamp;
            }
        }
        if (args != null && args.playlistId != null) {
            this.playlistId = args.playlistId;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkEventDetails");
        if (this.title != null) {
            output.writeFieldBegin("title", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.title);
            output.writeFieldEnd();
        }
        if (this.subtitle != null) {
            output.writeFieldBegin("subtitle", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.subtitle);
            output.writeFieldEnd();
        }
        if (this.eventDescription != null) {
            output.writeFieldBegin("eventDescription", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.eventDescription);
            output.writeFieldEnd();
        }
        if (this.startTime != null) {
            output.writeFieldBegin("startTime", thrift.Thrift.Type.I64, 4);
            output.writeI64(this.startTime);
            output.writeFieldEnd();
        }
        if (this.timezone != null) {
            output.writeFieldBegin("timezone", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.timezone);
            output.writeFieldEnd();
        }
        if (this.endTime != null) {
            output.writeFieldBegin("endTime", thrift.Thrift.Type.I64, 6);
            output.writeI64(this.endTime);
            output.writeFieldEnd();
        }
        if (this.location != null) {
            output.writeFieldBegin("location", thrift.Thrift.Type.STRUCT, 7);
            this.location.write(output);
            output.writeFieldEnd();
        }
        if (this.hostedBy != null) {
            output.writeFieldBegin("hostedBy", thrift.Thrift.Type.STRING, 8);
            output.writeString(this.hostedBy);
            output.writeFieldEnd();
        }
        if (this.cancelledTimestamp != null) {
            output.writeFieldBegin("cancelledTimestamp", thrift.Thrift.Type.I64, 9);
            output.writeI64(this.cancelledTimestamp);
            output.writeFieldEnd();
        }
        if (this.playlistId != null) {
            output.writeFieldBegin("playlistId", thrift.Thrift.Type.STRING, 10);
            output.writeString(this.playlistId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkEventDetails {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_28: string = input.readString();
                        _args.title = value_28;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_29: string = input.readString();
                        _args.subtitle = value_29;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_30: string = input.readString();
                        _args.eventDescription = value_30;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_31: Int64 = input.readI64();
                        _args.startTime = value_31;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_32: string = input.readString();
                        _args.timezone = value_32;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_33: Int64 = input.readI64();
                        _args.endTime = value_33;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_34: TAppElkLocation = TAppElkLocation.read(input);
                        _args.location = value_34;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_35: string = input.readString();
                        _args.hostedBy = value_35;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_36: Int64 = input.readI64();
                        _args.cancelledTimestamp = value_36;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 10:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_37: string = input.readString();
                        _args.playlistId = value_37;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.title !== undefined && _args.startTime !== undefined && _args.timezone !== undefined && _args.location !== undefined) {
            return new TAppElkEventDetails(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkEventDetails from input");
        }
    }
}
export interface ITAppElkDraftEventDetailsArgs {
    title?: string;
    subtitle?: string;
    eventDescription?: string;
    startTime?: number | Int64;
    timezone?: string;
    endTime?: number | Int64;
    location?: TAppElkDraftLocation;
    hostedBy?: string;
    cancelledTimestamp?: number | Int64;
    playlistId?: string;
}
export class TAppElkDraftEventDetails {
    public title?: string;
    public subtitle?: string;
    public eventDescription?: string;
    public startTime?: Int64;
    public timezone?: string;
    public endTime?: Int64;
    public location?: TAppElkDraftLocation;
    public hostedBy?: string;
    public cancelledTimestamp?: Int64;
    public playlistId?: string;
    constructor(args?: ITAppElkDraftEventDetailsArgs) {
        if (args != null && args.title != null) {
            this.title = args.title;
        }
        if (args != null && args.subtitle != null) {
            this.subtitle = args.subtitle;
        }
        if (args != null && args.eventDescription != null) {
            this.eventDescription = args.eventDescription;
        }
        if (args != null && args.startTime != null) {
            if (typeof args.startTime === "number") {
                this.startTime = new Int64(args.startTime);
            }
            else {
                this.startTime = args.startTime;
            }
        }
        if (args != null && args.timezone != null) {
            this.timezone = args.timezone;
        }
        if (args != null && args.endTime != null) {
            if (typeof args.endTime === "number") {
                this.endTime = new Int64(args.endTime);
            }
            else {
                this.endTime = args.endTime;
            }
        }
        if (args != null && args.location != null) {
            this.location = args.location;
        }
        if (args != null && args.hostedBy != null) {
            this.hostedBy = args.hostedBy;
        }
        if (args != null && args.cancelledTimestamp != null) {
            if (typeof args.cancelledTimestamp === "number") {
                this.cancelledTimestamp = new Int64(args.cancelledTimestamp);
            }
            else {
                this.cancelledTimestamp = args.cancelledTimestamp;
            }
        }
        if (args != null && args.playlistId != null) {
            this.playlistId = args.playlistId;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkDraftEventDetails");
        if (this.title != null) {
            output.writeFieldBegin("title", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.title);
            output.writeFieldEnd();
        }
        if (this.subtitle != null) {
            output.writeFieldBegin("subtitle", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.subtitle);
            output.writeFieldEnd();
        }
        if (this.eventDescription != null) {
            output.writeFieldBegin("eventDescription", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.eventDescription);
            output.writeFieldEnd();
        }
        if (this.startTime != null) {
            output.writeFieldBegin("startTime", thrift.Thrift.Type.I64, 4);
            output.writeI64(this.startTime);
            output.writeFieldEnd();
        }
        if (this.timezone != null) {
            output.writeFieldBegin("timezone", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.timezone);
            output.writeFieldEnd();
        }
        if (this.endTime != null) {
            output.writeFieldBegin("endTime", thrift.Thrift.Type.I64, 6);
            output.writeI64(this.endTime);
            output.writeFieldEnd();
        }
        if (this.location != null) {
            output.writeFieldBegin("location", thrift.Thrift.Type.STRUCT, 7);
            this.location.write(output);
            output.writeFieldEnd();
        }
        if (this.hostedBy != null) {
            output.writeFieldBegin("hostedBy", thrift.Thrift.Type.STRING, 8);
            output.writeString(this.hostedBy);
            output.writeFieldEnd();
        }
        if (this.cancelledTimestamp != null) {
            output.writeFieldBegin("cancelledTimestamp", thrift.Thrift.Type.I64, 9);
            output.writeI64(this.cancelledTimestamp);
            output.writeFieldEnd();
        }
        if (this.playlistId != null) {
            output.writeFieldBegin("playlistId", thrift.Thrift.Type.STRING, 10);
            output.writeString(this.playlistId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkDraftEventDetails {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_38: string = input.readString();
                        _args.title = value_38;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_39: string = input.readString();
                        _args.subtitle = value_39;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_40: string = input.readString();
                        _args.eventDescription = value_40;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_41: Int64 = input.readI64();
                        _args.startTime = value_41;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_42: string = input.readString();
                        _args.timezone = value_42;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_43: Int64 = input.readI64();
                        _args.endTime = value_43;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_44: TAppElkDraftLocation = TAppElkDraftLocation.read(input);
                        _args.location = value_44;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_45: string = input.readString();
                        _args.hostedBy = value_45;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_46: Int64 = input.readI64();
                        _args.cancelledTimestamp = value_46;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 10:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_47: string = input.readString();
                        _args.playlistId = value_47;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TAppElkDraftEventDetails(_args);
    }
}
export interface ITAppElkEventThemeArgs {
    colors: Array<string>;
    photoUrl?: string;
    animation?: TAppElkBackgroundAnimation;
    backgroundAnimationSearchQuery?: string;
    backgroundAnimationUrl?: string;
    videoBackgroundDisabled?: boolean;
}
export class TAppElkEventTheme {
    public colors: Array<string>;
    public photoUrl?: string;
    public animation?: TAppElkBackgroundAnimation;
    public backgroundAnimationSearchQuery?: string;
    public backgroundAnimationUrl?: string;
    public videoBackgroundDisabled?: boolean;
    constructor(args: ITAppElkEventThemeArgs) {
        if (args != null && args.colors != null) {
            this.colors = args.colors;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[colors] is unset!");
        }
        if (args != null && args.photoUrl != null) {
            this.photoUrl = args.photoUrl;
        }
        if (args != null && args.animation != null) {
            this.animation = args.animation;
        }
        if (args != null && args.backgroundAnimationSearchQuery != null) {
            this.backgroundAnimationSearchQuery = args.backgroundAnimationSearchQuery;
        }
        if (args != null && args.backgroundAnimationUrl != null) {
            this.backgroundAnimationUrl = args.backgroundAnimationUrl;
        }
        if (args != null && args.videoBackgroundDisabled != null) {
            this.videoBackgroundDisabled = args.videoBackgroundDisabled;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkEventTheme");
        if (this.colors != null) {
            output.writeFieldBegin("colors", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.colors.length);
            this.colors.forEach((value_48: string): void => {
                output.writeString(value_48);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.photoUrl != null) {
            output.writeFieldBegin("photoUrl", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.photoUrl);
            output.writeFieldEnd();
        }
        if (this.animation != null) {
            output.writeFieldBegin("animation", thrift.Thrift.Type.I32, 3);
            output.writeI32(this.animation);
            output.writeFieldEnd();
        }
        if (this.backgroundAnimationSearchQuery != null) {
            output.writeFieldBegin("backgroundAnimationSearchQuery", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.backgroundAnimationSearchQuery);
            output.writeFieldEnd();
        }
        if (this.backgroundAnimationUrl != null) {
            output.writeFieldBegin("backgroundAnimationUrl", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.backgroundAnimationUrl);
            output.writeFieldEnd();
        }
        if (this.videoBackgroundDisabled != null) {
            output.writeFieldBegin("videoBackgroundDisabled", thrift.Thrift.Type.BOOL, 6);
            output.writeBool(this.videoBackgroundDisabled);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkEventTheme {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_49: Array<string> = new Array<string>();
                        const metadata_5: thrift.TList = input.readListBegin();
                        const size_5: number = metadata_5.size;
                        for (let i_5: number = 0; i_5 < size_5; i_5++) {
                            const value_50: string = input.readString();
                            value_49.push(value_50);
                        }
                        input.readListEnd();
                        _args.colors = value_49;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_51: string = input.readString();
                        _args.photoUrl = value_51;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_52: TAppElkBackgroundAnimation = input.readI32();
                        _args.animation = value_52;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_53: string = input.readString();
                        _args.backgroundAnimationSearchQuery = value_53;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_54: string = input.readString();
                        _args.backgroundAnimationUrl = value_54;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_55: boolean = input.readBool();
                        _args.videoBackgroundDisabled = value_55;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.colors !== undefined) {
            return new TAppElkEventTheme(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkEventTheme from input");
        }
    }
}
export interface ITAppElkDraftEventThemeArgs {
    colors?: Array<string>;
    photoUrl?: string;
    animation?: TAppElkBackgroundAnimation;
    backgroundAnimationSearchQuery?: string;
    backgroundAnimationUrl?: string;
    videoBackgroundDisabled?: boolean;
}
export class TAppElkDraftEventTheme {
    public colors?: Array<string>;
    public photoUrl?: string;
    public animation?: TAppElkBackgroundAnimation;
    public backgroundAnimationSearchQuery?: string;
    public backgroundAnimationUrl?: string;
    public videoBackgroundDisabled?: boolean;
    constructor(args?: ITAppElkDraftEventThemeArgs) {
        if (args != null && args.colors != null) {
            this.colors = args.colors;
        }
        if (args != null && args.photoUrl != null) {
            this.photoUrl = args.photoUrl;
        }
        if (args != null && args.animation != null) {
            this.animation = args.animation;
        }
        if (args != null && args.backgroundAnimationSearchQuery != null) {
            this.backgroundAnimationSearchQuery = args.backgroundAnimationSearchQuery;
        }
        if (args != null && args.backgroundAnimationUrl != null) {
            this.backgroundAnimationUrl = args.backgroundAnimationUrl;
        }
        if (args != null && args.videoBackgroundDisabled != null) {
            this.videoBackgroundDisabled = args.videoBackgroundDisabled;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkDraftEventTheme");
        if (this.colors != null) {
            output.writeFieldBegin("colors", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.colors.length);
            this.colors.forEach((value_56: string): void => {
                output.writeString(value_56);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.photoUrl != null) {
            output.writeFieldBegin("photoUrl", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.photoUrl);
            output.writeFieldEnd();
        }
        if (this.animation != null) {
            output.writeFieldBegin("animation", thrift.Thrift.Type.I32, 3);
            output.writeI32(this.animation);
            output.writeFieldEnd();
        }
        if (this.backgroundAnimationSearchQuery != null) {
            output.writeFieldBegin("backgroundAnimationSearchQuery", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.backgroundAnimationSearchQuery);
            output.writeFieldEnd();
        }
        if (this.backgroundAnimationUrl != null) {
            output.writeFieldBegin("backgroundAnimationUrl", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.backgroundAnimationUrl);
            output.writeFieldEnd();
        }
        if (this.videoBackgroundDisabled != null) {
            output.writeFieldBegin("videoBackgroundDisabled", thrift.Thrift.Type.BOOL, 6);
            output.writeBool(this.videoBackgroundDisabled);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkDraftEventTheme {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_57: Array<string> = new Array<string>();
                        const metadata_6: thrift.TList = input.readListBegin();
                        const size_6: number = metadata_6.size;
                        for (let i_6: number = 0; i_6 < size_6; i_6++) {
                            const value_58: string = input.readString();
                            value_57.push(value_58);
                        }
                        input.readListEnd();
                        _args.colors = value_57;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_59: string = input.readString();
                        _args.photoUrl = value_59;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_60: TAppElkBackgroundAnimation = input.readI32();
                        _args.animation = value_60;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_61: string = input.readString();
                        _args.backgroundAnimationSearchQuery = value_61;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_62: string = input.readString();
                        _args.backgroundAnimationUrl = value_62;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_63: boolean = input.readBool();
                        _args.videoBackgroundDisabled = value_63;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TAppElkDraftEventTheme(_args);
    }
}
export interface ITAppElkEventMetadataArgs {
    openInvite: boolean;
    publicGuestList: boolean;
    disableReminders: boolean;
}
export class TAppElkEventMetadata {
    public openInvite: boolean;
    public publicGuestList: boolean;
    public disableReminders: boolean;
    constructor(args: ITAppElkEventMetadataArgs) {
        if (args != null && args.openInvite != null) {
            this.openInvite = args.openInvite;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[openInvite] is unset!");
        }
        if (args != null && args.publicGuestList != null) {
            this.publicGuestList = args.publicGuestList;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[publicGuestList] is unset!");
        }
        if (args != null && args.disableReminders != null) {
            this.disableReminders = args.disableReminders;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[disableReminders] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkEventMetadata");
        if (this.openInvite != null) {
            output.writeFieldBegin("openInvite", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.openInvite);
            output.writeFieldEnd();
        }
        if (this.publicGuestList != null) {
            output.writeFieldBegin("publicGuestList", thrift.Thrift.Type.BOOL, 2);
            output.writeBool(this.publicGuestList);
            output.writeFieldEnd();
        }
        if (this.disableReminders != null) {
            output.writeFieldBegin("disableReminders", thrift.Thrift.Type.BOOL, 3);
            output.writeBool(this.disableReminders);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkEventMetadata {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_64: boolean = input.readBool();
                        _args.openInvite = value_64;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_65: boolean = input.readBool();
                        _args.publicGuestList = value_65;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_66: boolean = input.readBool();
                        _args.disableReminders = value_66;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.openInvite !== undefined && _args.publicGuestList !== undefined && _args.disableReminders !== undefined) {
            return new TAppElkEventMetadata(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkEventMetadata from input");
        }
    }
}
export interface ITAppElkDraftEventMetadataArgs {
    openInvite?: boolean;
    publicGuestList?: boolean;
    disableReminders?: boolean;
}
export class TAppElkDraftEventMetadata {
    public openInvite?: boolean;
    public publicGuestList?: boolean;
    public disableReminders?: boolean;
    constructor(args?: ITAppElkDraftEventMetadataArgs) {
        if (args != null && args.openInvite != null) {
            this.openInvite = args.openInvite;
        }
        if (args != null && args.publicGuestList != null) {
            this.publicGuestList = args.publicGuestList;
        }
        if (args != null && args.disableReminders != null) {
            this.disableReminders = args.disableReminders;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkDraftEventMetadata");
        if (this.openInvite != null) {
            output.writeFieldBegin("openInvite", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.openInvite);
            output.writeFieldEnd();
        }
        if (this.publicGuestList != null) {
            output.writeFieldBegin("publicGuestList", thrift.Thrift.Type.BOOL, 2);
            output.writeBool(this.publicGuestList);
            output.writeFieldEnd();
        }
        if (this.disableReminders != null) {
            output.writeFieldBegin("disableReminders", thrift.Thrift.Type.BOOL, 3);
            output.writeBool(this.disableReminders);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkDraftEventMetadata {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_67: boolean = input.readBool();
                        _args.openInvite = value_67;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_68: boolean = input.readBool();
                        _args.publicGuestList = value_68;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_69: boolean = input.readBool();
                        _args.disableReminders = value_69;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TAppElkDraftEventMetadata(_args);
    }
}
export interface ITAppElkEventQuestionArgs {
    question: string;
    isRequired: boolean;
    questionUuid: string;
    questionType: TAppElkEventQuestionType;
}
export class TAppElkEventQuestion {
    public question: string;
    public isRequired: boolean;
    public questionUuid: string;
    public questionType: TAppElkEventQuestionType = TAppElkEventQuestionType.TEXT;
    constructor(args: ITAppElkEventQuestionArgs) {
        if (args != null && args.question != null) {
            this.question = args.question;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[question] is unset!");
        }
        if (args != null && args.isRequired != null) {
            this.isRequired = args.isRequired;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[isRequired] is unset!");
        }
        if (args != null && args.questionUuid != null) {
            this.questionUuid = args.questionUuid;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[questionUuid] is unset!");
        }
        if (args != null && args.questionType != null) {
            this.questionType = args.questionType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[questionType] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkEventQuestion");
        if (this.question != null) {
            output.writeFieldBegin("question", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.question);
            output.writeFieldEnd();
        }
        if (this.isRequired != null) {
            output.writeFieldBegin("isRequired", thrift.Thrift.Type.BOOL, 2);
            output.writeBool(this.isRequired);
            output.writeFieldEnd();
        }
        if (this.questionUuid != null) {
            output.writeFieldBegin("questionUuid", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.questionUuid);
            output.writeFieldEnd();
        }
        if (this.questionType != null) {
            output.writeFieldBegin("questionType", thrift.Thrift.Type.I32, 4);
            output.writeI32(this.questionType);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkEventQuestion {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_70: string = input.readString();
                        _args.question = value_70;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_71: boolean = input.readBool();
                        _args.isRequired = value_71;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_72: string = input.readString();
                        _args.questionUuid = value_72;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_73: TAppElkEventQuestionType = input.readI32();
                        _args.questionType = value_73;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.question !== undefined && _args.isRequired !== undefined && _args.questionUuid !== undefined && _args.questionType !== undefined) {
            return new TAppElkEventQuestion(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkEventQuestion from input");
        }
    }
}
export interface ITAppElkDraftEventQuestionArgs {
    question?: string;
    isRequired?: boolean;
    questionUuid?: string;
    questionType?: TAppElkEventQuestionType;
}
export class TAppElkDraftEventQuestion {
    public question?: string;
    public isRequired?: boolean;
    public questionUuid?: string;
    public questionType?: TAppElkEventQuestionType = TAppElkEventQuestionType.TEXT;
    constructor(args?: ITAppElkDraftEventQuestionArgs) {
        if (args != null && args.question != null) {
            this.question = args.question;
        }
        if (args != null && args.isRequired != null) {
            this.isRequired = args.isRequired;
        }
        if (args != null && args.questionUuid != null) {
            this.questionUuid = args.questionUuid;
        }
        if (args != null && args.questionType != null) {
            this.questionType = args.questionType;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkDraftEventQuestion");
        if (this.question != null) {
            output.writeFieldBegin("question", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.question);
            output.writeFieldEnd();
        }
        if (this.isRequired != null) {
            output.writeFieldBegin("isRequired", thrift.Thrift.Type.BOOL, 2);
            output.writeBool(this.isRequired);
            output.writeFieldEnd();
        }
        if (this.questionUuid != null) {
            output.writeFieldBegin("questionUuid", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.questionUuid);
            output.writeFieldEnd();
        }
        if (this.questionType != null) {
            output.writeFieldBegin("questionType", thrift.Thrift.Type.I32, 4);
            output.writeI32(this.questionType);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkDraftEventQuestion {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_74: string = input.readString();
                        _args.question = value_74;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_75: boolean = input.readBool();
                        _args.isRequired = value_75;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_76: string = input.readString();
                        _args.questionUuid = value_76;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_77: TAppElkEventQuestionType = input.readI32();
                        _args.questionType = value_77;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TAppElkDraftEventQuestion(_args);
    }
}
export interface ITAppElkSpotifySongArgs {
    songUri: string;
    songName: string;
    artistName?: string;
    albumName?: string;
    imageUrl?: string;
}
export class TAppElkSpotifySong {
    public songUri: string;
    public songName: string;
    public artistName?: string;
    public albumName?: string;
    public imageUrl?: string;
    constructor(args: ITAppElkSpotifySongArgs) {
        if (args != null && args.songUri != null) {
            this.songUri = args.songUri;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[songUri] is unset!");
        }
        if (args != null && args.songName != null) {
            this.songName = args.songName;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[songName] is unset!");
        }
        if (args != null && args.artistName != null) {
            this.artistName = args.artistName;
        }
        if (args != null && args.albumName != null) {
            this.albumName = args.albumName;
        }
        if (args != null && args.imageUrl != null) {
            this.imageUrl = args.imageUrl;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkSpotifySong");
        if (this.songUri != null) {
            output.writeFieldBegin("songUri", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.songUri);
            output.writeFieldEnd();
        }
        if (this.songName != null) {
            output.writeFieldBegin("songName", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.songName);
            output.writeFieldEnd();
        }
        if (this.artistName != null) {
            output.writeFieldBegin("artistName", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.artistName);
            output.writeFieldEnd();
        }
        if (this.albumName != null) {
            output.writeFieldBegin("albumName", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.albumName);
            output.writeFieldEnd();
        }
        if (this.imageUrl != null) {
            output.writeFieldBegin("imageUrl", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.imageUrl);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkSpotifySong {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_78: string = input.readString();
                        _args.songUri = value_78;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_79: string = input.readString();
                        _args.songName = value_79;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_80: string = input.readString();
                        _args.artistName = value_80;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_81: string = input.readString();
                        _args.albumName = value_81;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_82: string = input.readString();
                        _args.imageUrl = value_82;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.songUri !== undefined && _args.songName !== undefined) {
            return new TAppElkSpotifySong(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkSpotifySong from input");
        }
    }
}
export interface ITAppElkEventAnswerArgs {
    questionUuid: string;
    answerValue: TAppElkEventAnswerValue;
    answer?: string;
    isSong?: boolean;
    songId?: string;
}
export class TAppElkEventAnswer {
    public questionUuid: string;
    public answerValue: TAppElkEventAnswerValue;
    public answer?: string;
    public isSong?: boolean = false;
    public songId?: string;
    constructor(args: ITAppElkEventAnswerArgs) {
        if (args != null && args.questionUuid != null) {
            this.questionUuid = args.questionUuid;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[questionUuid] is unset!");
        }
        if (args != null && args.answerValue != null) {
            this.answerValue = args.answerValue;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[answerValue] is unset!");
        }
        if (args != null && args.answer != null) {
            this.answer = args.answer;
        }
        if (args != null && args.isSong != null) {
            this.isSong = args.isSong;
        }
        if (args != null && args.songId != null) {
            this.songId = args.songId;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkEventAnswer");
        if (this.questionUuid != null) {
            output.writeFieldBegin("questionUuid", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.questionUuid);
            output.writeFieldEnd();
        }
        if (this.answerValue != null) {
            output.writeFieldBegin("answerValue", thrift.Thrift.Type.STRUCT, 5);
            this.answerValue.write(output);
            output.writeFieldEnd();
        }
        if (this.answer != null) {
            output.writeFieldBegin("answer", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.answer);
            output.writeFieldEnd();
        }
        if (this.isSong != null) {
            output.writeFieldBegin("isSong", thrift.Thrift.Type.BOOL, 3);
            output.writeBool(this.isSong);
            output.writeFieldEnd();
        }
        if (this.songId != null) {
            output.writeFieldBegin("songId", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.songId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkEventAnswer {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_83: string = input.readString();
                        _args.questionUuid = value_83;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_84: TAppElkEventAnswerValue = TAppElkEventAnswerValue.read(input);
                        _args.answerValue = value_84;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_85: string = input.readString();
                        _args.answer = value_85;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_86: boolean = input.readBool();
                        _args.isSong = value_86;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_87: string = input.readString();
                        _args.songId = value_87;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.questionUuid !== undefined && _args.answerValue !== undefined) {
            return new TAppElkEventAnswer(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkEventAnswer from input");
        }
    }
}
export interface ITAppElkInviteeArgs {
    inviteeId: string;
    name?: string;
    email?: string;
    phone?: string;
    photoUrl?: string;
    userId?: string;
    role: TAppElkAttendeeRole;
    inviteStatus?: TAppElkInviteStatus;
    rsvpStatus: TAppElkAttendeeStatus;
    rsvpMessage?: string;
    additionalGuestCount: number;
    notificationPreference?: TAppElkNotificationPreference;
    answers?: Array<TAppElkEventAnswer>;
    invitationUpdateTs?: number | Int64;
    isPhoneVerified?: boolean;
    isEmailVerified?: boolean;
}
export class TAppElkInvitee {
    public inviteeId: string;
    public name?: string;
    public email?: string;
    public phone?: string;
    public photoUrl?: string;
    public userId?: string;
    public role: TAppElkAttendeeRole;
    public inviteStatus?: TAppElkInviteStatus;
    public rsvpStatus: TAppElkAttendeeStatus;
    public rsvpMessage?: string;
    public additionalGuestCount: number;
    public notificationPreference?: TAppElkNotificationPreference;
    public answers?: Array<TAppElkEventAnswer>;
    public invitationUpdateTs?: Int64;
    public isPhoneVerified?: boolean;
    public isEmailVerified?: boolean;
    constructor(args: ITAppElkInviteeArgs) {
        if (args != null && args.inviteeId != null) {
            this.inviteeId = args.inviteeId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[inviteeId] is unset!");
        }
        if (args != null && args.name != null) {
            this.name = args.name;
        }
        if (args != null && args.email != null) {
            this.email = args.email;
        }
        if (args != null && args.phone != null) {
            this.phone = args.phone;
        }
        if (args != null && args.photoUrl != null) {
            this.photoUrl = args.photoUrl;
        }
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        if (args != null && args.role != null) {
            this.role = args.role;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[role] is unset!");
        }
        if (args != null && args.inviteStatus != null) {
            this.inviteStatus = args.inviteStatus;
        }
        if (args != null && args.rsvpStatus != null) {
            this.rsvpStatus = args.rsvpStatus;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[rsvpStatus] is unset!");
        }
        if (args != null && args.rsvpMessage != null) {
            this.rsvpMessage = args.rsvpMessage;
        }
        if (args != null && args.additionalGuestCount != null) {
            this.additionalGuestCount = args.additionalGuestCount;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[additionalGuestCount] is unset!");
        }
        if (args != null && args.notificationPreference != null) {
            this.notificationPreference = args.notificationPreference;
        }
        if (args != null && args.answers != null) {
            this.answers = args.answers;
        }
        if (args != null && args.invitationUpdateTs != null) {
            if (typeof args.invitationUpdateTs === "number") {
                this.invitationUpdateTs = new Int64(args.invitationUpdateTs);
            }
            else {
                this.invitationUpdateTs = args.invitationUpdateTs;
            }
        }
        if (args != null && args.isPhoneVerified != null) {
            this.isPhoneVerified = args.isPhoneVerified;
        }
        if (args != null && args.isEmailVerified != null) {
            this.isEmailVerified = args.isEmailVerified;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkInvitee");
        if (this.inviteeId != null) {
            output.writeFieldBegin("inviteeId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.inviteeId);
            output.writeFieldEnd();
        }
        if (this.name != null) {
            output.writeFieldBegin("name", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.name);
            output.writeFieldEnd();
        }
        if (this.email != null) {
            output.writeFieldBegin("email", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.email);
            output.writeFieldEnd();
        }
        if (this.phone != null) {
            output.writeFieldBegin("phone", thrift.Thrift.Type.STRING, 14);
            output.writeString(this.phone);
            output.writeFieldEnd();
        }
        if (this.photoUrl != null) {
            output.writeFieldBegin("photoUrl", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.photoUrl);
            output.writeFieldEnd();
        }
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        if (this.role != null) {
            output.writeFieldBegin("role", thrift.Thrift.Type.I32, 6);
            output.writeI32(this.role);
            output.writeFieldEnd();
        }
        if (this.inviteStatus != null) {
            output.writeFieldBegin("inviteStatus", thrift.Thrift.Type.I32, 7);
            output.writeI32(this.inviteStatus);
            output.writeFieldEnd();
        }
        if (this.rsvpStatus != null) {
            output.writeFieldBegin("rsvpStatus", thrift.Thrift.Type.I32, 8);
            output.writeI32(this.rsvpStatus);
            output.writeFieldEnd();
        }
        if (this.rsvpMessage != null) {
            output.writeFieldBegin("rsvpMessage", thrift.Thrift.Type.STRING, 9);
            output.writeString(this.rsvpMessage);
            output.writeFieldEnd();
        }
        if (this.additionalGuestCount != null) {
            output.writeFieldBegin("additionalGuestCount", thrift.Thrift.Type.I16, 10);
            output.writeI16(this.additionalGuestCount);
            output.writeFieldEnd();
        }
        if (this.notificationPreference != null) {
            output.writeFieldBegin("notificationPreference", thrift.Thrift.Type.I32, 11);
            output.writeI32(this.notificationPreference);
            output.writeFieldEnd();
        }
        if (this.answers != null) {
            output.writeFieldBegin("answers", thrift.Thrift.Type.LIST, 12);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.answers.length);
            this.answers.forEach((value_88: TAppElkEventAnswer): void => {
                value_88.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.invitationUpdateTs != null) {
            output.writeFieldBegin("invitationUpdateTs", thrift.Thrift.Type.I64, 13);
            output.writeI64(this.invitationUpdateTs);
            output.writeFieldEnd();
        }
        if (this.isPhoneVerified != null) {
            output.writeFieldBegin("isPhoneVerified", thrift.Thrift.Type.BOOL, 15);
            output.writeBool(this.isPhoneVerified);
            output.writeFieldEnd();
        }
        if (this.isEmailVerified != null) {
            output.writeFieldBegin("isEmailVerified", thrift.Thrift.Type.BOOL, 16);
            output.writeBool(this.isEmailVerified);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkInvitee {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_89: string = input.readString();
                        _args.inviteeId = value_89;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_90: string = input.readString();
                        _args.name = value_90;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_91: string = input.readString();
                        _args.email = value_91;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 14:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_92: string = input.readString();
                        _args.phone = value_92;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_93: string = input.readString();
                        _args.photoUrl = value_93;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_94: string = input.readString();
                        _args.userId = value_94;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_95: TAppElkAttendeeRole = input.readI32();
                        _args.role = value_95;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_96: TAppElkInviteStatus = input.readI32();
                        _args.inviteStatus = value_96;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_97: TAppElkAttendeeStatus = input.readI32();
                        _args.rsvpStatus = value_97;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_98: string = input.readString();
                        _args.rsvpMessage = value_98;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 10:
                    if (fieldType === thrift.Thrift.Type.I16) {
                        const value_99: number = input.readI16();
                        _args.additionalGuestCount = value_99;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 11:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_100: TAppElkNotificationPreference = input.readI32();
                        _args.notificationPreference = value_100;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 12:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_101: Array<TAppElkEventAnswer> = new Array<TAppElkEventAnswer>();
                        const metadata_7: thrift.TList = input.readListBegin();
                        const size_7: number = metadata_7.size;
                        for (let i_7: number = 0; i_7 < size_7; i_7++) {
                            const value_102: TAppElkEventAnswer = TAppElkEventAnswer.read(input);
                            value_101.push(value_102);
                        }
                        input.readListEnd();
                        _args.answers = value_101;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 13:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_103: Int64 = input.readI64();
                        _args.invitationUpdateTs = value_103;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 15:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_104: boolean = input.readBool();
                        _args.isPhoneVerified = value_104;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 16:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_105: boolean = input.readBool();
                        _args.isEmailVerified = value_105;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.inviteeId !== undefined && _args.role !== undefined && _args.rsvpStatus !== undefined && _args.additionalGuestCount !== undefined) {
            return new TAppElkInvitee(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkInvitee from input");
        }
    }
}
export interface ITAppElkDraftInviteeArgs {
    inviteeId?: string;
    name?: string;
    email?: string;
    photoUrl?: string;
    userId?: string;
    role?: TAppElkAttendeeRole;
}
export class TAppElkDraftInvitee {
    public inviteeId?: string;
    public name?: string;
    public email?: string;
    public photoUrl?: string;
    public userId?: string;
    public role?: TAppElkAttendeeRole;
    constructor(args?: ITAppElkDraftInviteeArgs) {
        if (args != null && args.inviteeId != null) {
            this.inviteeId = args.inviteeId;
        }
        if (args != null && args.name != null) {
            this.name = args.name;
        }
        if (args != null && args.email != null) {
            this.email = args.email;
        }
        if (args != null && args.photoUrl != null) {
            this.photoUrl = args.photoUrl;
        }
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        if (args != null && args.role != null) {
            this.role = args.role;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkDraftInvitee");
        if (this.inviteeId != null) {
            output.writeFieldBegin("inviteeId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.inviteeId);
            output.writeFieldEnd();
        }
        if (this.name != null) {
            output.writeFieldBegin("name", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.name);
            output.writeFieldEnd();
        }
        if (this.email != null) {
            output.writeFieldBegin("email", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.email);
            output.writeFieldEnd();
        }
        if (this.photoUrl != null) {
            output.writeFieldBegin("photoUrl", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.photoUrl);
            output.writeFieldEnd();
        }
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        if (this.role != null) {
            output.writeFieldBegin("role", thrift.Thrift.Type.I32, 6);
            output.writeI32(this.role);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkDraftInvitee {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_106: string = input.readString();
                        _args.inviteeId = value_106;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_107: string = input.readString();
                        _args.name = value_107;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_108: string = input.readString();
                        _args.email = value_108;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_109: string = input.readString();
                        _args.photoUrl = value_109;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_110: string = input.readString();
                        _args.userId = value_110;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_111: TAppElkAttendeeRole = input.readI32();
                        _args.role = value_111;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TAppElkDraftInvitee(_args);
    }
}
export interface ITAppElkUserArgs {
    userId: string;
    name: string;
    phoneNumber: string;
    photoUrl?: string;
    grantedTextPermission: boolean;
    textPermissionStatus?: TAppElkTextPermissionStatus;
    emails?: Array<string>;
}
export class TAppElkUser {
    public userId: string;
    public name: string;
    public phoneNumber: string;
    public photoUrl?: string;
    public grantedTextPermission: boolean;
    public textPermissionStatus?: TAppElkTextPermissionStatus;
    public emails?: Array<string>;
    constructor(args: ITAppElkUserArgs) {
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[userId] is unset!");
        }
        if (args != null && args.name != null) {
            this.name = args.name;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[name] is unset!");
        }
        if (args != null && args.phoneNumber != null) {
            this.phoneNumber = args.phoneNumber;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[phoneNumber] is unset!");
        }
        if (args != null && args.photoUrl != null) {
            this.photoUrl = args.photoUrl;
        }
        if (args != null && args.grantedTextPermission != null) {
            this.grantedTextPermission = args.grantedTextPermission;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[grantedTextPermission] is unset!");
        }
        if (args != null && args.textPermissionStatus != null) {
            this.textPermissionStatus = args.textPermissionStatus;
        }
        if (args != null && args.emails != null) {
            this.emails = args.emails;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkUser");
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        if (this.name != null) {
            output.writeFieldBegin("name", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.name);
            output.writeFieldEnd();
        }
        if (this.phoneNumber != null) {
            output.writeFieldBegin("phoneNumber", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.phoneNumber);
            output.writeFieldEnd();
        }
        if (this.photoUrl != null) {
            output.writeFieldBegin("photoUrl", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.photoUrl);
            output.writeFieldEnd();
        }
        if (this.grantedTextPermission != null) {
            output.writeFieldBegin("grantedTextPermission", thrift.Thrift.Type.BOOL, 5);
            output.writeBool(this.grantedTextPermission);
            output.writeFieldEnd();
        }
        if (this.textPermissionStatus != null) {
            output.writeFieldBegin("textPermissionStatus", thrift.Thrift.Type.I32, 6);
            output.writeI32(this.textPermissionStatus);
            output.writeFieldEnd();
        }
        if (this.emails != null) {
            output.writeFieldBegin("emails", thrift.Thrift.Type.LIST, 7);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.emails.length);
            this.emails.forEach((value_112: string): void => {
                output.writeString(value_112);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkUser {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_113: string = input.readString();
                        _args.userId = value_113;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_114: string = input.readString();
                        _args.name = value_114;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_115: string = input.readString();
                        _args.phoneNumber = value_115;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_116: string = input.readString();
                        _args.photoUrl = value_116;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_117: boolean = input.readBool();
                        _args.grantedTextPermission = value_117;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_118: TAppElkTextPermissionStatus = input.readI32();
                        _args.textPermissionStatus = value_118;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_119: Array<string> = new Array<string>();
                        const metadata_8: thrift.TList = input.readListBegin();
                        const size_8: number = metadata_8.size;
                        for (let i_8: number = 0; i_8 < size_8; i_8++) {
                            const value_120: string = input.readString();
                            value_119.push(value_120);
                        }
                        input.readListEnd();
                        _args.emails = value_119;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.userId !== undefined && _args.name !== undefined && _args.phoneNumber !== undefined && _args.grantedTextPermission !== undefined) {
            return new TAppElkUser(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkUser from input");
        }
    }
}
export interface ITAppElkProfileArgs {
    userId: string;
    name: string;
    photoUrl?: string;
    grantedTextPermission: boolean;
    textPermissionStatus?: TAppElkTextPermissionStatus;
}
export class TAppElkProfile {
    public userId: string;
    public name: string;
    public photoUrl?: string;
    public grantedTextPermission: boolean;
    public textPermissionStatus?: TAppElkTextPermissionStatus;
    constructor(args: ITAppElkProfileArgs) {
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[userId] is unset!");
        }
        if (args != null && args.name != null) {
            this.name = args.name;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[name] is unset!");
        }
        if (args != null && args.photoUrl != null) {
            this.photoUrl = args.photoUrl;
        }
        if (args != null && args.grantedTextPermission != null) {
            this.grantedTextPermission = args.grantedTextPermission;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[grantedTextPermission] is unset!");
        }
        if (args != null && args.textPermissionStatus != null) {
            this.textPermissionStatus = args.textPermissionStatus;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkProfile");
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        if (this.name != null) {
            output.writeFieldBegin("name", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.name);
            output.writeFieldEnd();
        }
        if (this.photoUrl != null) {
            output.writeFieldBegin("photoUrl", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.photoUrl);
            output.writeFieldEnd();
        }
        if (this.grantedTextPermission != null) {
            output.writeFieldBegin("grantedTextPermission", thrift.Thrift.Type.BOOL, 4);
            output.writeBool(this.grantedTextPermission);
            output.writeFieldEnd();
        }
        if (this.textPermissionStatus != null) {
            output.writeFieldBegin("textPermissionStatus", thrift.Thrift.Type.I32, 5);
            output.writeI32(this.textPermissionStatus);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkProfile {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_121: string = input.readString();
                        _args.userId = value_121;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_122: string = input.readString();
                        _args.name = value_122;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_123: string = input.readString();
                        _args.photoUrl = value_123;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_124: boolean = input.readBool();
                        _args.grantedTextPermission = value_124;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_125: TAppElkTextPermissionStatus = input.readI32();
                        _args.textPermissionStatus = value_125;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.userId !== undefined && _args.name !== undefined && _args.grantedTextPermission !== undefined) {
            return new TAppElkProfile(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkProfile from input");
        }
    }
}
export interface ITAppElkLocationArgs {
    displayName: string;
    placeName?: string;
    address?: PContact.TAppAddress;
}
export class TAppElkLocation {
    public displayName: string;
    public placeName?: string;
    public address?: PContact.TAppAddress;
    constructor(args: ITAppElkLocationArgs) {
        if (args != null && args.displayName != null) {
            this.displayName = args.displayName;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[displayName] is unset!");
        }
        if (args != null && args.placeName != null) {
            this.placeName = args.placeName;
        }
        if (args != null && args.address != null) {
            this.address = args.address;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkLocation");
        if (this.displayName != null) {
            output.writeFieldBegin("displayName", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.displayName);
            output.writeFieldEnd();
        }
        if (this.placeName != null) {
            output.writeFieldBegin("placeName", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.placeName);
            output.writeFieldEnd();
        }
        if (this.address != null) {
            output.writeFieldBegin("address", thrift.Thrift.Type.STRUCT, 2);
            this.address.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkLocation {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_126: string = input.readString();
                        _args.displayName = value_126;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_127: string = input.readString();
                        _args.placeName = value_127;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_128: PContact.TAppAddress = PContact.TAppAddress.read(input);
                        _args.address = value_128;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.displayName !== undefined) {
            return new TAppElkLocation(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkLocation from input");
        }
    }
}
export interface ITAppElkDraftLocationArgs {
    displayName?: string;
    placeName?: string;
    address?: PContact.TAppAddress;
}
export class TAppElkDraftLocation {
    public displayName?: string;
    public placeName?: string;
    public address?: PContact.TAppAddress;
    constructor(args?: ITAppElkDraftLocationArgs) {
        if (args != null && args.displayName != null) {
            this.displayName = args.displayName;
        }
        if (args != null && args.placeName != null) {
            this.placeName = args.placeName;
        }
        if (args != null && args.address != null) {
            this.address = args.address;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkDraftLocation");
        if (this.displayName != null) {
            output.writeFieldBegin("displayName", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.displayName);
            output.writeFieldEnd();
        }
        if (this.placeName != null) {
            output.writeFieldBegin("placeName", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.placeName);
            output.writeFieldEnd();
        }
        if (this.address != null) {
            output.writeFieldBegin("address", thrift.Thrift.Type.STRUCT, 2);
            this.address.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkDraftLocation {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_129: string = input.readString();
                        _args.displayName = value_129;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_130: string = input.readString();
                        _args.placeName = value_130;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_131: PContact.TAppAddress = PContact.TAppAddress.read(input);
                        _args.address = value_131;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TAppElkDraftLocation(_args);
    }
}
export interface ITAppElkMessageArgs {
    message: string;
    sentTime: number | Int64;
    senderProfile: TAppElkProfile;
    textBlast: boolean;
    receiverInviteeUuid?: string;
}
export class TAppElkMessage {
    public message: string;
    public sentTime: Int64;
    public senderProfile: TAppElkProfile;
    public textBlast: boolean;
    public receiverInviteeUuid?: string;
    constructor(args: ITAppElkMessageArgs) {
        if (args != null && args.message != null) {
            this.message = args.message;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[message] is unset!");
        }
        if (args != null && args.sentTime != null) {
            if (typeof args.sentTime === "number") {
                this.sentTime = new Int64(args.sentTime);
            }
            else {
                this.sentTime = args.sentTime;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[sentTime] is unset!");
        }
        if (args != null && args.senderProfile != null) {
            this.senderProfile = args.senderProfile;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[senderProfile] is unset!");
        }
        if (args != null && args.textBlast != null) {
            this.textBlast = args.textBlast;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[textBlast] is unset!");
        }
        if (args != null && args.receiverInviteeUuid != null) {
            this.receiverInviteeUuid = args.receiverInviteeUuid;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkMessage");
        if (this.message != null) {
            output.writeFieldBegin("message", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.message);
            output.writeFieldEnd();
        }
        if (this.sentTime != null) {
            output.writeFieldBegin("sentTime", thrift.Thrift.Type.I64, 2);
            output.writeI64(this.sentTime);
            output.writeFieldEnd();
        }
        if (this.senderProfile != null) {
            output.writeFieldBegin("senderProfile", thrift.Thrift.Type.STRUCT, 3);
            this.senderProfile.write(output);
            output.writeFieldEnd();
        }
        if (this.textBlast != null) {
            output.writeFieldBegin("textBlast", thrift.Thrift.Type.BOOL, 4);
            output.writeBool(this.textBlast);
            output.writeFieldEnd();
        }
        if (this.receiverInviteeUuid != null) {
            output.writeFieldBegin("receiverInviteeUuid", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.receiverInviteeUuid);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkMessage {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_132: string = input.readString();
                        _args.message = value_132;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_133: Int64 = input.readI64();
                        _args.sentTime = value_133;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_134: TAppElkProfile = TAppElkProfile.read(input);
                        _args.senderProfile = value_134;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_135: boolean = input.readBool();
                        _args.textBlast = value_135;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_136: string = input.readString();
                        _args.receiverInviteeUuid = value_136;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.message !== undefined && _args.sentTime !== undefined && _args.senderProfile !== undefined && _args.textBlast !== undefined) {
            return new TAppElkMessage(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkMessage from input");
        }
    }
}
export interface ITAppElkImageArgs {
    imageUrl: string;
    prompt?: string;
    eventUuid?: string;
}
export class TAppElkImage {
    public imageUrl: string;
    public prompt?: string;
    public eventUuid?: string;
    constructor(args: ITAppElkImageArgs) {
        if (args != null && args.imageUrl != null) {
            this.imageUrl = args.imageUrl;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[imageUrl] is unset!");
        }
        if (args != null && args.prompt != null) {
            this.prompt = args.prompt;
        }
        if (args != null && args.eventUuid != null) {
            this.eventUuid = args.eventUuid;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkImage");
        if (this.imageUrl != null) {
            output.writeFieldBegin("imageUrl", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.imageUrl);
            output.writeFieldEnd();
        }
        if (this.prompt != null) {
            output.writeFieldBegin("prompt", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.prompt);
            output.writeFieldEnd();
        }
        if (this.eventUuid != null) {
            output.writeFieldBegin("eventUuid", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.eventUuid);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkImage {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_137: string = input.readString();
                        _args.imageUrl = value_137;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_138: string = input.readString();
                        _args.prompt = value_138;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_139: string = input.readString();
                        _args.eventUuid = value_139;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.imageUrl !== undefined) {
            return new TAppElkImage(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkImage from input");
        }
    }
}
export interface ITAppElkPhotoMetaArgs {
    userId: string;
    photoId: string;
    filename: string;
    photoTs?: number | Int64;
    numFaces?: number;
    fullsizeUrl?: string;
    thumbnailUrl?: string;
    exif?: string;
    exifAux?: string;
    clusterId?: number | Int64;
    isKeyPhoto?: boolean;
    isInvitation?: boolean;
    offsetInSeconds?: number;
}
export class TAppElkPhotoMeta {
    public userId: string;
    public photoId: string;
    public filename: string;
    public photoTs?: Int64;
    public numFaces?: number;
    public fullsizeUrl?: string;
    public thumbnailUrl?: string;
    public exif?: string;
    public exifAux?: string;
    public clusterId?: Int64;
    public isKeyPhoto?: boolean;
    public isInvitation?: boolean;
    public offsetInSeconds?: number;
    constructor(args: ITAppElkPhotoMetaArgs) {
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[userId] is unset!");
        }
        if (args != null && args.photoId != null) {
            this.photoId = args.photoId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[photoId] is unset!");
        }
        if (args != null && args.filename != null) {
            this.filename = args.filename;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[filename] is unset!");
        }
        if (args != null && args.photoTs != null) {
            if (typeof args.photoTs === "number") {
                this.photoTs = new Int64(args.photoTs);
            }
            else {
                this.photoTs = args.photoTs;
            }
        }
        if (args != null && args.numFaces != null) {
            this.numFaces = args.numFaces;
        }
        if (args != null && args.fullsizeUrl != null) {
            this.fullsizeUrl = args.fullsizeUrl;
        }
        if (args != null && args.thumbnailUrl != null) {
            this.thumbnailUrl = args.thumbnailUrl;
        }
        if (args != null && args.exif != null) {
            this.exif = args.exif;
        }
        if (args != null && args.exifAux != null) {
            this.exifAux = args.exifAux;
        }
        if (args != null && args.clusterId != null) {
            if (typeof args.clusterId === "number") {
                this.clusterId = new Int64(args.clusterId);
            }
            else {
                this.clusterId = args.clusterId;
            }
        }
        if (args != null && args.isKeyPhoto != null) {
            this.isKeyPhoto = args.isKeyPhoto;
        }
        if (args != null && args.isInvitation != null) {
            this.isInvitation = args.isInvitation;
        }
        if (args != null && args.offsetInSeconds != null) {
            this.offsetInSeconds = args.offsetInSeconds;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkPhotoMeta");
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        if (this.photoId != null) {
            output.writeFieldBegin("photoId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.photoId);
            output.writeFieldEnd();
        }
        if (this.filename != null) {
            output.writeFieldBegin("filename", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.filename);
            output.writeFieldEnd();
        }
        if (this.photoTs != null) {
            output.writeFieldBegin("photoTs", thrift.Thrift.Type.I64, 4);
            output.writeI64(this.photoTs);
            output.writeFieldEnd();
        }
        if (this.numFaces != null) {
            output.writeFieldBegin("numFaces", thrift.Thrift.Type.I32, 5);
            output.writeI32(this.numFaces);
            output.writeFieldEnd();
        }
        if (this.fullsizeUrl != null) {
            output.writeFieldBegin("fullsizeUrl", thrift.Thrift.Type.STRING, 6);
            output.writeString(this.fullsizeUrl);
            output.writeFieldEnd();
        }
        if (this.thumbnailUrl != null) {
            output.writeFieldBegin("thumbnailUrl", thrift.Thrift.Type.STRING, 7);
            output.writeString(this.thumbnailUrl);
            output.writeFieldEnd();
        }
        if (this.exif != null) {
            output.writeFieldBegin("exif", thrift.Thrift.Type.STRING, 8);
            output.writeString(this.exif);
            output.writeFieldEnd();
        }
        if (this.exifAux != null) {
            output.writeFieldBegin("exifAux", thrift.Thrift.Type.STRING, 9);
            output.writeString(this.exifAux);
            output.writeFieldEnd();
        }
        if (this.clusterId != null) {
            output.writeFieldBegin("clusterId", thrift.Thrift.Type.I64, 11);
            output.writeI64(this.clusterId);
            output.writeFieldEnd();
        }
        if (this.isKeyPhoto != null) {
            output.writeFieldBegin("isKeyPhoto", thrift.Thrift.Type.BOOL, 12);
            output.writeBool(this.isKeyPhoto);
            output.writeFieldEnd();
        }
        if (this.isInvitation != null) {
            output.writeFieldBegin("isInvitation", thrift.Thrift.Type.BOOL, 13);
            output.writeBool(this.isInvitation);
            output.writeFieldEnd();
        }
        if (this.offsetInSeconds != null) {
            output.writeFieldBegin("offsetInSeconds", thrift.Thrift.Type.I32, 14);
            output.writeI32(this.offsetInSeconds);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkPhotoMeta {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_140: string = input.readString();
                        _args.userId = value_140;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_141: string = input.readString();
                        _args.photoId = value_141;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_142: string = input.readString();
                        _args.filename = value_142;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_143: Int64 = input.readI64();
                        _args.photoTs = value_143;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_144: number = input.readI32();
                        _args.numFaces = value_144;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_145: string = input.readString();
                        _args.fullsizeUrl = value_145;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_146: string = input.readString();
                        _args.thumbnailUrl = value_146;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_147: string = input.readString();
                        _args.exif = value_147;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_148: string = input.readString();
                        _args.exifAux = value_148;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 11:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_149: Int64 = input.readI64();
                        _args.clusterId = value_149;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 12:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_150: boolean = input.readBool();
                        _args.isKeyPhoto = value_150;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 13:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_151: boolean = input.readBool();
                        _args.isInvitation = value_151;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 14:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_152: number = input.readI32();
                        _args.offsetInSeconds = value_152;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.userId !== undefined && _args.photoId !== undefined && _args.filename !== undefined) {
            return new TAppElkPhotoMeta(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkPhotoMeta from input");
        }
    }
}
export interface ITElkCreateEventRequestArgs {
    event: TAppElkEvent;
    albumId?: string;
    photoUploadMode?: TAppElkPhotoUploadMode;
}
export class TElkCreateEventRequest {
    public event: TAppElkEvent;
    public albumId?: string;
    public photoUploadMode?: TAppElkPhotoUploadMode;
    constructor(args: ITElkCreateEventRequestArgs) {
        if (args != null && args.event != null) {
            this.event = args.event;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[event] is unset!");
        }
        if (args != null && args.albumId != null) {
            this.albumId = args.albumId;
        }
        if (args != null && args.photoUploadMode != null) {
            this.photoUploadMode = args.photoUploadMode;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkCreateEventRequest");
        if (this.event != null) {
            output.writeFieldBegin("event", thrift.Thrift.Type.STRUCT, 1);
            this.event.write(output);
            output.writeFieldEnd();
        }
        if (this.albumId != null) {
            output.writeFieldBegin("albumId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.albumId);
            output.writeFieldEnd();
        }
        if (this.photoUploadMode != null) {
            output.writeFieldBegin("photoUploadMode", thrift.Thrift.Type.I32, 3);
            output.writeI32(this.photoUploadMode);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkCreateEventRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_153: TAppElkEvent = TAppElkEvent.read(input);
                        _args.event = value_153;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_154: string = input.readString();
                        _args.albumId = value_154;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_155: TAppElkPhotoUploadMode = input.readI32();
                        _args.photoUploadMode = value_155;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.event !== undefined) {
            return new TElkCreateEventRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkCreateEventRequest from input");
        }
    }
}
export interface ITElkCreateEventDraftRequestArgs {
    event: TAppElkDraftEvent;
    albumId?: string;
    photoUploadMode?: TAppElkPhotoUploadMode;
}
export class TElkCreateEventDraftRequest {
    public event: TAppElkDraftEvent;
    public albumId?: string;
    public photoUploadMode?: TAppElkPhotoUploadMode;
    constructor(args: ITElkCreateEventDraftRequestArgs) {
        if (args != null && args.event != null) {
            this.event = args.event;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[event] is unset!");
        }
        if (args != null && args.albumId != null) {
            this.albumId = args.albumId;
        }
        if (args != null && args.photoUploadMode != null) {
            this.photoUploadMode = args.photoUploadMode;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkCreateEventDraftRequest");
        if (this.event != null) {
            output.writeFieldBegin("event", thrift.Thrift.Type.STRUCT, 1);
            this.event.write(output);
            output.writeFieldEnd();
        }
        if (this.albumId != null) {
            output.writeFieldBegin("albumId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.albumId);
            output.writeFieldEnd();
        }
        if (this.photoUploadMode != null) {
            output.writeFieldBegin("photoUploadMode", thrift.Thrift.Type.I32, 3);
            output.writeI32(this.photoUploadMode);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkCreateEventDraftRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_156: TAppElkDraftEvent = TAppElkDraftEvent.read(input);
                        _args.event = value_156;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_157: string = input.readString();
                        _args.albumId = value_157;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_158: TAppElkPhotoUploadMode = input.readI32();
                        _args.photoUploadMode = value_158;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.event !== undefined) {
            return new TElkCreateEventDraftRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkCreateEventDraftRequest from input");
        }
    }
}
export interface ITElkCreateEventDraftResponseArgs {
    event: TAppElkDraftEvent;
}
export class TElkCreateEventDraftResponse {
    public event: TAppElkDraftEvent;
    constructor(args: ITElkCreateEventDraftResponseArgs) {
        if (args != null && args.event != null) {
            this.event = args.event;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[event] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkCreateEventDraftResponse");
        if (this.event != null) {
            output.writeFieldBegin("event", thrift.Thrift.Type.STRUCT, 1);
            this.event.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkCreateEventDraftResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_159: TAppElkDraftEvent = TAppElkDraftEvent.read(input);
                        _args.event = value_159;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.event !== undefined) {
            return new TElkCreateEventDraftResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkCreateEventDraftResponse from input");
        }
    }
}
export interface ITElkCreateEventResponseArgs {
    event: TAppElkEvent;
}
export class TElkCreateEventResponse {
    public event: TAppElkEvent;
    constructor(args: ITElkCreateEventResponseArgs) {
        if (args != null && args.event != null) {
            this.event = args.event;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[event] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkCreateEventResponse");
        if (this.event != null) {
            output.writeFieldBegin("event", thrift.Thrift.Type.STRUCT, 1);
            this.event.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkCreateEventResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_160: TAppElkEvent = TAppElkEvent.read(input);
                        _args.event = value_160;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.event !== undefined) {
            return new TElkCreateEventResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkCreateEventResponse from input");
        }
    }
}
export interface ITElkCancelEventRequestArgs {
    eventId: string;
}
export class TElkCancelEventRequest {
    public eventId: string;
    constructor(args: ITElkCancelEventRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkCancelEventRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkCancelEventRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_161: string = input.readString();
                        _args.eventId = value_161;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined) {
            return new TElkCancelEventRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkCancelEventRequest from input");
        }
    }
}
export interface ITElkCancelEventResponseArgs {
}
export class TElkCancelEventResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkCancelEventResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkCancelEventResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkCancelEventResponse();
    }
}
export interface ITElkDeleteEventRequestArgs {
    eventId: string;
}
export class TElkDeleteEventRequest {
    public eventId: string;
    constructor(args: ITElkDeleteEventRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkDeleteEventRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkDeleteEventRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_162: string = input.readString();
                        _args.eventId = value_162;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined) {
            return new TElkDeleteEventRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkDeleteEventRequest from input");
        }
    }
}
export interface ITElkDeleteEventResponseArgs {
}
export class TElkDeleteEventResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkDeleteEventResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkDeleteEventResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkDeleteEventResponse();
    }
}
export interface ITElkEditEventDetailsRequestArgs {
    eventId: string;
    event: TAppElkEvent;
    disableNotification?: boolean;
    uploadMode?: TAppElkPhotoUploadMode;
}
export class TElkEditEventDetailsRequest {
    public eventId: string;
    public event: TAppElkEvent;
    public disableNotification?: boolean;
    public uploadMode?: TAppElkPhotoUploadMode;
    constructor(args: ITElkEditEventDetailsRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
        if (args != null && args.event != null) {
            this.event = args.event;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[event] is unset!");
        }
        if (args != null && args.disableNotification != null) {
            this.disableNotification = args.disableNotification;
        }
        if (args != null && args.uploadMode != null) {
            this.uploadMode = args.uploadMode;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkEditEventDetailsRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        if (this.event != null) {
            output.writeFieldBegin("event", thrift.Thrift.Type.STRUCT, 2);
            this.event.write(output);
            output.writeFieldEnd();
        }
        if (this.disableNotification != null) {
            output.writeFieldBegin("disableNotification", thrift.Thrift.Type.BOOL, 3);
            output.writeBool(this.disableNotification);
            output.writeFieldEnd();
        }
        if (this.uploadMode != null) {
            output.writeFieldBegin("uploadMode", thrift.Thrift.Type.I32, 4);
            output.writeI32(this.uploadMode);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkEditEventDetailsRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_163: string = input.readString();
                        _args.eventId = value_163;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_164: TAppElkEvent = TAppElkEvent.read(input);
                        _args.event = value_164;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_165: boolean = input.readBool();
                        _args.disableNotification = value_165;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_166: TAppElkPhotoUploadMode = input.readI32();
                        _args.uploadMode = value_166;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined && _args.event !== undefined) {
            return new TElkEditEventDetailsRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkEditEventDetailsRequest from input");
        }
    }
}
export interface ITElkEditEventDraftResponseArgs {
}
export class TElkEditEventDraftResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkEditEventDraftResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkEditEventDraftResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkEditEventDraftResponse();
    }
}
export interface ITElkEditEventDraftRequestArgs {
    eventId: string;
    event: TAppElkDraftEvent;
}
export class TElkEditEventDraftRequest {
    public eventId: string;
    public event: TAppElkDraftEvent;
    constructor(args: ITElkEditEventDraftRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
        if (args != null && args.event != null) {
            this.event = args.event;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[event] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkEditEventDraftRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        if (this.event != null) {
            output.writeFieldBegin("event", thrift.Thrift.Type.STRUCT, 2);
            this.event.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkEditEventDraftRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_167: string = input.readString();
                        _args.eventId = value_167;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_168: TAppElkDraftEvent = TAppElkDraftEvent.read(input);
                        _args.event = value_168;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined && _args.event !== undefined) {
            return new TElkEditEventDraftRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkEditEventDraftRequest from input");
        }
    }
}
export interface ITElkEditEventDetailsResponseArgs {
}
export class TElkEditEventDetailsResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkEditEventDetailsResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkEditEventDetailsResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkEditEventDetailsResponse();
    }
}
export interface ITElkGetEventRequestArgs {
    eventId: string;
    inviteeUuid?: string;
}
export class TElkGetEventRequest {
    public eventId: string;
    public inviteeUuid?: string;
    constructor(args: ITElkGetEventRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
        if (args != null && args.inviteeUuid != null) {
            this.inviteeUuid = args.inviteeUuid;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetEventRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        if (this.inviteeUuid != null) {
            output.writeFieldBegin("inviteeUuid", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.inviteeUuid);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetEventRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_169: string = input.readString();
                        _args.eventId = value_169;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_170: string = input.readString();
                        _args.inviteeUuid = value_170;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined) {
            return new TElkGetEventRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetEventRequest from input");
        }
    }
}
export interface ITElkGetEventResponseArgs {
    event: TAppElkEvent;
}
export class TElkGetEventResponse {
    public event: TAppElkEvent;
    constructor(args: ITElkGetEventResponseArgs) {
        if (args != null && args.event != null) {
            this.event = args.event;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[event] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetEventResponse");
        if (this.event != null) {
            output.writeFieldBegin("event", thrift.Thrift.Type.STRUCT, 1);
            this.event.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetEventResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_171: TAppElkEvent = TAppElkEvent.read(input);
                        _args.event = value_171;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.event !== undefined) {
            return new TElkGetEventResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetEventResponse from input");
        }
    }
}
export interface ITElkGetEventShortLinkRequestArgs {
    eventId: string;
}
export class TElkGetEventShortLinkRequest {
    public eventId: string;
    constructor(args: ITElkGetEventShortLinkRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetEventShortLinkRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetEventShortLinkRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_172: string = input.readString();
                        _args.eventId = value_172;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined) {
            return new TElkGetEventShortLinkRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetEventShortLinkRequest from input");
        }
    }
}
export interface ITElkGetEventShortLinkResponseArgs {
    eventUrl: string;
}
export class TElkGetEventShortLinkResponse {
    public eventUrl: string;
    constructor(args: ITElkGetEventShortLinkResponseArgs) {
        if (args != null && args.eventUrl != null) {
            this.eventUrl = args.eventUrl;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventUrl] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetEventShortLinkResponse");
        if (this.eventUrl != null) {
            output.writeFieldBegin("eventUrl", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventUrl);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetEventShortLinkResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_173: string = input.readString();
                        _args.eventUrl = value_173;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventUrl !== undefined) {
            return new TElkGetEventShortLinkResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetEventShortLinkResponse from input");
        }
    }
}
export interface ITElkGetEventPreviewRequestArgs {
    eventId: string;
    inviteeUuid?: string;
}
export class TElkGetEventPreviewRequest {
    public eventId: string;
    public inviteeUuid?: string;
    constructor(args: ITElkGetEventPreviewRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
        if (args != null && args.inviteeUuid != null) {
            this.inviteeUuid = args.inviteeUuid;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetEventPreviewRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        if (this.inviteeUuid != null) {
            output.writeFieldBegin("inviteeUuid", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.inviteeUuid);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetEventPreviewRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_174: string = input.readString();
                        _args.eventId = value_174;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_175: string = input.readString();
                        _args.inviteeUuid = value_175;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined) {
            return new TElkGetEventPreviewRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetEventPreviewRequest from input");
        }
    }
}
export interface ITElkGetEventPreviewResponseArgs {
    event: TAppElkEvent;
}
export class TElkGetEventPreviewResponse {
    public event: TAppElkEvent;
    constructor(args: ITElkGetEventPreviewResponseArgs) {
        if (args != null && args.event != null) {
            this.event = args.event;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[event] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetEventPreviewResponse");
        if (this.event != null) {
            output.writeFieldBegin("event", thrift.Thrift.Type.STRUCT, 1);
            this.event.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetEventPreviewResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_176: TAppElkEvent = TAppElkEvent.read(input);
                        _args.event = value_176;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.event !== undefined) {
            return new TElkGetEventPreviewResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetEventPreviewResponse from input");
        }
    }
}
export interface ITElkFetchEventsRequestArgs {
}
export class TElkFetchEventsRequest {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkFetchEventsRequest");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkFetchEventsRequest {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkFetchEventsRequest();
    }
}
export interface ITElkFetchEventsResponseArgs {
    events: Array<TAppElkEvent>;
}
export class TElkFetchEventsResponse {
    public events: Array<TAppElkEvent>;
    constructor(args: ITElkFetchEventsResponseArgs) {
        if (args != null && args.events != null) {
            this.events = args.events;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[events] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkFetchEventsResponse");
        if (this.events != null) {
            output.writeFieldBegin("events", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.events.length);
            this.events.forEach((value_177: TAppElkEvent): void => {
                value_177.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkFetchEventsResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_178: Array<TAppElkEvent> = new Array<TAppElkEvent>();
                        const metadata_9: thrift.TList = input.readListBegin();
                        const size_9: number = metadata_9.size;
                        for (let i_9: number = 0; i_9 < size_9; i_9++) {
                            const value_179: TAppElkEvent = TAppElkEvent.read(input);
                            value_178.push(value_179);
                        }
                        input.readListEnd();
                        _args.events = value_178;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.events !== undefined) {
            return new TElkFetchEventsResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkFetchEventsResponse from input");
        }
    }
}
export interface ITElkUpdateAttendanceRequestArgs {
    eventId: string;
    invitee: TAppElkInvitee;
    textPermissionStatusUpdate?: TAppElkTextPermissionStatus;
    token?: string;
    photoUploadMode?: TAppElkPhotoUploadMode;
}
export class TElkUpdateAttendanceRequest {
    public eventId: string;
    public invitee: TAppElkInvitee;
    public textPermissionStatusUpdate?: TAppElkTextPermissionStatus;
    public token?: string;
    public photoUploadMode?: TAppElkPhotoUploadMode;
    constructor(args: ITElkUpdateAttendanceRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
        if (args != null && args.invitee != null) {
            this.invitee = args.invitee;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[invitee] is unset!");
        }
        if (args != null && args.textPermissionStatusUpdate != null) {
            this.textPermissionStatusUpdate = args.textPermissionStatusUpdate;
        }
        if (args != null && args.token != null) {
            this.token = args.token;
        }
        if (args != null && args.photoUploadMode != null) {
            this.photoUploadMode = args.photoUploadMode;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkUpdateAttendanceRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        if (this.invitee != null) {
            output.writeFieldBegin("invitee", thrift.Thrift.Type.STRUCT, 2);
            this.invitee.write(output);
            output.writeFieldEnd();
        }
        if (this.textPermissionStatusUpdate != null) {
            output.writeFieldBegin("textPermissionStatusUpdate", thrift.Thrift.Type.I32, 3);
            output.writeI32(this.textPermissionStatusUpdate);
            output.writeFieldEnd();
        }
        if (this.token != null) {
            output.writeFieldBegin("token", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.token);
            output.writeFieldEnd();
        }
        if (this.photoUploadMode != null) {
            output.writeFieldBegin("photoUploadMode", thrift.Thrift.Type.I32, 5);
            output.writeI32(this.photoUploadMode);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkUpdateAttendanceRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_180: string = input.readString();
                        _args.eventId = value_180;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_181: TAppElkInvitee = TAppElkInvitee.read(input);
                        _args.invitee = value_181;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_182: TAppElkTextPermissionStatus = input.readI32();
                        _args.textPermissionStatusUpdate = value_182;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_183: string = input.readString();
                        _args.token = value_183;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_184: TAppElkPhotoUploadMode = input.readI32();
                        _args.photoUploadMode = value_184;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined && _args.invitee !== undefined) {
            return new TElkUpdateAttendanceRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkUpdateAttendanceRequest from input");
        }
    }
}
export interface ITElkUpdateAttendanceResponseArgs {
    shouldPromptLogin?: boolean;
    email?: string;
    phone?: string;
    inviteeId?: string;
}
export class TElkUpdateAttendanceResponse {
    public shouldPromptLogin?: boolean;
    public email?: string;
    public phone?: string;
    public inviteeId?: string;
    constructor(args?: ITElkUpdateAttendanceResponseArgs) {
        if (args != null && args.shouldPromptLogin != null) {
            this.shouldPromptLogin = args.shouldPromptLogin;
        }
        if (args != null && args.email != null) {
            this.email = args.email;
        }
        if (args != null && args.phone != null) {
            this.phone = args.phone;
        }
        if (args != null && args.inviteeId != null) {
            this.inviteeId = args.inviteeId;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkUpdateAttendanceResponse");
        if (this.shouldPromptLogin != null) {
            output.writeFieldBegin("shouldPromptLogin", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.shouldPromptLogin);
            output.writeFieldEnd();
        }
        if (this.email != null) {
            output.writeFieldBegin("email", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.email);
            output.writeFieldEnd();
        }
        if (this.phone != null) {
            output.writeFieldBegin("phone", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.phone);
            output.writeFieldEnd();
        }
        if (this.inviteeId != null) {
            output.writeFieldBegin("inviteeId", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.inviteeId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkUpdateAttendanceResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_185: boolean = input.readBool();
                        _args.shouldPromptLogin = value_185;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_186: string = input.readString();
                        _args.email = value_186;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_187: string = input.readString();
                        _args.phone = value_187;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_188: string = input.readString();
                        _args.inviteeId = value_188;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkUpdateAttendanceResponse(_args);
    }
}
export interface ITElkEditInviteeRequestArgs {
    eventId: string;
    invitee: TAppElkInvitee;
}
export class TElkEditInviteeRequest {
    public eventId: string;
    public invitee: TAppElkInvitee;
    constructor(args: ITElkEditInviteeRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
        if (args != null && args.invitee != null) {
            this.invitee = args.invitee;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[invitee] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkEditInviteeRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        if (this.invitee != null) {
            output.writeFieldBegin("invitee", thrift.Thrift.Type.STRUCT, 2);
            this.invitee.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkEditInviteeRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_189: string = input.readString();
                        _args.eventId = value_189;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_190: TAppElkInvitee = TAppElkInvitee.read(input);
                        _args.invitee = value_190;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined && _args.invitee !== undefined) {
            return new TElkEditInviteeRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkEditInviteeRequest from input");
        }
    }
}
export interface ITElkEditInviteeResponseArgs {
}
export class TElkEditInviteeResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkEditInviteeResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkEditInviteeResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkEditInviteeResponse();
    }
}
export interface ITElkGetUserRequestArgs {
    userId: string;
}
export class TElkGetUserRequest {
    public userId: string;
    constructor(args: ITElkGetUserRequestArgs) {
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[userId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetUserRequest");
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetUserRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_191: string = input.readString();
                        _args.userId = value_191;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.userId !== undefined) {
            return new TElkGetUserRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetUserRequest from input");
        }
    }
}
export interface ITElkGetUserResponseArgs {
    elkUser: TAppElkUser;
    defaultPhotoUploadMode?: TAppElkPhotoUploadMode;
}
export class TElkGetUserResponse {
    public elkUser: TAppElkUser;
    public defaultPhotoUploadMode?: TAppElkPhotoUploadMode;
    constructor(args: ITElkGetUserResponseArgs) {
        if (args != null && args.elkUser != null) {
            this.elkUser = args.elkUser;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[elkUser] is unset!");
        }
        if (args != null && args.defaultPhotoUploadMode != null) {
            this.defaultPhotoUploadMode = args.defaultPhotoUploadMode;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetUserResponse");
        if (this.elkUser != null) {
            output.writeFieldBegin("elkUser", thrift.Thrift.Type.STRUCT, 1);
            this.elkUser.write(output);
            output.writeFieldEnd();
        }
        if (this.defaultPhotoUploadMode != null) {
            output.writeFieldBegin("defaultPhotoUploadMode", thrift.Thrift.Type.I32, 2);
            output.writeI32(this.defaultPhotoUploadMode);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetUserResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_192: TAppElkUser = TAppElkUser.read(input);
                        _args.elkUser = value_192;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_193: TAppElkPhotoUploadMode = input.readI32();
                        _args.defaultPhotoUploadMode = value_193;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.elkUser !== undefined) {
            return new TElkGetUserResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetUserResponse from input");
        }
    }
}
export interface ITElkCreateUserRequestArgs {
    elkUser: TAppElkUser;
    coinsSpent?: number;
}
export class TElkCreateUserRequest {
    public elkUser: TAppElkUser;
    public coinsSpent?: number;
    constructor(args: ITElkCreateUserRequestArgs) {
        if (args != null && args.elkUser != null) {
            this.elkUser = args.elkUser;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[elkUser] is unset!");
        }
        if (args != null && args.coinsSpent != null) {
            this.coinsSpent = args.coinsSpent;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkCreateUserRequest");
        if (this.elkUser != null) {
            output.writeFieldBegin("elkUser", thrift.Thrift.Type.STRUCT, 1);
            this.elkUser.write(output);
            output.writeFieldEnd();
        }
        if (this.coinsSpent != null) {
            output.writeFieldBegin("coinsSpent", thrift.Thrift.Type.I32, 2);
            output.writeI32(this.coinsSpent);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkCreateUserRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_194: TAppElkUser = TAppElkUser.read(input);
                        _args.elkUser = value_194;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_195: number = input.readI32();
                        _args.coinsSpent = value_195;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.elkUser !== undefined) {
            return new TElkCreateUserRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkCreateUserRequest from input");
        }
    }
}
export interface ITElkCreateUserResponseArgs {
}
export class TElkCreateUserResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkCreateUserResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkCreateUserResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkCreateUserResponse();
    }
}
export interface ITElkUpdateUserRequestArgs {
    elkUser: TAppElkUser;
}
export class TElkUpdateUserRequest {
    public elkUser: TAppElkUser;
    constructor(args: ITElkUpdateUserRequestArgs) {
        if (args != null && args.elkUser != null) {
            this.elkUser = args.elkUser;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[elkUser] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkUpdateUserRequest");
        if (this.elkUser != null) {
            output.writeFieldBegin("elkUser", thrift.Thrift.Type.STRUCT, 1);
            this.elkUser.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkUpdateUserRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_196: TAppElkUser = TAppElkUser.read(input);
                        _args.elkUser = value_196;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.elkUser !== undefined) {
            return new TElkUpdateUserRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkUpdateUserRequest from input");
        }
    }
}
export interface ITElkUpdateUserResponseArgs {
}
export class TElkUpdateUserResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkUpdateUserResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkUpdateUserResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkUpdateUserResponse();
    }
}
export interface ITElkGetHasCompletedProfileRequestArgs {
    phoneNumber: string;
}
export class TElkGetHasCompletedProfileRequest {
    public phoneNumber: string;
    constructor(args: ITElkGetHasCompletedProfileRequestArgs) {
        if (args != null && args.phoneNumber != null) {
            this.phoneNumber = args.phoneNumber;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[phoneNumber] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetHasCompletedProfileRequest");
        if (this.phoneNumber != null) {
            output.writeFieldBegin("phoneNumber", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.phoneNumber);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetHasCompletedProfileRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_197: string = input.readString();
                        _args.phoneNumber = value_197;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.phoneNumber !== undefined) {
            return new TElkGetHasCompletedProfileRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetHasCompletedProfileRequest from input");
        }
    }
}
export interface ITElkGetHasCompletedProfileResponseArgs {
    existingElkProfile: boolean;
    askTextPermission?: boolean;
}
export class TElkGetHasCompletedProfileResponse {
    public existingElkProfile: boolean;
    public askTextPermission?: boolean;
    constructor(args: ITElkGetHasCompletedProfileResponseArgs) {
        if (args != null && args.existingElkProfile != null) {
            this.existingElkProfile = args.existingElkProfile;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[existingElkProfile] is unset!");
        }
        if (args != null && args.askTextPermission != null) {
            this.askTextPermission = args.askTextPermission;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetHasCompletedProfileResponse");
        if (this.existingElkProfile != null) {
            output.writeFieldBegin("existingElkProfile", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.existingElkProfile);
            output.writeFieldEnd();
        }
        if (this.askTextPermission != null) {
            output.writeFieldBegin("askTextPermission", thrift.Thrift.Type.BOOL, 2);
            output.writeBool(this.askTextPermission);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetHasCompletedProfileResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_198: boolean = input.readBool();
                        _args.existingElkProfile = value_198;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_199: boolean = input.readBool();
                        _args.askTextPermission = value_199;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.existingElkProfile !== undefined) {
            return new TElkGetHasCompletedProfileResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetHasCompletedProfileResponse from input");
        }
    }
}
export interface ITElkGetHasProfileRequestArgs {
}
export class TElkGetHasProfileRequest {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetHasProfileRequest");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetHasProfileRequest {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkGetHasProfileRequest();
    }
}
export interface ITElkGetHasProfileResponseArgs {
    existingElkProfile: boolean;
}
export class TElkGetHasProfileResponse {
    public existingElkProfile: boolean;
    constructor(args: ITElkGetHasProfileResponseArgs) {
        if (args != null && args.existingElkProfile != null) {
            this.existingElkProfile = args.existingElkProfile;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[existingElkProfile] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetHasProfileResponse");
        if (this.existingElkProfile != null) {
            output.writeFieldBegin("existingElkProfile", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.existingElkProfile);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetHasProfileResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_200: boolean = input.readBool();
                        _args.existingElkProfile = value_200;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.existingElkProfile !== undefined) {
            return new TElkGetHasProfileResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetHasProfileResponse from input");
        }
    }
}
export interface ITElkSendCommentRequestArgs {
    eventId: string;
    messageId: string;
    message: string;
    isTextBlast: boolean;
    sendToStatuses?: Array<TAppElkAttendeeStatus>;
    email?: string;
    receiverInviteeUuid?: string;
}
export class TElkSendCommentRequest {
    public eventId: string;
    public messageId: string;
    public message: string;
    public isTextBlast: boolean;
    public sendToStatuses?: Array<TAppElkAttendeeStatus>;
    public email?: string;
    public receiverInviteeUuid?: string;
    constructor(args: ITElkSendCommentRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
        if (args != null && args.messageId != null) {
            this.messageId = args.messageId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[messageId] is unset!");
        }
        if (args != null && args.message != null) {
            this.message = args.message;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[message] is unset!");
        }
        if (args != null && args.isTextBlast != null) {
            this.isTextBlast = args.isTextBlast;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[isTextBlast] is unset!");
        }
        if (args != null && args.sendToStatuses != null) {
            this.sendToStatuses = args.sendToStatuses;
        }
        if (args != null && args.email != null) {
            this.email = args.email;
        }
        if (args != null && args.receiverInviteeUuid != null) {
            this.receiverInviteeUuid = args.receiverInviteeUuid;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkSendCommentRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        if (this.messageId != null) {
            output.writeFieldBegin("messageId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.messageId);
            output.writeFieldEnd();
        }
        if (this.message != null) {
            output.writeFieldBegin("message", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.message);
            output.writeFieldEnd();
        }
        if (this.isTextBlast != null) {
            output.writeFieldBegin("isTextBlast", thrift.Thrift.Type.BOOL, 4);
            output.writeBool(this.isTextBlast);
            output.writeFieldEnd();
        }
        if (this.sendToStatuses != null) {
            output.writeFieldBegin("sendToStatuses", thrift.Thrift.Type.LIST, 5);
            output.writeListBegin(thrift.Thrift.Type.I32, this.sendToStatuses.length);
            this.sendToStatuses.forEach((value_201: TAppElkAttendeeStatus): void => {
                output.writeI32(value_201);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.email != null) {
            output.writeFieldBegin("email", thrift.Thrift.Type.STRING, 6);
            output.writeString(this.email);
            output.writeFieldEnd();
        }
        if (this.receiverInviteeUuid != null) {
            output.writeFieldBegin("receiverInviteeUuid", thrift.Thrift.Type.STRING, 7);
            output.writeString(this.receiverInviteeUuid);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkSendCommentRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_202: string = input.readString();
                        _args.eventId = value_202;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_203: string = input.readString();
                        _args.messageId = value_203;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_204: string = input.readString();
                        _args.message = value_204;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_205: boolean = input.readBool();
                        _args.isTextBlast = value_205;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_206: Array<TAppElkAttendeeStatus> = new Array<TAppElkAttendeeStatus>();
                        const metadata_10: thrift.TList = input.readListBegin();
                        const size_10: number = metadata_10.size;
                        for (let i_10: number = 0; i_10 < size_10; i_10++) {
                            const value_207: TAppElkAttendeeStatus = input.readI32();
                            value_206.push(value_207);
                        }
                        input.readListEnd();
                        _args.sendToStatuses = value_206;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_208: string = input.readString();
                        _args.email = value_208;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_209: string = input.readString();
                        _args.receiverInviteeUuid = value_209;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined && _args.messageId !== undefined && _args.message !== undefined && _args.isTextBlast !== undefined) {
            return new TElkSendCommentRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkSendCommentRequest from input");
        }
    }
}
export interface ITElkSendCommentResponseArgs {
}
export class TElkSendCommentResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkSendCommentResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkSendCommentResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkSendCommentResponse();
    }
}
export interface ITElkGetMessageHistoryRequestArgs {
    eventId: string;
    inviteeUuid?: string;
    token?: string;
}
export class TElkGetMessageHistoryRequest {
    public eventId: string;
    public inviteeUuid?: string;
    public token?: string;
    constructor(args: ITElkGetMessageHistoryRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
        if (args != null && args.inviteeUuid != null) {
            this.inviteeUuid = args.inviteeUuid;
        }
        if (args != null && args.token != null) {
            this.token = args.token;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetMessageHistoryRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        if (this.inviteeUuid != null) {
            output.writeFieldBegin("inviteeUuid", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.inviteeUuid);
            output.writeFieldEnd();
        }
        if (this.token != null) {
            output.writeFieldBegin("token", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.token);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetMessageHistoryRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_210: string = input.readString();
                        _args.eventId = value_210;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_211: string = input.readString();
                        _args.inviteeUuid = value_211;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_212: string = input.readString();
                        _args.token = value_212;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined) {
            return new TElkGetMessageHistoryRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetMessageHistoryRequest from input");
        }
    }
}
export interface ITElkGetMessageHistoryResponseArgs {
    messages: Array<TAppElkMessage>;
}
export class TElkGetMessageHistoryResponse {
    public messages: Array<TAppElkMessage>;
    constructor(args: ITElkGetMessageHistoryResponseArgs) {
        if (args != null && args.messages != null) {
            this.messages = args.messages;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[messages] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetMessageHistoryResponse");
        if (this.messages != null) {
            output.writeFieldBegin("messages", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.messages.length);
            this.messages.forEach((value_213: TAppElkMessage): void => {
                value_213.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetMessageHistoryResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_214: Array<TAppElkMessage> = new Array<TAppElkMessage>();
                        const metadata_11: thrift.TList = input.readListBegin();
                        const size_11: number = metadata_11.size;
                        for (let i_11: number = 0; i_11 < size_11; i_11++) {
                            const value_215: TAppElkMessage = TAppElkMessage.read(input);
                            value_214.push(value_215);
                        }
                        input.readListEnd();
                        _args.messages = value_214;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.messages !== undefined) {
            return new TElkGetMessageHistoryResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetMessageHistoryResponse from input");
        }
    }
}
export interface ITElkGetImageHistoryRequestArgs {
    eventId?: string;
}
export class TElkGetImageHistoryRequest {
    public eventId?: string;
    constructor(args?: ITElkGetImageHistoryRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetImageHistoryRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetImageHistoryRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_216: string = input.readString();
                        _args.eventId = value_216;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkGetImageHistoryRequest(_args);
    }
}
export interface ITElkGetImageHistoryResponseArgs {
    images: Array<TAppElkImage>;
    uploadedImages?: Array<TAppElkImage>;
}
export class TElkGetImageHistoryResponse {
    public images: Array<TAppElkImage>;
    public uploadedImages?: Array<TAppElkImage>;
    constructor(args: ITElkGetImageHistoryResponseArgs) {
        if (args != null && args.images != null) {
            this.images = args.images;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[images] is unset!");
        }
        if (args != null && args.uploadedImages != null) {
            this.uploadedImages = args.uploadedImages;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetImageHistoryResponse");
        if (this.images != null) {
            output.writeFieldBegin("images", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.images.length);
            this.images.forEach((value_217: TAppElkImage): void => {
                value_217.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.uploadedImages != null) {
            output.writeFieldBegin("uploadedImages", thrift.Thrift.Type.LIST, 2);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.uploadedImages.length);
            this.uploadedImages.forEach((value_218: TAppElkImage): void => {
                value_218.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetImageHistoryResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_219: Array<TAppElkImage> = new Array<TAppElkImage>();
                        const metadata_12: thrift.TList = input.readListBegin();
                        const size_12: number = metadata_12.size;
                        for (let i_12: number = 0; i_12 < size_12; i_12++) {
                            const value_220: TAppElkImage = TAppElkImage.read(input);
                            value_219.push(value_220);
                        }
                        input.readListEnd();
                        _args.images = value_219;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_221: Array<TAppElkImage> = new Array<TAppElkImage>();
                        const metadata_13: thrift.TList = input.readListBegin();
                        const size_13: number = metadata_13.size;
                        for (let i_13: number = 0; i_13 < size_13; i_13++) {
                            const value_222: TAppElkImage = TAppElkImage.read(input);
                            value_221.push(value_222);
                        }
                        input.readListEnd();
                        _args.uploadedImages = value_221;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.images !== undefined) {
            return new TElkGetImageHistoryResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetImageHistoryResponse from input");
        }
    }
}
export interface ITElkSendTemplateEmailRequestArgs {
    eventId: string;
    emailAddress: string;
}
export class TElkSendTemplateEmailRequest {
    public eventId: string;
    public emailAddress: string;
    constructor(args: ITElkSendTemplateEmailRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
        if (args != null && args.emailAddress != null) {
            this.emailAddress = args.emailAddress;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[emailAddress] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkSendTemplateEmailRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        if (this.emailAddress != null) {
            output.writeFieldBegin("emailAddress", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.emailAddress);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkSendTemplateEmailRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_223: string = input.readString();
                        _args.eventId = value_223;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_224: string = input.readString();
                        _args.emailAddress = value_224;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined && _args.emailAddress !== undefined) {
            return new TElkSendTemplateEmailRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkSendTemplateEmailRequest from input");
        }
    }
}
export interface ITElkSendTemplateEmailResponseArgs {
}
export class TElkSendTemplateEmailResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkSendTemplateEmailResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkSendTemplateEmailResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkSendTemplateEmailResponse();
    }
}
export interface ITElkQueueInviteRequestArgs {
    eventId: string;
    invitees: Array<TAppElkInvitee>;
}
export class TElkQueueInviteRequest {
    public eventId: string;
    public invitees: Array<TAppElkInvitee>;
    constructor(args: ITElkQueueInviteRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
        if (args != null && args.invitees != null) {
            this.invitees = args.invitees;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[invitees] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkQueueInviteRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        if (this.invitees != null) {
            output.writeFieldBegin("invitees", thrift.Thrift.Type.LIST, 2);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.invitees.length);
            this.invitees.forEach((value_225: TAppElkInvitee): void => {
                value_225.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkQueueInviteRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_226: string = input.readString();
                        _args.eventId = value_226;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_227: Array<TAppElkInvitee> = new Array<TAppElkInvitee>();
                        const metadata_14: thrift.TList = input.readListBegin();
                        const size_14: number = metadata_14.size;
                        for (let i_14: number = 0; i_14 < size_14; i_14++) {
                            const value_228: TAppElkInvitee = TAppElkInvitee.read(input);
                            value_227.push(value_228);
                        }
                        input.readListEnd();
                        _args.invitees = value_227;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined && _args.invitees !== undefined) {
            return new TElkQueueInviteRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkQueueInviteRequest from input");
        }
    }
}
export interface ITElkQueueInviteResponseArgs {
}
export class TElkQueueInviteResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkQueueInviteResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkQueueInviteResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkQueueInviteResponse();
    }
}
export interface ITElkDeletePendingInviteRequestArgs {
    eventId: string;
    inviteIds: Array<string>;
}
export class TElkDeletePendingInviteRequest {
    public eventId: string;
    public inviteIds: Array<string>;
    constructor(args: ITElkDeletePendingInviteRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
        if (args != null && args.inviteIds != null) {
            this.inviteIds = args.inviteIds;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[inviteIds] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkDeletePendingInviteRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        if (this.inviteIds != null) {
            output.writeFieldBegin("inviteIds", thrift.Thrift.Type.LIST, 2);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.inviteIds.length);
            this.inviteIds.forEach((value_229: string): void => {
                output.writeString(value_229);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkDeletePendingInviteRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_230: string = input.readString();
                        _args.eventId = value_230;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_231: Array<string> = new Array<string>();
                        const metadata_15: thrift.TList = input.readListBegin();
                        const size_15: number = metadata_15.size;
                        for (let i_15: number = 0; i_15 < size_15; i_15++) {
                            const value_232: string = input.readString();
                            value_231.push(value_232);
                        }
                        input.readListEnd();
                        _args.inviteIds = value_231;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined && _args.inviteIds !== undefined) {
            return new TElkDeletePendingInviteRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkDeletePendingInviteRequest from input");
        }
    }
}
export interface ITElkDeletePendingInviteResponseArgs {
    failedInviteIds: Array<string>;
}
export class TElkDeletePendingInviteResponse {
    public failedInviteIds: Array<string>;
    constructor(args: ITElkDeletePendingInviteResponseArgs) {
        if (args != null && args.failedInviteIds != null) {
            this.failedInviteIds = args.failedInviteIds;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[failedInviteIds] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkDeletePendingInviteResponse");
        if (this.failedInviteIds != null) {
            output.writeFieldBegin("failedInviteIds", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.failedInviteIds.length);
            this.failedInviteIds.forEach((value_233: string): void => {
                output.writeString(value_233);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkDeletePendingInviteResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_234: Array<string> = new Array<string>();
                        const metadata_16: thrift.TList = input.readListBegin();
                        const size_16: number = metadata_16.size;
                        for (let i_16: number = 0; i_16 < size_16; i_16++) {
                            const value_235: string = input.readString();
                            value_234.push(value_235);
                        }
                        input.readListEnd();
                        _args.failedInviteIds = value_234;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.failedInviteIds !== undefined) {
            return new TElkDeletePendingInviteResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkDeletePendingInviteResponse from input");
        }
    }
}
export interface ITElkSendInviteRequestArgs {
    eventId: string;
    inviteIds: Array<string>;
    email?: string;
}
export class TElkSendInviteRequest {
    public eventId: string;
    public inviteIds: Array<string>;
    public email?: string;
    constructor(args: ITElkSendInviteRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
        if (args != null && args.inviteIds != null) {
            this.inviteIds = args.inviteIds;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[inviteIds] is unset!");
        }
        if (args != null && args.email != null) {
            this.email = args.email;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkSendInviteRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        if (this.inviteIds != null) {
            output.writeFieldBegin("inviteIds", thrift.Thrift.Type.LIST, 2);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.inviteIds.length);
            this.inviteIds.forEach((value_236: string): void => {
                output.writeString(value_236);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.email != null) {
            output.writeFieldBegin("email", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.email);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkSendInviteRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_237: string = input.readString();
                        _args.eventId = value_237;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_238: Array<string> = new Array<string>();
                        const metadata_17: thrift.TList = input.readListBegin();
                        const size_17: number = metadata_17.size;
                        for (let i_17: number = 0; i_17 < size_17; i_17++) {
                            const value_239: string = input.readString();
                            value_238.push(value_239);
                        }
                        input.readListEnd();
                        _args.inviteIds = value_238;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_240: string = input.readString();
                        _args.email = value_240;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined && _args.inviteIds !== undefined) {
            return new TElkSendInviteRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkSendInviteRequest from input");
        }
    }
}
export interface ITElkGetAlbumDetailsRequestArgs {
    eventId: string;
    token?: string;
    inviteeUuid?: string;
}
export class TElkGetAlbumDetailsRequest {
    public eventId: string;
    public token?: string;
    public inviteeUuid?: string;
    constructor(args: ITElkGetAlbumDetailsRequestArgs) {
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventId] is unset!");
        }
        if (args != null && args.token != null) {
            this.token = args.token;
        }
        if (args != null && args.inviteeUuid != null) {
            this.inviteeUuid = args.inviteeUuid;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetAlbumDetailsRequest");
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        if (this.token != null) {
            output.writeFieldBegin("token", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.token);
            output.writeFieldEnd();
        }
        if (this.inviteeUuid != null) {
            output.writeFieldBegin("inviteeUuid", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.inviteeUuid);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetAlbumDetailsRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_241: string = input.readString();
                        _args.eventId = value_241;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_242: string = input.readString();
                        _args.token = value_242;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_243: string = input.readString();
                        _args.inviteeUuid = value_243;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventId !== undefined) {
            return new TElkGetAlbumDetailsRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetAlbumDetailsRequest from input");
        }
    }
}
export interface ITElkGetAlbumDetailsResponseArgs {
    albumUrl?: string;
    photoCount?: number;
    albumCode?: string;
    memberCount?: number;
    albumJoinStatus?: TElkAlbumJoinStatus;
    photoMetas?: Array<TAppElkPhotoMeta>;
    animatedPreviewUrl?: string;
    photoUploadMode?: TAppElkPhotoUploadMode;
}
export class TElkGetAlbumDetailsResponse {
    public albumUrl?: string;
    public photoCount?: number;
    public albumCode?: string;
    public memberCount?: number;
    public albumJoinStatus?: TElkAlbumJoinStatus;
    public photoMetas?: Array<TAppElkPhotoMeta>;
    public animatedPreviewUrl?: string;
    public photoUploadMode?: TAppElkPhotoUploadMode;
    constructor(args?: ITElkGetAlbumDetailsResponseArgs) {
        if (args != null && args.albumUrl != null) {
            this.albumUrl = args.albumUrl;
        }
        if (args != null && args.photoCount != null) {
            this.photoCount = args.photoCount;
        }
        if (args != null && args.albumCode != null) {
            this.albumCode = args.albumCode;
        }
        if (args != null && args.memberCount != null) {
            this.memberCount = args.memberCount;
        }
        if (args != null && args.albumJoinStatus != null) {
            this.albumJoinStatus = args.albumJoinStatus;
        }
        if (args != null && args.photoMetas != null) {
            this.photoMetas = args.photoMetas;
        }
        if (args != null && args.animatedPreviewUrl != null) {
            this.animatedPreviewUrl = args.animatedPreviewUrl;
        }
        if (args != null && args.photoUploadMode != null) {
            this.photoUploadMode = args.photoUploadMode;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetAlbumDetailsResponse");
        if (this.albumUrl != null) {
            output.writeFieldBegin("albumUrl", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.albumUrl);
            output.writeFieldEnd();
        }
        if (this.photoCount != null) {
            output.writeFieldBegin("photoCount", thrift.Thrift.Type.I32, 2);
            output.writeI32(this.photoCount);
            output.writeFieldEnd();
        }
        if (this.albumCode != null) {
            output.writeFieldBegin("albumCode", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.albumCode);
            output.writeFieldEnd();
        }
        if (this.memberCount != null) {
            output.writeFieldBegin("memberCount", thrift.Thrift.Type.I32, 4);
            output.writeI32(this.memberCount);
            output.writeFieldEnd();
        }
        if (this.albumJoinStatus != null) {
            output.writeFieldBegin("albumJoinStatus", thrift.Thrift.Type.I32, 5);
            output.writeI32(this.albumJoinStatus);
            output.writeFieldEnd();
        }
        if (this.photoMetas != null) {
            output.writeFieldBegin("photoMetas", thrift.Thrift.Type.LIST, 6);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.photoMetas.length);
            this.photoMetas.forEach((value_244: TAppElkPhotoMeta): void => {
                value_244.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.animatedPreviewUrl != null) {
            output.writeFieldBegin("animatedPreviewUrl", thrift.Thrift.Type.STRING, 7);
            output.writeString(this.animatedPreviewUrl);
            output.writeFieldEnd();
        }
        if (this.photoUploadMode != null) {
            output.writeFieldBegin("photoUploadMode", thrift.Thrift.Type.I32, 8);
            output.writeI32(this.photoUploadMode);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetAlbumDetailsResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_245: string = input.readString();
                        _args.albumUrl = value_245;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_246: number = input.readI32();
                        _args.photoCount = value_246;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_247: string = input.readString();
                        _args.albumCode = value_247;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_248: number = input.readI32();
                        _args.memberCount = value_248;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_249: TElkAlbumJoinStatus = input.readI32();
                        _args.albumJoinStatus = value_249;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_250: Array<TAppElkPhotoMeta> = new Array<TAppElkPhotoMeta>();
                        const metadata_18: thrift.TList = input.readListBegin();
                        const size_18: number = metadata_18.size;
                        for (let i_18: number = 0; i_18 < size_18; i_18++) {
                            const value_251: TAppElkPhotoMeta = TAppElkPhotoMeta.read(input);
                            value_250.push(value_251);
                        }
                        input.readListEnd();
                        _args.photoMetas = value_250;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_252: string = input.readString();
                        _args.animatedPreviewUrl = value_252;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_253: TAppElkPhotoUploadMode = input.readI32();
                        _args.photoUploadMode = value_253;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkGetAlbumDetailsResponse(_args);
    }
}
export interface ITElkSendInviteResponseArgs {
}
export class TElkSendInviteResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkSendInviteResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkSendInviteResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkSendInviteResponse();
    }
}
export interface ITAppElkPhoenixAlbumArgs {
    title?: string;
    startTs?: number | Int64;
    endTs?: number | Int64;
    albumId?: string;
    addressInfo?: PContact.TAppAddress;
}
export class TAppElkPhoenixAlbum {
    public title?: string;
    public startTs?: Int64;
    public endTs?: Int64;
    public albumId?: string;
    public addressInfo?: PContact.TAppAddress;
    constructor(args?: ITAppElkPhoenixAlbumArgs) {
        if (args != null && args.title != null) {
            this.title = args.title;
        }
        if (args != null && args.startTs != null) {
            if (typeof args.startTs === "number") {
                this.startTs = new Int64(args.startTs);
            }
            else {
                this.startTs = args.startTs;
            }
        }
        if (args != null && args.endTs != null) {
            if (typeof args.endTs === "number") {
                this.endTs = new Int64(args.endTs);
            }
            else {
                this.endTs = args.endTs;
            }
        }
        if (args != null && args.albumId != null) {
            this.albumId = args.albumId;
        }
        if (args != null && args.addressInfo != null) {
            this.addressInfo = args.addressInfo;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkPhoenixAlbum");
        if (this.title != null) {
            output.writeFieldBegin("title", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.title);
            output.writeFieldEnd();
        }
        if (this.startTs != null) {
            output.writeFieldBegin("startTs", thrift.Thrift.Type.I64, 2);
            output.writeI64(this.startTs);
            output.writeFieldEnd();
        }
        if (this.endTs != null) {
            output.writeFieldBegin("endTs", thrift.Thrift.Type.I64, 3);
            output.writeI64(this.endTs);
            output.writeFieldEnd();
        }
        if (this.albumId != null) {
            output.writeFieldBegin("albumId", thrift.Thrift.Type.STRING, 7);
            output.writeString(this.albumId);
            output.writeFieldEnd();
        }
        if (this.addressInfo != null) {
            output.writeFieldBegin("addressInfo", thrift.Thrift.Type.STRUCT, 8);
            this.addressInfo.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkPhoenixAlbum {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_254: string = input.readString();
                        _args.title = value_254;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_255: Int64 = input.readI64();
                        _args.startTs = value_255;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_256: Int64 = input.readI64();
                        _args.endTs = value_256;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_257: string = input.readString();
                        _args.albumId = value_257;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_258: PContact.TAppAddress = PContact.TAppAddress.read(input);
                        _args.addressInfo = value_258;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TAppElkPhoenixAlbum(_args);
    }
}
export interface ITElkFetchAlbumRequestArgs {
    albumId: string;
}
export class TElkFetchAlbumRequest {
    public albumId: string;
    constructor(args: ITElkFetchAlbumRequestArgs) {
        if (args != null && args.albumId != null) {
            this.albumId = args.albumId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[albumId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkFetchAlbumRequest");
        if (this.albumId != null) {
            output.writeFieldBegin("albumId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.albumId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkFetchAlbumRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_259: string = input.readString();
                        _args.albumId = value_259;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.albumId !== undefined) {
            return new TElkFetchAlbumRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkFetchAlbumRequest from input");
        }
    }
}
export interface ITElkFetchAlbumResponseArgs {
    album?: TAppElkPhoenixAlbum;
}
export class TElkFetchAlbumResponse {
    public album?: TAppElkPhoenixAlbum;
    constructor(args?: ITElkFetchAlbumResponseArgs) {
        if (args != null && args.album != null) {
            this.album = args.album;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkFetchAlbumResponse");
        if (this.album != null) {
            output.writeFieldBegin("album", thrift.Thrift.Type.STRUCT, 1);
            this.album.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkFetchAlbumResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_260: TAppElkPhoenixAlbum = TAppElkPhoenixAlbum.read(input);
                        _args.album = value_260;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkFetchAlbumResponse(_args);
    }
}
export interface ITElkFetchContactsRequestArgs {
    pageToken?: string;
    numRows?: number;
}
export class TElkFetchContactsRequest {
    public pageToken?: string;
    public numRows?: number = 1000;
    constructor(args?: ITElkFetchContactsRequestArgs) {
        if (args != null && args.pageToken != null) {
            this.pageToken = args.pageToken;
        }
        if (args != null && args.numRows != null) {
            this.numRows = args.numRows;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkFetchContactsRequest");
        if (this.pageToken != null) {
            output.writeFieldBegin("pageToken", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.pageToken);
            output.writeFieldEnd();
        }
        if (this.numRows != null) {
            output.writeFieldBegin("numRows", thrift.Thrift.Type.I32, 2);
            output.writeI32(this.numRows);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkFetchContactsRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_261: string = input.readString();
                        _args.pageToken = value_261;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_262: number = input.readI32();
                        _args.numRows = value_262;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkFetchContactsRequest(_args);
    }
}
export interface ITElkFetchContactsResponseArgs {
    contacts: Array<ContactLite.TAppContactLite>;
    nextPageToken: string;
    hasMore: boolean;
}
export class TElkFetchContactsResponse {
    public contacts: Array<ContactLite.TAppContactLite>;
    public nextPageToken: string;
    public hasMore: boolean;
    constructor(args: ITElkFetchContactsResponseArgs) {
        if (args != null && args.contacts != null) {
            this.contacts = args.contacts;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[contacts] is unset!");
        }
        if (args != null && args.nextPageToken != null) {
            this.nextPageToken = args.nextPageToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[nextPageToken] is unset!");
        }
        if (args != null && args.hasMore != null) {
            this.hasMore = args.hasMore;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[hasMore] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkFetchContactsResponse");
        if (this.contacts != null) {
            output.writeFieldBegin("contacts", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.contacts.length);
            this.contacts.forEach((value_263: ContactLite.TAppContactLite): void => {
                value_263.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.nextPageToken != null) {
            output.writeFieldBegin("nextPageToken", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.nextPageToken);
            output.writeFieldEnd();
        }
        if (this.hasMore != null) {
            output.writeFieldBegin("hasMore", thrift.Thrift.Type.BOOL, 3);
            output.writeBool(this.hasMore);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkFetchContactsResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_264: Array<ContactLite.TAppContactLite> = new Array<ContactLite.TAppContactLite>();
                        const metadata_19: thrift.TList = input.readListBegin();
                        const size_19: number = metadata_19.size;
                        for (let i_19: number = 0; i_19 < size_19; i_19++) {
                            const value_265: ContactLite.TAppContactLite = ContactLite.TAppContactLite.read(input);
                            value_264.push(value_265);
                        }
                        input.readListEnd();
                        _args.contacts = value_264;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_266: string = input.readString();
                        _args.nextPageToken = value_266;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_267: boolean = input.readBool();
                        _args.hasMore = value_267;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.contacts !== undefined && _args.nextPageToken !== undefined && _args.hasMore !== undefined) {
            return new TElkFetchContactsResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkFetchContactsResponse from input");
        }
    }
}
export interface ITElkFetchDraftRequestArgs {
    eventUuid: string;
}
export class TElkFetchDraftRequest {
    public eventUuid: string;
    constructor(args: ITElkFetchDraftRequestArgs) {
        if (args != null && args.eventUuid != null) {
            this.eventUuid = args.eventUuid;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventUuid] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkFetchDraftRequest");
        if (this.eventUuid != null) {
            output.writeFieldBegin("eventUuid", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventUuid);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkFetchDraftRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_268: string = input.readString();
                        _args.eventUuid = value_268;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventUuid !== undefined) {
            return new TElkFetchDraftRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkFetchDraftRequest from input");
        }
    }
}
export interface ITElkDeleteDraftResponseArgs {
}
export class TElkDeleteDraftResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkDeleteDraftResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkDeleteDraftResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkDeleteDraftResponse();
    }
}
export interface ITElkDeleteDraftRequestArgs {
    eventUuid: string;
}
export class TElkDeleteDraftRequest {
    public eventUuid: string;
    constructor(args: ITElkDeleteDraftRequestArgs) {
        if (args != null && args.eventUuid != null) {
            this.eventUuid = args.eventUuid;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventUuid] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkDeleteDraftRequest");
        if (this.eventUuid != null) {
            output.writeFieldBegin("eventUuid", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventUuid);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkDeleteDraftRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_269: string = input.readString();
                        _args.eventUuid = value_269;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventUuid !== undefined) {
            return new TElkDeleteDraftRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkDeleteDraftRequest from input");
        }
    }
}
export interface ITElkFetchDraftResponseArgs {
    event?: TAppElkDraftEvent;
}
export class TElkFetchDraftResponse {
    public event?: TAppElkDraftEvent;
    constructor(args?: ITElkFetchDraftResponseArgs) {
        if (args != null && args.event != null) {
            this.event = args.event;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkFetchDraftResponse");
        if (this.event != null) {
            output.writeFieldBegin("event", thrift.Thrift.Type.STRUCT, 1);
            this.event.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkFetchDraftResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_270: TAppElkDraftEvent = TAppElkDraftEvent.read(input);
                        _args.event = value_270;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkFetchDraftResponse(_args);
    }
}
export interface ITElkFetchDraftsRequestArgs {
}
export class TElkFetchDraftsRequest {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkFetchDraftsRequest");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkFetchDraftsRequest {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TElkFetchDraftsRequest();
    }
}
export interface ITElkFetchDraftsResponseArgs {
    events: Array<TAppElkDraftEvent>;
}
export class TElkFetchDraftsResponse {
    public events: Array<TAppElkDraftEvent>;
    constructor(args: ITElkFetchDraftsResponseArgs) {
        if (args != null && args.events != null) {
            this.events = args.events;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[events] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkFetchDraftsResponse");
        if (this.events != null) {
            output.writeFieldBegin("events", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.events.length);
            this.events.forEach((value_271: TAppElkDraftEvent): void => {
                value_271.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkFetchDraftsResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_272: Array<TAppElkDraftEvent> = new Array<TAppElkDraftEvent>();
                        const metadata_20: thrift.TList = input.readListBegin();
                        const size_20: number = metadata_20.size;
                        for (let i_20: number = 0; i_20 < size_20; i_20++) {
                            const value_273: TAppElkDraftEvent = TAppElkDraftEvent.read(input);
                            value_272.push(value_273);
                        }
                        input.readListEnd();
                        _args.events = value_272;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.events !== undefined) {
            return new TElkFetchDraftsResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkFetchDraftsResponse from input");
        }
    }
}
export interface ITAppElkMutualMemberArgs {
    member: TAppElkUser;
    sharedEvents: Array<string>;
}
export class TAppElkMutualMember {
    public member: TAppElkUser;
    public sharedEvents: Array<string>;
    constructor(args: ITAppElkMutualMemberArgs) {
        if (args != null && args.member != null) {
            this.member = args.member;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[member] is unset!");
        }
        if (args != null && args.sharedEvents != null) {
            this.sharedEvents = args.sharedEvents;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[sharedEvents] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkMutualMember");
        if (this.member != null) {
            output.writeFieldBegin("member", thrift.Thrift.Type.STRUCT, 1);
            this.member.write(output);
            output.writeFieldEnd();
        }
        if (this.sharedEvents != null) {
            output.writeFieldBegin("sharedEvents", thrift.Thrift.Type.LIST, 2);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.sharedEvents.length);
            this.sharedEvents.forEach((value_274: string): void => {
                output.writeString(value_274);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkMutualMember {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_275: TAppElkUser = TAppElkUser.read(input);
                        _args.member = value_275;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_276: Array<string> = new Array<string>();
                        const metadata_21: thrift.TList = input.readListBegin();
                        const size_21: number = metadata_21.size;
                        for (let i_21: number = 0; i_21 < size_21; i_21++) {
                            const value_277: string = input.readString();
                            value_276.push(value_277);
                        }
                        input.readListEnd();
                        _args.sharedEvents = value_276;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.member !== undefined && _args.sharedEvents !== undefined) {
            return new TAppElkMutualMember(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppElkMutualMember from input");
        }
    }
}
export interface ITElkFetchMutualMembersRequestArgs {
    pageToken: string;
}
export class TElkFetchMutualMembersRequest {
    public pageToken: string;
    constructor(args: ITElkFetchMutualMembersRequestArgs) {
        if (args != null && args.pageToken != null) {
            this.pageToken = args.pageToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[pageToken] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkFetchMutualMembersRequest");
        if (this.pageToken != null) {
            output.writeFieldBegin("pageToken", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.pageToken);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkFetchMutualMembersRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_278: string = input.readString();
                        _args.pageToken = value_278;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.pageToken !== undefined) {
            return new TElkFetchMutualMembersRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkFetchMutualMembersRequest from input");
        }
    }
}
export interface ITElkFetchMutualMembersResponseArgs {
    mutualMembers: Array<TAppElkMutualMember>;
    pageToken: string;
    hasMore: boolean;
}
export class TElkFetchMutualMembersResponse {
    public mutualMembers: Array<TAppElkMutualMember>;
    public pageToken: string;
    public hasMore: boolean;
    constructor(args: ITElkFetchMutualMembersResponseArgs) {
        if (args != null && args.mutualMembers != null) {
            this.mutualMembers = args.mutualMembers;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[mutualMembers] is unset!");
        }
        if (args != null && args.pageToken != null) {
            this.pageToken = args.pageToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[pageToken] is unset!");
        }
        if (args != null && args.hasMore != null) {
            this.hasMore = args.hasMore;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[hasMore] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkFetchMutualMembersResponse");
        if (this.mutualMembers != null) {
            output.writeFieldBegin("mutualMembers", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.mutualMembers.length);
            this.mutualMembers.forEach((value_279: TAppElkMutualMember): void => {
                value_279.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.pageToken != null) {
            output.writeFieldBegin("pageToken", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.pageToken);
            output.writeFieldEnd();
        }
        if (this.hasMore != null) {
            output.writeFieldBegin("hasMore", thrift.Thrift.Type.BOOL, 3);
            output.writeBool(this.hasMore);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkFetchMutualMembersResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_280: Array<TAppElkMutualMember> = new Array<TAppElkMutualMember>();
                        const metadata_22: thrift.TList = input.readListBegin();
                        const size_22: number = metadata_22.size;
                        for (let i_22: number = 0; i_22 < size_22; i_22++) {
                            const value_281: TAppElkMutualMember = TAppElkMutualMember.read(input);
                            value_280.push(value_281);
                        }
                        input.readListEnd();
                        _args.mutualMembers = value_280;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_282: string = input.readString();
                        _args.pageToken = value_282;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_283: boolean = input.readBool();
                        _args.hasMore = value_283;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.mutualMembers !== undefined && _args.pageToken !== undefined && _args.hasMore !== undefined) {
            return new TElkFetchMutualMembersResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkFetchMutualMembersResponse from input");
        }
    }
}
export interface ITAppElkEventAnswerValueArgs {
    text?: string;
    isYes?: boolean;
    song?: TAppElkSpotifySong;
}
export class TAppElkEventAnswerValue {
    public text?: string;
    public isYes?: boolean;
    public song?: TAppElkSpotifySong;
    constructor(args?: ITAppElkEventAnswerValueArgs) {
        let _fieldsSet: number = 0;
        if (args != null) {
            if (args.text != null) {
                _fieldsSet++;
                this.text = args.text;
            }
            if (args.isYes != null) {
                _fieldsSet++;
                this.isYes = args.isYes;
            }
            if (args.song != null) {
                _fieldsSet++;
                this.song = args.song;
            }
            if (_fieldsSet > 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
            }
            else if (_fieldsSet < 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
            }
        }
    }
    public static fromText(text: string): TAppElkEventAnswerValue {
        return new TAppElkEventAnswerValue({ text });
    }
    public static fromIsYes(isYes: boolean): TAppElkEventAnswerValue {
        return new TAppElkEventAnswerValue({ isYes });
    }
    public static fromSong(song: TAppElkSpotifySong): TAppElkEventAnswerValue {
        return new TAppElkEventAnswerValue({ song });
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppElkEventAnswerValue");
        if (this.text != null) {
            output.writeFieldBegin("text", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.text);
            output.writeFieldEnd();
        }
        if (this.isYes != null) {
            output.writeFieldBegin("isYes", thrift.Thrift.Type.BOOL, 2);
            output.writeBool(this.isYes);
            output.writeFieldEnd();
        }
        if (this.song != null) {
            output.writeFieldBegin("song", thrift.Thrift.Type.STRUCT, 3);
            this.song.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppElkEventAnswerValue {
        let _fieldsSet: number = 0;
        let _returnValue: TAppElkEventAnswerValue | null = null;
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        _fieldsSet++;
                        const value_284: string = input.readString();
                        _returnValue = TAppElkEventAnswerValue.fromText(value_284);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        _fieldsSet++;
                        const value_285: boolean = input.readBool();
                        _returnValue = TAppElkEventAnswerValue.fromIsYes(value_285);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        _fieldsSet++;
                        const value_286: TAppElkSpotifySong = TAppElkSpotifySong.read(input);
                        _returnValue = TAppElkEventAnswerValue.fromSong(value_286);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_fieldsSet > 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
        }
        else if (_fieldsSet < 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
        }
        if (_returnValue !== null) {
            return _returnValue;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read data for TUnion");
        }
    }
}
export interface ITElkInitializePaymentRequestArgs {
    priceId: string;
}
export class TElkInitializePaymentRequest {
    public priceId: string;
    constructor(args: ITElkInitializePaymentRequestArgs) {
        if (args != null && args.priceId != null) {
            this.priceId = args.priceId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[priceId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkInitializePaymentRequest");
        if (this.priceId != null) {
            output.writeFieldBegin("priceId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.priceId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkInitializePaymentRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_287: string = input.readString();
                        _args.priceId = value_287;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.priceId !== undefined) {
            return new TElkInitializePaymentRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkInitializePaymentRequest from input");
        }
    }
}
export interface ITElkInitializePaymentResponseArgs {
    clientSecret: string;
}
export class TElkInitializePaymentResponse {
    public clientSecret: string;
    constructor(args: ITElkInitializePaymentResponseArgs) {
        if (args != null && args.clientSecret != null) {
            this.clientSecret = args.clientSecret;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[clientSecret] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkInitializePaymentResponse");
        if (this.clientSecret != null) {
            output.writeFieldBegin("clientSecret", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.clientSecret);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkInitializePaymentResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_288: string = input.readString();
                        _args.clientSecret = value_288;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.clientSecret !== undefined) {
            return new TElkInitializePaymentResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkInitializePaymentResponse from input");
        }
    }
}
export enum TAppElkImageStyle {
    PAINTERLY = 0,
    ABSTRACT_ART = 1,
    POP_ART = 2,
    GOTHIC = 3,
    FAIRY_TALE = 4,
    SOPHISTICATED = 5,
    CUBIST = 6,
    VAN_GOGH = 7,
    CLASSICAL = 8,
    STREET_ART = 9,
    BAROQUE = 10,
    FANTASY = 11,
    RENAISSANCE = 12,
    NOIR = 13,
    DEFAULT = 14
}
export interface ITElkGetThemeSuggestionRequestArgs {
    eventName: string;
}
export class TElkGetThemeSuggestionRequest {
    public eventName: string;
    constructor(args: ITElkGetThemeSuggestionRequestArgs) {
        if (args != null && args.eventName != null) {
            this.eventName = args.eventName;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventName] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetThemeSuggestionRequest");
        if (this.eventName != null) {
            output.writeFieldBegin("eventName", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventName);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetThemeSuggestionRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_289: string = input.readString();
                        _args.eventName = value_289;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventName !== undefined) {
            return new TElkGetThemeSuggestionRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetThemeSuggestionRequest from input");
        }
    }
}
export interface ITElkGetThemeSuggestionResponseArgs {
    emoji: string;
    color: string;
    animation?: ElkEvent.TAppElkBackgroundAnimation;
}
export class TElkGetThemeSuggestionResponse {
    public emoji: string;
    public color: string;
    public animation?: ElkEvent.TAppElkBackgroundAnimation;
    constructor(args: ITElkGetThemeSuggestionResponseArgs) {
        if (args != null && args.emoji != null) {
            this.emoji = args.emoji;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[emoji] is unset!");
        }
        if (args != null && args.color != null) {
            this.color = args.color;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[color] is unset!");
        }
        if (args != null && args.animation != null) {
            this.animation = args.animation;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGetThemeSuggestionResponse");
        if (this.emoji != null) {
            output.writeFieldBegin("emoji", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.emoji);
            output.writeFieldEnd();
        }
        if (this.color != null) {
            output.writeFieldBegin("color", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.color);
            output.writeFieldEnd();
        }
        if (this.animation != null) {
            output.writeFieldBegin("animation", thrift.Thrift.Type.I32, 3);
            output.writeI32(this.animation);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGetThemeSuggestionResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_290: string = input.readString();
                        _args.emoji = value_290;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_291: string = input.readString();
                        _args.color = value_291;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_292: ElkEvent.TAppElkBackgroundAnimation = input.readI32();
                        _args.animation = value_292;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.emoji !== undefined && _args.color !== undefined) {
            return new TElkGetThemeSuggestionResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGetThemeSuggestionResponse from input");
        }
    }
}
export interface ITElkGenerateImageRequestArgs {
    eventName: string;
    prompt?: string;
    useVerbatim?: boolean;
    taskId: string;
    eventUuid: string;
    style?: TAppElkImageStyle;
}
export class TElkGenerateImageRequest {
    public eventName: string;
    public prompt?: string;
    public useVerbatim?: boolean;
    public taskId: string;
    public eventUuid: string;
    public style?: TAppElkImageStyle;
    constructor(args: ITElkGenerateImageRequestArgs) {
        if (args != null && args.eventName != null) {
            this.eventName = args.eventName;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventName] is unset!");
        }
        if (args != null && args.prompt != null) {
            this.prompt = args.prompt;
        }
        if (args != null && args.useVerbatim != null) {
            this.useVerbatim = args.useVerbatim;
        }
        if (args != null && args.taskId != null) {
            this.taskId = args.taskId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[taskId] is unset!");
        }
        if (args != null && args.eventUuid != null) {
            this.eventUuid = args.eventUuid;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventUuid] is unset!");
        }
        if (args != null && args.style != null) {
            this.style = args.style;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGenerateImageRequest");
        if (this.eventName != null) {
            output.writeFieldBegin("eventName", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventName);
            output.writeFieldEnd();
        }
        if (this.prompt != null) {
            output.writeFieldBegin("prompt", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.prompt);
            output.writeFieldEnd();
        }
        if (this.useVerbatim != null) {
            output.writeFieldBegin("useVerbatim", thrift.Thrift.Type.BOOL, 3);
            output.writeBool(this.useVerbatim);
            output.writeFieldEnd();
        }
        if (this.taskId != null) {
            output.writeFieldBegin("taskId", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.taskId);
            output.writeFieldEnd();
        }
        if (this.eventUuid != null) {
            output.writeFieldBegin("eventUuid", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.eventUuid);
            output.writeFieldEnd();
        }
        if (this.style != null) {
            output.writeFieldBegin("style", thrift.Thrift.Type.I32, 6);
            output.writeI32(this.style);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGenerateImageRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_293: string = input.readString();
                        _args.eventName = value_293;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_294: string = input.readString();
                        _args.prompt = value_294;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_295: boolean = input.readBool();
                        _args.useVerbatim = value_295;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_296: string = input.readString();
                        _args.taskId = value_296;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_297: string = input.readString();
                        _args.eventUuid = value_297;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_298: TAppElkImageStyle = input.readI32();
                        _args.style = value_298;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventName !== undefined && _args.taskId !== undefined && _args.eventUuid !== undefined) {
            return new TElkGenerateImageRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGenerateImageRequest from input");
        }
    }
}
export interface ITElkGenerateSampleImageRequestArgs {
    eventName: string;
    prompt?: string;
    useVerbatim?: boolean;
    taskId: string;
    eventUuid: string;
    remainingCoinBalance: number;
    style?: TAppElkImageStyle;
}
export class TElkGenerateSampleImageRequest {
    public eventName: string;
    public prompt?: string;
    public useVerbatim?: boolean;
    public taskId: string;
    public eventUuid: string;
    public remainingCoinBalance: number;
    public style?: TAppElkImageStyle;
    constructor(args: ITElkGenerateSampleImageRequestArgs) {
        if (args != null && args.eventName != null) {
            this.eventName = args.eventName;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventName] is unset!");
        }
        if (args != null && args.prompt != null) {
            this.prompt = args.prompt;
        }
        if (args != null && args.useVerbatim != null) {
            this.useVerbatim = args.useVerbatim;
        }
        if (args != null && args.taskId != null) {
            this.taskId = args.taskId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[taskId] is unset!");
        }
        if (args != null && args.eventUuid != null) {
            this.eventUuid = args.eventUuid;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventUuid] is unset!");
        }
        if (args != null && args.remainingCoinBalance != null) {
            this.remainingCoinBalance = args.remainingCoinBalance;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[remainingCoinBalance] is unset!");
        }
        if (args != null && args.style != null) {
            this.style = args.style;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGenerateSampleImageRequest");
        if (this.eventName != null) {
            output.writeFieldBegin("eventName", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventName);
            output.writeFieldEnd();
        }
        if (this.prompt != null) {
            output.writeFieldBegin("prompt", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.prompt);
            output.writeFieldEnd();
        }
        if (this.useVerbatim != null) {
            output.writeFieldBegin("useVerbatim", thrift.Thrift.Type.BOOL, 3);
            output.writeBool(this.useVerbatim);
            output.writeFieldEnd();
        }
        if (this.taskId != null) {
            output.writeFieldBegin("taskId", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.taskId);
            output.writeFieldEnd();
        }
        if (this.eventUuid != null) {
            output.writeFieldBegin("eventUuid", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.eventUuid);
            output.writeFieldEnd();
        }
        if (this.remainingCoinBalance != null) {
            output.writeFieldBegin("remainingCoinBalance", thrift.Thrift.Type.I32, 6);
            output.writeI32(this.remainingCoinBalance);
            output.writeFieldEnd();
        }
        if (this.style != null) {
            output.writeFieldBegin("style", thrift.Thrift.Type.I32, 7);
            output.writeI32(this.style);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGenerateSampleImageRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_299: string = input.readString();
                        _args.eventName = value_299;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_300: string = input.readString();
                        _args.prompt = value_300;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_301: boolean = input.readBool();
                        _args.useVerbatim = value_301;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_302: string = input.readString();
                        _args.taskId = value_302;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_303: string = input.readString();
                        _args.eventUuid = value_303;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_304: number = input.readI32();
                        _args.remainingCoinBalance = value_304;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_305: TAppElkImageStyle = input.readI32();
                        _args.style = value_305;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventName !== undefined && _args.taskId !== undefined && _args.eventUuid !== undefined && _args.remainingCoinBalance !== undefined) {
            return new TElkGenerateSampleImageRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGenerateSampleImageRequest from input");
        }
    }
}
export interface ITElkGenerateSampleImageResponseArgs {
    imageUrls: Array<string>;
    remainingCoinBalance?: number;
}
export class TElkGenerateSampleImageResponse {
    public imageUrls: Array<string>;
    public remainingCoinBalance?: number;
    constructor(args: ITElkGenerateSampleImageResponseArgs) {
        if (args != null && args.imageUrls != null) {
            this.imageUrls = args.imageUrls;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[imageUrls] is unset!");
        }
        if (args != null && args.remainingCoinBalance != null) {
            this.remainingCoinBalance = args.remainingCoinBalance;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGenerateSampleImageResponse");
        if (this.imageUrls != null) {
            output.writeFieldBegin("imageUrls", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.imageUrls.length);
            this.imageUrls.forEach((value_306: string): void => {
                output.writeString(value_306);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.remainingCoinBalance != null) {
            output.writeFieldBegin("remainingCoinBalance", thrift.Thrift.Type.I32, 2);
            output.writeI32(this.remainingCoinBalance);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGenerateSampleImageResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_307: Array<string> = new Array<string>();
                        const metadata_23: thrift.TList = input.readListBegin();
                        const size_23: number = metadata_23.size;
                        for (let i_23: number = 0; i_23 < size_23; i_23++) {
                            const value_308: string = input.readString();
                            value_307.push(value_308);
                        }
                        input.readListEnd();
                        _args.imageUrls = value_307;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_309: number = input.readI32();
                        _args.remainingCoinBalance = value_309;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.imageUrls !== undefined) {
            return new TElkGenerateSampleImageResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGenerateSampleImageResponse from input");
        }
    }
}
export interface ITElkGenerateImageResponseArgs {
    imageUrls: Array<string>;
}
export class TElkGenerateImageResponse {
    public imageUrls: Array<string>;
    constructor(args: ITElkGenerateImageResponseArgs) {
        if (args != null && args.imageUrls != null) {
            this.imageUrls = args.imageUrls;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[imageUrls] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGenerateImageResponse");
        if (this.imageUrls != null) {
            output.writeFieldBegin("imageUrls", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.imageUrls.length);
            this.imageUrls.forEach((value_310: string): void => {
                output.writeString(value_310);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGenerateImageResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_311: Array<string> = new Array<string>();
                        const metadata_24: thrift.TList = input.readListBegin();
                        const size_24: number = metadata_24.size;
                        for (let i_24: number = 0; i_24 < size_24; i_24++) {
                            const value_312: string = input.readString();
                            value_311.push(value_312);
                        }
                        input.readListEnd();
                        _args.imageUrls = value_311;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.imageUrls !== undefined) {
            return new TElkGenerateImageResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGenerateImageResponse from input");
        }
    }
}
export interface ITElkGenerateEventFromMadLibsRequestArgs {
    eventName: string;
    location: ElkEvent.TAppElkLocation;
    startTime: number | Int64;
    endTime: number | Int64;
    theme: string;
    eventId?: string;
}
export class TElkGenerateEventFromMadLibsRequest {
    public eventName: string;
    public location: ElkEvent.TAppElkLocation;
    public startTime: Int64;
    public endTime: Int64;
    public theme: string;
    public eventId?: string;
    constructor(args: ITElkGenerateEventFromMadLibsRequestArgs) {
        if (args != null && args.eventName != null) {
            this.eventName = args.eventName;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[eventName] is unset!");
        }
        if (args != null && args.location != null) {
            this.location = args.location;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[location] is unset!");
        }
        if (args != null && args.startTime != null) {
            if (typeof args.startTime === "number") {
                this.startTime = new Int64(args.startTime);
            }
            else {
                this.startTime = args.startTime;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[startTime] is unset!");
        }
        if (args != null && args.endTime != null) {
            if (typeof args.endTime === "number") {
                this.endTime = new Int64(args.endTime);
            }
            else {
                this.endTime = args.endTime;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[endTime] is unset!");
        }
        if (args != null && args.theme != null) {
            this.theme = args.theme;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[theme] is unset!");
        }
        if (args != null && args.eventId != null) {
            this.eventId = args.eventId;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGenerateEventFromMadLibsRequest");
        if (this.eventName != null) {
            output.writeFieldBegin("eventName", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.eventName);
            output.writeFieldEnd();
        }
        if (this.location != null) {
            output.writeFieldBegin("location", thrift.Thrift.Type.STRUCT, 2);
            this.location.write(output);
            output.writeFieldEnd();
        }
        if (this.startTime != null) {
            output.writeFieldBegin("startTime", thrift.Thrift.Type.I64, 3);
            output.writeI64(this.startTime);
            output.writeFieldEnd();
        }
        if (this.endTime != null) {
            output.writeFieldBegin("endTime", thrift.Thrift.Type.I64, 4);
            output.writeI64(this.endTime);
            output.writeFieldEnd();
        }
        if (this.theme != null) {
            output.writeFieldBegin("theme", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.theme);
            output.writeFieldEnd();
        }
        if (this.eventId != null) {
            output.writeFieldBegin("eventId", thrift.Thrift.Type.STRING, 6);
            output.writeString(this.eventId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGenerateEventFromMadLibsRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_313: string = input.readString();
                        _args.eventName = value_313;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_314: ElkEvent.TAppElkLocation = ElkEvent.TAppElkLocation.read(input);
                        _args.location = value_314;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_315: Int64 = input.readI64();
                        _args.startTime = value_315;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_316: Int64 = input.readI64();
                        _args.endTime = value_316;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_317: string = input.readString();
                        _args.theme = value_317;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_318: string = input.readString();
                        _args.eventId = value_318;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.eventName !== undefined && _args.location !== undefined && _args.startTime !== undefined && _args.endTime !== undefined && _args.theme !== undefined) {
            return new TElkGenerateEventFromMadLibsRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGenerateEventFromMadLibsRequest from input");
        }
    }
}
export interface ITElkGenerateEventFromMadLibsResponseArgs {
    event: ElkEvent.TAppElkEvent;
    prompt?: string;
}
export class TElkGenerateEventFromMadLibsResponse {
    public event: ElkEvent.TAppElkEvent;
    public prompt?: string;
    constructor(args: ITElkGenerateEventFromMadLibsResponseArgs) {
        if (args != null && args.event != null) {
            this.event = args.event;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[event] is unset!");
        }
        if (args != null && args.prompt != null) {
            this.prompt = args.prompt;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TElkGenerateEventFromMadLibsResponse");
        if (this.event != null) {
            output.writeFieldBegin("event", thrift.Thrift.Type.STRUCT, 1);
            this.event.write(output);
            output.writeFieldEnd();
        }
        if (this.prompt != null) {
            output.writeFieldBegin("prompt", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.prompt);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TElkGenerateEventFromMadLibsResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_319: ElkEvent.TAppElkEvent = ElkEvent.TAppElkEvent.read(input);
                        _args.event = value_319;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_320: string = input.readString();
                        _args.prompt = value_320;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.event !== undefined) {
            return new TElkGenerateEventFromMadLibsResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TElkGenerateEventFromMadLibsResponse from input");
        }
    }
}
